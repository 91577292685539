import React, { useEffect, useRef } from 'react';
import { useDragLayer } from 'react-dnd';

const DragPreview = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number>();

  const updateCanvas = () => {
    if (isDragging && item?.sourceCanvas && canvasRef.current) {
      const sourceCanvas = item.sourceCanvas;
      const targetCanvas = canvasRef.current;

      // Set target canvas size to match source (but will be displayed larger)
      targetCanvas.width = sourceCanvas.width;
      targetCanvas.height = sourceCanvas.height;

      const ctx = targetCanvas.getContext('2d', { alpha: true });
      if (ctx) {
        // Clear with transparency
        ctx.clearRect(0, 0, targetCanvas.width, targetCanvas.height);
        // Preserve transparency when drawing
        ctx.globalAlpha = 1;
        ctx.drawImage(sourceCanvas, 0, 0);
      }

      // Request next frame
      requestRef.current = requestAnimationFrame(updateCanvas);
    }
  };

  useEffect(() => {
    if (isDragging) {
      requestRef.current = requestAnimationFrame(updateCanvas);
    }
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [isDragging, item]);

  if (!isDragging || !currentOffset || !item?.sourceCanvas) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: currentOffset.x - 80, // Centered on cursor with 2x size
        top: currentOffset.y - 80,  // Centered on cursor with 2x size
        width: '160px',             // 2x original size
        height: '160px',            // 2x original size
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

export default DragPreview; 