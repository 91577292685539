import * as Blockly from 'blockly';

export const blocklyTheme = new Blockly.Theme(
  'CustomColorTheme',
  {
    loop_blocks: {
      colourPrimary: '#904DBE',
      colourSecondary: '#B466E9',
      colourTertiary: '#D9A3FD',
      hat: 'cap',
    },
    logic_blocks: {
      colourPrimary: '#C09815',
      colourSecondary: '#EDC643',
      colourTertiary: '#FFE27B',
      hat: 'cap',
    },
    math_blocks: {
      colourPrimary: '#C2229B',
      colourSecondary: '#EC5AC8',
      colourTertiary: '#F19CDC',
      hat: 'cap',
    },
    text_blocks: {
      colourPrimary: '#D2731B',
      colourSecondary: '#F09541',
      colourTertiary: '#F8BF8A',
      hat: 'cap',
    },
    lists_blocks: {
      colourPrimary: '#C2229B',
      colourSecondary: '#EC5AC8',
      colourTertiary: '#F19CDC',
      hat: 'cap',
    },
    colour_blocks: {
      colourPrimary: '#399CC1',
      colourSecondary: '#67C9ED',
      colourTertiary: '#A6DEF3',
      hat: 'cap',
    },
    variable_blocks: {
      colourPrimary: '#2B9732',
      colourSecondary: '#53B65A',
      colourTertiary: '#8ADC90',
      hat: 'cap',
    },
    procedure_blocks: {
      colourPrimary: '#C23B3C',
      colourSecondary: '#EC6869',
      colourTertiary: '#F39A9A',
      hat: 'cap',
    },
  },
  // Category Styles (optional)
  {
    // 'loop': { colour: '#4A90E2' },
    // 'logic': { colour: '#50C878' }
  },
  // Component Styles (optional)
  {
    workspaceBackgroundColour: '#FFFFFF',
    toolboxBackgroundColour: '#FFFFFF',
    toolboxForegroundColour: 'black',
    flyoutBackgroundColour: '#FFFFFF',
    flyoutForegroundColour: 'black',
    flyoutOpacity: 1,
    scrollbarColour: 'salte',
    scrollbarOpacity: 1,
    insertionMarkerColour: 'gray',
    insertionMarkerOpacity: 1,
    markerColour: 'black',
    cursorColour: 'black',
    selectedGlowColour: 'white  ',
    selectedGlowOpacity: 1,
    replacementGlowColour: 'black',
    replacementGlowOpacity: 1,
  },
);

// After creation, you can further customize the theme if needed
blocklyTheme.setFontStyle({
  family: 'Arial, sans-serif',
  size: 12,
  weight: 'normal',
});

blocklyTheme.setStartHats(true);
