// Global state for transform interaction
let isTransformInteracting = false;
let transformTimeout: NodeJS.Timeout | null = null;

export const setTransformInteracting = (value: boolean) => {
  isTransformInteracting = value;
  
  // Clear any existing timeout
  if (transformTimeout) {
    clearTimeout(transformTimeout);
  }

  // If interaction ended, set a timeout to allow selections after 1000ms
  if (!value) {
    transformTimeout = setTimeout(() => {
      isTransformInteracting = false;
      console.log('Transform interaction cooldown ended');
    }, 1000);
  }
};

export const getTransformInteracting = () => isTransformInteracting; 