import React, {useState, useCallback, useRef, useEffect} from 'react';
import {useDrop} from 'react-dnd';
import axios from 'axios';
import * as THREE from 'three';
import toast from 'react-hot-toast';
import {XR, createXRStore} from '@react-three/xr';
import {Canvas} from '@react-three/fiber';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

import ActionsModel from './ActionsModel';
import PartList from './3DComponents/PartList';
import {BlockNear, CanvasBlocks, Lesson} from './3DComponents/types';

// validation functions
import {getLessonBlocks} from './Validation/RequirePositons';
import {AssemblyValidator} from './Validation/newValidation';
import {SceneContent} from './3DComponents/SceneContent';
import DragPreview from './3DComponents/DragPreview';

// Mock data for offline mode
const mockInventoryData: Lesson = {
  name: 'Offline Mode',
  description: 'Working in offline mode with mock data',
  pieces: [
    {filename: '/models/2780.glb', quantity: 10},
    {filename: '/models/32009.glb', quantity: 5},
    {filename: '/models/32316.glb', quantity: 5},
    {filename: '/models/39369.glb', quantity: 3},
    {filename: '/models/48989.glb', quantity: 4},
  ],
};

const Editor3D: React.FC = () => {
  const initialCameraPosition = new THREE.Vector3(0, 5, 0);
  const [xrStore] = useState(() => createXRStore());

  const [isOrbitEnabled, setIsOrbitEnabled] = useState(true);
  const [canvasBlocks, setCanvasBlocks] = useState<CanvasBlocks[]>([]);
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);
  const [gltfCache, setGltfCache] = useState<Record<string, THREE.Group>>({});

  const [page, setPage] = useState(1);
  const [lastPage, setLastpage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLesson, setCurrentLesson] = useState<Lesson | null>(null);

  const [partsInventory, setPartsInventory] = useState<{
    [key: string]: number;
  }>({});

  const orbitControlsRef = useRef<any>(null);
  const currentLessonRef = useRef(currentLesson);
  const partsInventoryRef = useRef(partsInventory);
  const gltfCacheRef = useRef<Record<string, THREE.Group>>({});

  // Memoized loading function
  const loadGLTF = useCallback(
    (filename: string) =>
      new Promise<THREE.Group>((resolve, reject) => {
        new GLTFLoader().load(
          filename,
          gltf => resolve(gltf.scene),
          undefined,
          reject,
        );
      }),
    [],
  );

  const addBlockToCanvas = useCallback(
    async (filename: string, dropPosition?: THREE.Vector3) => {
      if (partsInventory[filename] > 0) {
        let gltfModel: THREE.Group;

        if (gltfCacheRef.current[filename]) {
          gltfModel = gltfCacheRef.current[filename].clone();
        } else {
          try {
            gltfModel = await loadGLTF(filename);
            gltfCacheRef.current[filename] = gltfModel.clone();
          } catch (error) {
            console.error(`Error loading GLB model: ${filename}`, error);
            return;
          }
        }

        // Use dropPosition if provided, otherwise find a free position
        const position = dropPosition
          ? [dropPosition.x, dropPosition.y, dropPosition.z]
          : findFreePosition3D();

        // Logic to create and add new model block to canvas
        const newModel: CanvasBlocks = {
          id: `${filename}-${Date.now()}`,
          url: filename,
          position: position as [number, number, number],
          rotation: [0, 0, 0],
          gltf: gltfModel,
          description: filename.split('/models/')[1].split('.glb')[0],
          isModify: true,
        };

        setPartsInventory(prev => {
          const updatedInventory = {
            ...prev,
            [filename]: prev[filename] - 1,
          };
          setCanvasBlocks(prevBlocks => {
            const newBlocks = [...prevBlocks, newModel];
            callAddBlocksUpdate(newBlocks, updatedInventory);
            return newBlocks;
          });
          partsInventoryRef.current = updatedInventory;
          return updatedInventory;
        });
      }
    },
    [loadGLTF, partsInventory],
  );
  const findFreePosition3D = (): [number, number, number] => {
    let x = 0,
      y = 1,
      z = 0;
    let attempts = 0;
    const maxAttempts = 100;

    while (attempts < maxAttempts) {
      const isBlockNear = (block: BlockNear, x: number, y: number, z: number) =>
        Math.abs(block.position[0] - x) < 1 &&
        Math.abs(block.position[1] - y) < 1 &&
        Math.abs(block.position[2] - z) < 1;

      // eslint-disable-next-line no-loop-func
      if (!canvasBlocks.some(block => isBlockNear(block, x, y, z))) {
        return [x, y, z];
      }
      x += 1;
      if (x > 5) {
        x = 0;
        z += 1;
      }
      if (z > 5) {
        z = 0;
        y += 1;
      }
      attempts++;
    }
    return [x, y, z];
  };

  const [, drop] = useDrop(
    () => ({
      accept: 'PIECE',
      drop: async (item: {type: string; filename: string}, monitor) => {
        const dropPosition = monitor.getClientOffset();
        if (dropPosition) {
          // Convert screen coordinates to world coordinates
          const canvas = document.querySelector('canvas');
          if (!canvas) return;

          const rect = canvas.getBoundingClientRect();
          // Calculate normalized device coordinates (-1 to +1)
          const x = ((dropPosition.x - rect.left) / rect.width) * 2 - 1;
          const y = -((dropPosition.y - rect.top) / rect.height) * 2 + 1;

          // Create a raycaster
          const raycaster = new THREE.Raycaster();

          // Create a camera with the same parameters as the scene camera
          const camera = new THREE.PerspectiveCamera(
            100,
            window.innerWidth / window.innerHeight,
            0.1,
            1000,
          );
          camera.position.copy(initialCameraPosition);
          camera.lookAt(new THREE.Vector3(0, 0, 0));
          camera.updateMatrixWorld();

          // Set up the raycaster with the camera and mouse position
          raycaster.setFromCamera(new THREE.Vector2(x, y), camera);

          // Define the ground plane at y=0
          const groundPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
          const intersection = new THREE.Vector3();

          // Find the intersection point
          if (raycaster.ray.intersectPlane(groundPlane, intersection)) {
            // Scale the position to match your scene scale
            const scaledPosition = new THREE.Vector3(
              intersection.x * 5, // Adjust these scaling factors
              0, // Keep y at 0 for ground level
              intersection.z * 5, // Adjust these scaling factors
            );

            // Add the block at the intersection point
            await addBlockToCanvas(item.filename, scaledPosition);
            return {dropped: true};
          }
        }
        return undefined;
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    [addBlockToCanvas, initialCameraPosition],
  );

  // Separate initialization effect
  useEffect(() => {
    setCurrentLesson(null);
    const urlParams = new URLSearchParams(window.location.search);
    const lessonParam = urlParams.get('lessonId');
    const userIDParam = urlParams.get('userId');
    const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
    const userID = userIDParam ? parseInt(userIDParam) : 1;

    const initialRenderData = async () => {
      if (process.env.REACT_APP_SKIP_API === 'true') {
        setCurrentPage(1);
        setLastpage(1);
        setPartsData(mockInventoryData);
        currentLessonRef.current = mockInventoryData;
        setTimeout(() => {
          setCurrentLesson(mockInventoryData);
        }, 2000);
        return;
      }

      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/3d-builder/${lessonNumber}/${userID}?page=1`,
        });
        setCurrentPage(Number(response?.data?.current_page));
        setLastpage(Number(response?.data?.total_page));
        const responseData = response?.data?.data?.items;
        setPartsData(responseData?.inventory_data || responseData?.lesson_data);
        currentLessonRef.current = responseData?.lesson_data;

        setTimeout(() => {
          setCurrentLesson(responseData?.lesson_data);
        }, 2000);

        // Load initial blocks only during initialization
        const newBlocksData: any = [];
        for (const d of responseData?.blocks || []) {
          const filename = d?.url;
          let gltfModel: THREE.Group;
          if (gltfCache[filename]) {
            gltfModel = gltfCache[filename].clone();
          } else {
            try {
              gltfModel = await loadGLTF(filename);
              setGltfCache(prev => ({
                ...prev,
                [filename]: gltfModel.clone(),
              }));
            } catch (error) {
              console.error(`Error loading GLB model: ${filename}`, error);
              continue;
            }
          }
          const file = d?.url?.split('/models/')[1];
          const data = {
            ...d,
            gltf: gltfModel,
            description: file.split('.glb')[0],
          };
          newBlocksData.push(data);
        }

        setCanvasBlocks(newBlocksData);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        // Use mock data as fallback
        setCurrentPage(1);
        setLastpage(1);
        setPartsData(mockInventoryData);
        currentLessonRef.current = mockInventoryData;
        setTimeout(() => {
          setCurrentLesson(mockInventoryData);
        }, 2000);
      }
    };

    initialRenderData();
  }, []); // Only run on mount

  // Separate page change effect
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lessonParam = urlParams.get('lessonId');
    const userIDParam = urlParams.get('userId');
    const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
    const userID = userIDParam ? parseInt(userIDParam) : 1;

    const updatePageData = async () => {
      if (process.env.REACT_APP_SKIP_API === 'true') {
        return; // No pagination in offline mode
      }

      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/3d-builder/${lessonNumber}/${userID}?page=${page}`,
        });

        setCurrentPage(Number(response?.data?.current_page));
        setLastpage(Number(response?.data?.total_page));
        const responseData = response?.data?.data?.items;

        // Only update the parts inventory, not the blocks in the scene
        setCurrentLesson(responseData?.lesson_data);
        setPartsData(responseData?.inventory_data || responseData?.lesson_data);
        currentLessonRef.current = responseData?.lesson_data; 
      } catch (error) {
        console.error('Error fetching page data:', error);
      }
    };

    // Only skip the initial mount page 1 load
    if (page === 1 && currentPage === 0) return;

    updatePageData();
  }, [page, currentPage]); // Add currentPage to dependencies

  const setPartsData = (data: any) => {
    const inventory = data?.pieces?.reduce(
      (
        acc: {[key: string]: number},
        piece: {filename: string; quantity: number},
      ) => {
        acc[piece.filename] = piece.quantity;
        return acc;
      },
      {},
    );

    setPartsInventory(inventory || data);
    partsInventoryRef.current = inventory || data;
  };

  const callAddBlocksUpdate = (newBlocks: any, partsinventoryObj: any) => {
    if (process.env.REACT_APP_SKIP_API === 'true') {
      return; // Skip API call in offline mode
    }

    const urlParams = new URLSearchParams(window.location.search);
    const lessonParam = urlParams.get('lessonId');
    const userIDParam = urlParams.get('userId');
    const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
    const userID = userIDParam ? parseInt(userIDParam) : 1;
    const newBlocksData = newBlocks.map((d: any, index: number) => {
      return {
        id: d.id,
        position: d.position,
        rotation: d.rotation,
        url: d?.url,
        isModify: d?.isModify ?? true,
        // isModify: d?.url === "/models/39369.glb" ? false : true,
      };
    });

    fetch(`/lessons/${lessonNumber}.json`)
      .then(r => r.json())
      .then(data => {
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/3d-builder`,
          data: {
            lesson_id: lessonNumber,
            user_id: userID,
            items: {
              blocks: newBlocksData,
              inventory_data: partsinventoryObj,
              lesson_data: data,
            },
          },
        })
          .then(function (response) {
            // console.log('response::::', response);
          })
          .catch(function (error) {
            console.log(error);
          });
      });
  };

  const updateBlockPosition = (
    index: number,
    newPosition: [number, number, number],
  ) => {
    setCanvasBlocks(prevBlocks => {
      const updatedBlocks = [...prevBlocks];
      updatedBlocks[index].position = newPosition;
      // callAddBlocksUpdate(updatedBlocks, partsInventory);
      return updatedBlocks;
    });
  };

  const callUpdateBlockPosition = (
    index: number,
    newPosition: [number, number, number],
  ) => {
    const updatedBlocks = [...canvasBlocks];
    updatedBlocks[index].position = newPosition;
    callAddBlocksUpdate(updatedBlocks, partsInventory);
  };

  const handleDeleteBlock = useCallback(
    (index: any) => {
      // console.log(`Attempting to delete block at index: ${index}`);

      // Retrieve the block to be deleted before updating the state
      // const deletedBlock = canvasBlocks[index];
      const deletedBlock = canvasBlocks.filter(item => item.id === index);
      if (deletedBlock.length === 0) {
        // console.log(`Invalid index: ${index}. No block deleted.`);
        return;
      }
      // Update inventory separately to ensure it's called only once
      setPartsInventory(prevInventory => {
        const currentQuantity = prevInventory[deletedBlock[0]?.url] || 0;
        const newQuantity = currentQuantity + 1;
        const partsinventoryObj = {
          ...prevInventory,
          [deletedBlock[0]?.url]: newQuantity,
        };

        // Remove the block from canvasBlocks
        setCanvasBlocks(prevBlocks => {
          const newBlocks = prevBlocks.filter((item, i) => item.id !== index);
          callAddBlocksUpdate(newBlocks, partsinventoryObj);
          return newBlocks;
        });

        partsInventoryRef.current = partsinventoryObj;
        return partsinventoryObj;
      });
    },
    [canvasBlocks],
  );

  const handleDuplicateBlock = useCallback((index: number) => {
    setCanvasBlocks(prevBlocks => {
      const blockToDuplicate = prevBlocks[index];
      const newPosition: [number, number, number] = [
        blockToDuplicate.position[0] + 1,
        blockToDuplicate.position[1],
        blockToDuplicate.position[2] + 1,
      ];
      const newBlock = {...blockToDuplicate, position: newPosition};
      const updatedBlocks = [...prevBlocks, newBlock];
      callAddBlocksUpdate(updatedBlocks, partsInventoryRef.current);
      return updatedBlocks;
    });
  }, []);

  const handleRotateBlock = useCallback(
    (index: number, newRotation: [number, number, number]) => {
      //axis: 'x' | 'z' | 'y'
      setCanvasBlocks(prevBlocks => {
        const updatedBlocks = [...prevBlocks];
        const block = updatedBlocks[index];

        block.rotation = newRotation;

        callAddBlocksUpdate(updatedBlocks, partsInventoryRef.current);
        return updatedBlocks;
      });
    },
    [],
  );

  const memoizedHandleDeleteBlock = useCallback(handleDeleteBlock, [
    handleDeleteBlock,
  ]);

  const handleValidatePlacement = useCallback(
    (From?: string | React.MouseEvent<HTMLButtonElement>) => {
      const urlParams = new URLSearchParams(window.location.search);
      const lessonId = urlParams.get('lessonId');
      const Blocks = getLessonBlocks(lessonId);

      const validator = new AssemblyValidator(Blocks, canvasBlocks);
      const result = validator.validateAssembly();

      if (result.stage === 'availability') {
        toast.error('Missing Required Blocks', {
          id: 'errorReQ',
          duration: 2000,
        });
        return {message: 'Missing Required Blocks', valid: false};
      }

      const newValidationStates: Record<string, boolean> = {};
      canvasBlocks.forEach(block => {
        const status = validator.getBlockStatus(block.id);
        newValidationStates[block.id] = status.isCorrect;
      });

      if (result.isValid) {
        if (From === 'home') {
          toast.success(
            'Perfect! All blocks are correctly placed. You can now play the video.',
            {id: 'Perfect Placement', duration: 2000},
          );
        }
        return {
          message: 'Perfect! All blocks are correctly placed',
          valid: true,
        };
      } else {
        toast.error('Incorrect Block Placement', {
          id: 'incorrectplace',
          duration: 2000,
        });
        return {message: 'Incorrect Block Placement', valid: false};
      }
    },
    [canvasBlocks],
  );

  return (
    <div className="relative w-full h-full" ref={drop}>
      <div className="w-screen h-screen overflow-hidden">
        <Canvas
          camera={{position: initialCameraPosition.toArray(), fov: 100}}
          shadows
          className="w-full h-full"
          gl={{
            powerPreference: 'high-performance',
            antialias: false, // Disable antialiasing for performance
            alpha: false,
            stencil: false,
            depth: true,
            logarithmicDepthBuffer: true,
          }}
          onCreated={({gl}) => {
            gl.setClearColor(new THREE.Color('#f0f0f0'));
          }}>
          <XR store={xrStore}>
            {
              // isARMode ? (
              //   <ARContent blocks={blocks} onError={onARError} />
              // ) : (
              <SceneContent
                // blocks={blocks}
                canvasBlocks={canvasBlocks}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
                updateBlockPosition={updateBlockPosition}
                callUpdateBlockPosition={callUpdateBlockPosition}
                orbitControlsRef={orbitControlsRef}
                isOrbitEnabled={isOrbitEnabled}
                setIsOrbitEnabled={setIsOrbitEnabled}
                onDeleteBlock={memoizedHandleDeleteBlock}
                onDuplicateBlock={handleDuplicateBlock}
                onRotateBlock={handleRotateBlock}
              />
              // )
            }
          </XR>
        </Canvas>

        {/* Title */}
        <div className="absolute top-4 left-28 text-2xl font-bold text-gray-800">
          &nbsp;
        </div>

        {/* Actions Model  */}
        <ActionsModel isValid={() => handleValidatePlacement('action')} />

        {/* model right icons*/}
        <div className="absolute top-4 right-4 z-10"></div>

        <div className="">hello</div>

        {/* Floating bottom bar */}
        {/* {currentLesson && ( */}
        <PartList
          parts={currentLesson?.pieces || []}
          inventory={partsInventory}
          onPartClick={addBlockToCanvas}
          setPage={setPage}
          currentPage={currentPage}
          lastPage={lastPage}
        />
        {/* )} */}

        {/* {isPlayModalOpen && (
          <PlayModal blocks={blocks} onClose={() => setIsPlayModalOpen(false)} />
        )} */}
        <div className="absolute top-5 left-4 flex flex-col gap-4 z-50">
          <button
            onClick={handleValidatePlacement}
            className="bg-gray-100 shadow-lg px-4 py-2 bg-blue-500 text-black cursor-pointer rounded hover:bg-blue-600">
            Check Placement
          </button>
        </div>
      </div>
      <DragPreview />
    </div>
  );
};

export default Editor3D;
