import React, {useRef, useCallback, useState} from 'react';
import '@blockly/field-bitmap';
import * as Blockly from 'blockly';
import {useBlocklyWorkspace} from 'react-blockly';
import {registerFieldColour} from '@blockly/field-colour';

import {customBlockArray} from './Blockly/StaticData/CustomBlocks';
import {initializeGenerators} from './Blockly/JavaScriptGenerator';
import {useToolboxManager} from './Blockly/Hooks/useToolboxManager';
import {workspaceConfiguration} from './Blockly/Config/WorkspaceConfig';
import {toolboxConfigurations} from './Blockly/StaticData/Category';
import {registerFieldAngle} from '@blockly/field-angle';

// validation function
import {
  // initializeBlockValidation,
  validateWorkspace,
} from './Blockly/Validation/BlockValidation';
import {registerCustomField} from './Blockly/StaticData/CustomFiled';

const urlParams = new URLSearchParams(window.location.search);
const lessonParam = urlParams.get('lessonId');
const lessonKey = lessonParam ? `lesson-${lessonParam}` : null;

registerFieldAngle();
registerFieldColour();
registerCustomField(); // Register our custom field
// initializeBlockValidation(`lesson-${lessonParam}`);
initializeGenerators();
Blockly.defineBlocksWithJsonArray(customBlockArray);
// Register the field type

interface BlocklyWorkspaceProps {
  initialState: any;
  setInitialState: React.Dispatch<any>;
}

type ToolboxConfigurations = typeof toolboxConfigurations;

const BlocklyWorkspace: React.FC<BlocklyWorkspaceProps> = ({
  initialState,
  setInitialState,
}) => {
  const workspaceRef = useRef<HTMLDivElement | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleWorkspaceChange = useCallback(
    (workspace: Blockly.WorkspaceSvg) => {
      const toolboxElement: any = workspace.getToolbox();
      const allItems = toolboxElement?.getToolboxItems();
      const selectedCategory: any = toolboxElement?.getSelectedItem();

      allItems?.forEach((item: any) => {
        if (item.toolboxItemDef_.id === selectedCategory?.toolboxItemDef_.id) {
          item.setSelected(true);
        } else {
          item.setSelected(false);
        }
      });
    },
    [],
  );

  const selectedToolbox =
    lessonKey &&
    (
      toolboxConfigurations as Record<
        string,
        ToolboxConfigurations[keyof ToolboxConfigurations]
      >
    )[lessonKey]
      ? toolboxConfigurations[lessonKey as keyof ToolboxConfigurations]
      : {contents: []};

  const {workspace} = useBlocklyWorkspace({
    ref: workspaceRef,
    toolboxConfiguration: selectedToolbox,
    workspaceConfiguration: workspaceConfiguration,
    onWorkspaceChange: handleWorkspaceChange,
  });

  useToolboxManager(workspace, initialState);

  const saveWorkspace = async () => {
    if (workspace) {
      const urlParams = new URLSearchParams(window.location.search);
      const lessonParam = urlParams.get('lessonId');
      const userIDParam = urlParams.get('userId');
      const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
      const userID = userIDParam ? parseInt(userIDParam) : 1;

      try {
        setIsSaving(true);
        let checkValidation: any = validateWorkspace(
          workspace,
          `lesson-${lessonParam}`,
          'saveFunction',
        );

        const state = Blockly.serialization.workspaces.save(workspace);

        if (process.env.REACT_APP_SKIP_API === 'true') {
          // In offline mode, just show success state
          setInitialState({is_valid: true});
          setIsSaving(false);
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/block-action-model`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              lesson_id: lessonNumber,
              user_id: userID,
              items: state,
              is_valid: checkValidation,
            }),
          },
        );
        const data = await response.json();
        if (response.status === 200) {
          setInitialState(data?.data);
        }
      } catch (error) {
        console.error('Error saving workspace state:', error);
        // Use default state as fallback in case of error
        setInitialState({is_valid: true});
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <div className="w-full h-full relative">
      <div
        ref={workspaceRef}
        className="h-full relative w-full !bg-white"></div>
      <div className="absolute top-0 right-0">
        <button
          className="bg-blue rounded-md text-sm text-white py-1 px-2"
          onClick={saveWorkspace}>
          {isSaving ? 'Loading...' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default BlocklyWorkspace;
