interface BlockPosition {
  id: string;
  position: number[];
  rotation: number[];
  url: string;
}

export class AssemblyValidator {
  private readonly positionTolerance = 0.5;
  private readonly rotationTolerance = 0.1;
  private occupiedPositions: Set<string> = new Set();

  constructor(
    private requiredBlocks: BlockPosition[],
    private currentBlocks: BlockPosition[],
  ) {}

  // Convert position to a string key for comparison
  private getPositionKey(position: number[]): string {
    // Round to a specific precision to reduce floating-point precision issues
    return position.map(coord => coord.toFixed(1)).join(',');
  }

  private findPotentialRequiredPositions(currentBlock: BlockPosition): {
    matches: BlockPosition[];
    positionMatches: BlockPosition[];
    rotationMatches: BlockPosition[];
  } {
    // Find all potential matching positions for the current block
    const matches = this.requiredBlocks.filter(
      required => required?.url === currentBlock?.url,
    );

    const positionMatches = matches.filter(required =>
      this.comparePositions(currentBlock.position, required.position),
    );

    const rotationMatches = matches.filter(required =>
      this.compareRotations(currentBlock.rotation, required.rotation),
    );

    return {
      matches,
      positionMatches,
      rotationMatches,
    };
  }

  public validateAssembly(): {
    isValid: boolean;
    errors: string[];
    correctPlacements: string[];
    stage: 'availability' | 'placement';
  } {
    // Reset occupied positions - now store both position and block type
    const occupiedPositions = new Map<string, string>(); // positionKey -> blockUrl

    // First check block availability
    const availabilityCheck = this.validateBlockAvailability();
    if (!availabilityCheck.isValid) {
      return {
        isValid: false,
        errors: availabilityCheck.errors,
        correctPlacements: [],
        stage: 'availability',
      };
    }

    const errors: string[] = [];
    const correctPlacements: string[] = [];

    // Check each current block
    for (const currentBlock of this.currentBlocks) {
      const positionKey = this.getPositionKey(currentBlock.position);
      const blockName = currentBlock?.url?.split('/')?.pop()?.replace('.glb', '');

      // Find potential matching required positions
      const {matches, positionMatches, rotationMatches} =
        this.findPotentialRequiredPositions(currentBlock);

      // Check if the position is occupied by the same block type
      if (occupiedPositions.has(positionKey) && occupiedPositions.get(positionKey) === currentBlock.url) {
        errors.push(`Position ${positionKey} is already occupied by another ${blockName} block`);
        continue;
      }

      // Add the position and block type to occupied positions
      occupiedPositions.set(positionKey, currentBlock.url);

      if (matches.length === 0) {
        errors.push(`${blockName} block is not a required block`);
      } else if (positionMatches.length === 0 && rotationMatches.length === 0) {
        errors.push(`${blockName} block has incorrect position and rotation`);
      } else if (positionMatches.length === 0) {
        errors.push(`${blockName} block has incorrect position`);
      } else if (rotationMatches.length === 0) {
        errors.push(`${blockName} block has incorrect rotation`);
      } else {
        correctPlacements.push(currentBlock.id);
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
      correctPlacements,
      stage: 'placement',
    };
  }

  // Existing helper methods remain the same as in the previous implementation
  private validateBlockAvailability(): {
    isValid: boolean;
    errors: string[];
  } {
    // Implementation remains the same as in the previous version
    const errors: string[] = [];
    const requiredCounts = this.getBlockCounts(this.requiredBlocks);
    const currentCounts = this.getBlockCounts(this.currentBlocks);

    requiredCounts.forEach((requiredCount, url) => {
      const currentCount = currentCounts.get(url) || 0;
      if (currentCount < requiredCount) {
        const blockName = url?.split('/').pop()?.replace('.glb', '');
        const missing = requiredCount - currentCount;
        errors.push(
          `Missing ${missing} ${blockName} block${
            missing > 1 ? 's' : ''
          } (${missing} more needed)`,
        );
      }
    });

    return {
      isValid: errors.length === 0,
      errors,
    };
  }

  // Existing methods for comparing positions, rotations, etc. remain the same
  private normalizeRotation(rotation: number): number {
    return ((rotation % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
  }

  private comparePositions(current: number[], required: number[]): boolean {
    if (current.length !== 3 || required.length !== 3) return false;
    return current.every(
      (coord, i) => Math.abs(coord - required[i]) <= this.positionTolerance,
    );
  }

  private compareRotations(current: number[], required: number[]): boolean {
    if (current.length !== 3 || required.length !== 3) return false;
    return current.every((angle, i) => {
      const normalizedCurrent = this.normalizeRotation(angle);
      const normalizedRequired = this.normalizeRotation(required[i]);
      const diff = Math.abs(normalizedCurrent - normalizedRequired);
      return (
        diff <= this.rotationTolerance ||
        Math.abs(diff - 2 * Math.PI) <= this.rotationTolerance
      );
    });
  }

  public getBlockStatus(blockId: string): {
    isCorrect: boolean;
    position: boolean;
    rotation: boolean;
    positionOccupied?: boolean;
  } {
    const currentBlock = this.currentBlocks.find(b => b.id === blockId);
    if (!currentBlock) {
      return {isCorrect: false, position: false, rotation: false};
    }

    const positionKey = this.getPositionKey(currentBlock.position);
    const isPositionOccupied = this.checkPositionOccupation(currentBlock);

    // Find potential matching positions
    const {positionMatches, rotationMatches} =
      this.findPotentialRequiredPositions(currentBlock);

    return {
      isCorrect:
        positionMatches.length > 0 &&
        rotationMatches.length > 0 &&
        !isPositionOccupied,
      position: positionMatches.length > 0,
      rotation: rotationMatches.length > 0,
      positionOccupied: isPositionOccupied,
    };
  }

  private checkPositionOccupation(block: BlockPosition): boolean {
    const positionKey = this.getPositionKey(block.position);

    // Check if this position is already occupied by another block with the same URL
    const occupiedBySameBlockType = this.currentBlocks.some(
      b =>
        b.id !== block.id && // Different block instance
        b.url === block.url && // Same block type (URL)
        this.getPositionKey(b.position) === positionKey, // Same position
    );

    return occupiedBySameBlockType;
  }

  private getBlockCounts(blocks: BlockPosition[]): Map<string, number> {
    const counts = new Map<string, number>();
    blocks.forEach(block => {
      const count = counts.get(block?.url) || 0;
      counts.set(block?.url, count + 1);
    });
    return counts;
  }
}
