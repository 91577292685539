import React from 'react';
import { Grid as DreiGrid } from '@react-three/drei';

const Grid: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const lessonParam = Number(urlParams.get('lessonId'));

  return (
    <DreiGrid
      infiniteGrid
      cellSize={1}
      sectionSize={10}
      fadeDistance={lessonParam > 1 ? 100 : 50}
      fadeStrength={1.5}
    />
  );
};

export default Grid;
