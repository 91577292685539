let motorImage =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCIgaWQ9ImRzbUljb24iPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIgMikiIGlkPSJJY29uLzQ4L21vdG9yIiBzdHJva2Utd2lkdGg9IjEiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBpZD0iQmciIGZpbGw9IiNGRkYiIGN4PSIyMiIgY3k9IjIyIiByPSIyMSIvPjxwYXRoIGQ9Ik0yMiAwYzEyLjE1IDAgMjIgOS44NSAyMiAyMnMtOS44NSAyMi0yMiAyMlMwIDM0LjE1IDAgMjIgOS44NSAwIDIyIDB6bTAgMS41QzEwLjY5NiAxLjUgMS41IDEwLjY5NiAxLjUgMjJTMTAuNjk2IDQyLjUgMjIgNDIuNSA0Mi41IDMzLjMwNCA0Mi41IDIyIDMzLjMwNCAxLjUgMjIgMS41em0wIDI2Ljc1YTUuNzUgNS43NSAwIDExMCAxMS41IDUuNzUgNS43NSAwIDAxMC0xMS41em0tMTItMTJhNS43NSA1Ljc1IDAgMTEwIDExLjUgNS43NSA1Ljc1IDAgMDEwLTExLjV6bTI0IDBhNS43NSA1Ljc1IDAgMTEwIDExLjUgNS43NSA1Ljc1IDAgMDEwLTExLjV6bS0xMi4xMzIgMS43NTdsLjExNi0uMDA3YTEgMSAwIDAxLjk5NC44ODNsLjAwNi4xMTd2MS45N0gyNWExIDEgMCAwMS45OTMuODg0bC4wMDcuMTE3YTEgMSAwIDAxLS44ODMuOTkzbC0uMTE3LjAwNy0yLjAxNi0uMDAxVjI1YTEgMSAwIDAxLS44ODMuOTkzbC0uMTE3LjAwN2ExIDEgMCAwMS0uOTkzLS44ODNMMjAuOTg0IDI1di0yLjAzaC0yLjAxNWExIDEgMCAwMS0uOTkzLS44ODNsLS4wMDctLjExNmExIDEgMCAwMS44ODMtLjk5NGwuMTE3LS4wMDYgMi4wMTUtLjAwMVYxOWExIDEgMCAwMS44ODQtLjk5M2wuMTE2LS4wMDd6TTIyIDQuMjVhNS43NSA1Ljc1IDAgMTEwIDExLjUgNS43NSA1Ljc1IDAgMDEwLTExLjV6IiBpZD0iQ29tYmluZWQtU2hhcGUiIGZpbGw9IiMwMDc4Q0MiLz48L2c+PC9zdmc+';
let motorImage2 =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCIgaWQ9ImRzbUljb24iPjxnIGlkPSJJY29uLzQ4L21vdmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNOS45MzEgMzguMDY5QTIwLjQwNSAyMC40MDUgMCAwMDIwLjUgNDFDMzEuODIyIDQxIDQxIDMxLjgyMiA0MSAyMC41YzAtMy44NjYtMS4wNy03LjQ4My0yLjkzMS0xMC41NjlDNDQuMDIgMTMuNTE5IDQ4IDIwLjA0NSA0OCAyNy41IDQ4IDM4LjgyMiAzOC44MjIgNDggMjcuNSA0OGMtNy40NTUgMC0xMy45ODEtMy45OC0xNy41NjktOS45MzF6IiBpZD0iQmctMiIgZmlsbD0iI0ZGRiIvPjxwYXRoIGQ9Ik0yNy41IDMzLjMyNGE1LjM1OCA1LjM1OCAwIDExMCAxMC43MTYgNS4zNTggNS4zNTggMCAwMTAtMTAuNzE2ek00NC4wNCAyNy41YTUuMzU4IDUuMzU4IDAgMTEtMTAuNzE2IDAgNS4zNTggNS4zNTggMCAwMTEwLjcxNiAwek0yNy41IDQ4QzM4LjgyMiA0OCA0OCAzOC44MjIgNDggMjcuNVMzOC44MjIgNyAyNy41IDcgNyAxNi4xNzggNyAyNy41IDE2LjE3OCA0OCAyNy41IDQ4em0wLTEuNWMtMTAuNDkzIDAtMTktOC41MDctMTktMTlzOC41MDctMTkgMTktMTkgMTkgOC41MDcgMTkgMTktOC41MDcgMTktMTkgMTl6IiBpZD0iQ29tYmluZWQtU2hhcGUtMiIgZmlsbD0iI0M4MDA5MCIvPjxjaXJjbGUgaWQ9IkJnLTEiIGZpbGw9IiNGRkYiIGN4PSIyMC41IiBjeT0iMjAuNSIgcj0iMjAuNSIvPjxwYXRoIGQ9Ik0xNC42NzYgMjAuNWE1LjM1OCA1LjM1OCAwIDExLTEwLjcxNiAwIDUuMzU4IDUuMzU4IDAgMDExMC43MTYgMHptMjIuMzY0IDBhNS4zNTggNS4zNTggMCAxMS0xMC43MTYgMCA1LjM1OCA1LjM1OCAwIDAxMTAuNzE2IDB6bS0xNy41NTUuOTczaC0xLjgxYTEgMSAwIDAxMC0yaDEuODF2LTEuNzY4YTEgMSAwIDAxMiAwdjEuNzY4aDEuODFhMSAxIDAgMDEwIDJoLTEuODF2MS44MjJhMSAxIDAgMDEtMiAwdi0xLjgyMnptMS4wMTUgNC44NWE1LjM1OCA1LjM1OCAwIDExMCAxMC43MTcgNS4zNTggNS4zNTggMCAwMTAtMTAuNzE2em0wLTIyLjM2M2E1LjM1OCA1LjM1OCAwIDExMCAxMC43MTYgNS4zNTggNS4zNTggMCAwMTAtMTAuNzE2em0wIDM3LjA0QzMxLjgyMiA0MSA0MSAzMS44MjIgNDEgMjAuNVMzMS44MjIgMCAyMC41IDAgMCA5LjE3OCAwIDIwLjUgOS4xNzggNDEgMjAuNSA0MXptMC0xLjVjLTEwLjQ5MyAwLTE5LTguNTA3LTE5LTE5czguNTA3LTE5IDE5LTE5IDE5IDguNTA3IDE5IDE5LTguNTA3IDE5LTE5IDE5eiIgaWQ9IkNvbWJpbmVkLVNoYXBlLTEiIGZpbGw9IiNDODAwOTAiLz48L2c+PC9zdmc+';
let squareImage =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCIgaWQ9ImRzbUljb24iPjxnIGlkPSJJY29uLzQ4L2NvbG9yLXNlbnNvciIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zOCA1LjVjMi40ODEgMCA0LjUgMi4wMTkgNC41IDQuNXYyOGMwIDIuNDgxLTIuMDE5IDQuNS00LjUgNC41SDEwQTQuNTA1IDQuNTA1IDAgMDE1LjUgMzhWMTBjMC0yLjQ4MSAyLjAxOS00LjUgNC41LTQuNWgyOCIgaWQ9IkJnIiBmaWxsPSIjRkZGIi8+PHBhdGggZD0iTTM0IDI0YzAgNS41MjItNC40NzcgMTAtMTAgMTBzLTEwLTQuNDc4LTEwLTEwIDQuNDc3LTEwIDEwLTEwIDEwIDQuNDc4IDEwIDEwem00LTIwYTYgNiAwIDAxNiA2djI4YTYgNiAwIDAxLTYgNkgxMGE2IDYgMCAwMS02LTZWMTBhNiA2IDAgMDE2LTZoMjh6bTAgMS41SDEwQTQuNTA1IDQuNTA1IDAgMDA1LjUgMTB2MjhjMCAyLjQ4MSAyLjAxOSA0LjUgNC41IDQuNWgyOGMyLjQ4MSAwIDQuNS0yLjAxOSA0LjUtNC41VjEwYzAtMi40ODEtMi4wMTktNC41LTQuNS00LjV6TTI0IDljOC4yNzEgMCAxNSA2LjcyOSAxNSAxNXMtNi43MjkgMTUtMTUgMTVTOSAzMi4yNzEgOSAyNCAxNS43MjkgOSAyNCA5em0wIDJjLTcuMTggMC0xMyA1LjgyLTEzIDEzczUuODIgMTMgMTMgMTMgMTMtNS44MiAxMy0xMy01LjgyLTEzLTEzLTEzeiIgaWQ9IkNvbWJpbmVkLVNoYXBlIiBmaWxsPSIjMDBBOEM5Ii8+PC9nPjwvc3ZnPg==';
let redioImage =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCIgaWQ9ImRzbUljb24iPjxnIGlkPSJJY29uLzQ4L2Rpc3RhbmNlIiBzdHJva2Utd2lkdGg9IjEiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTQyIDguNWMyLjQ4MSAwIDQuNSAyLjAxOSA0LjUgNC41djIyYzAgMi40ODEtMi4wMTkgNC41LTQuNSA0LjVINkE0LjUwNSA0LjUwNSAwIDAxMS41IDM1VjEzYzAtMi40ODEgMi4wMTktNC41IDQuNS00LjVoMzYiIGlkPSJCZyIgZmlsbD0iI0ZGRiIvPjxwYXRoIGQ9Ik01LjExOCAyM2gyLjAyOGMuNDU0LTIuMjc5IDIuNDctNCA0Ljg3OS00YTQuOTgzIDQuOTgzIDAgMDE0Ljg3OSA0aDIuMDI4YTYuOTc3IDYuOTc3IDAgMDAtMTMuODE0IDB6bS4wMDcgMmE2Ljk3NyA2Ljk3NyAwIDAwMTMuOCAwaC0yLjAzMWE0Ljk4NCA0Ljk4NCAwIDAxLTQuODcgMy45NUE0Ljk4NCA0Ljk4NCAwIDAxNy4xNTcgMjVINS4xMjV6bTIzLjk5My0yaDIuMDI4Yy40NTQtMi4yNzkgMi40Ny00IDQuODc5LTRhNC45ODMgNC45ODMgMCAwMTQuODc5IDRoMi4wMjhhNi45NzcgNi45NzcgMCAwMC0xMy44MTQgMHptLjAwNyAyYTYuOTc3IDYuOTc3IDAgMDAxMy44IDBoLTIuMDMxYTQuOTg0IDQuOTg0IDAgMDEtNC44NyAzLjk1QTQuOTg0IDQuOTg0IDAgMDEzMS4xNTcgMjVoLTIuMDMxem02Ljc2NS0xMGguMDg1YTguOTc2IDguOTc2IDAgMTEtOC4wMjQgMTNIMjAuMDVhOC45NzYgOC45NzYgMCAxMS03LjkzOC0xM0gzNS44OXpNNDIgN2E2IDYgMCAwMTYgNnYyMmE2IDYgMCAwMS02IDZINmE2IDYgMCAwMS02LTZWMTNhNiA2IDAgMDE2LTZoMzZ6bTAgMS41SDZBNC41MDUgNC41MDUgMCAwMDEuNSAxM3YyMmMwIDIuNDgxIDIuMDE5IDQuNSA0LjUgNC41aDM2YzIuNDgxIDAgNC41LTIuMDE5IDQuNS00LjVWMTNjMC0yLjQ4MS0yLjAxOS00LjUtNC41LTQuNXoiIGlkPSJDb21iaW5lZC1TaGFwZSIgZmlsbD0iIzY5MUZGMCIvPjwvZz48L3N2Zz4=';
let eventStart =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCIgaWQ9ImRzbUljb24iPjxnIGlkPSJJY29uLzQ4L3BsYXktYmxvY2stb3V0bGluZWQiIHN0cm9rZS13aWR0aD0iMSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMzUuMzc2IDI0TDEzLjI1IDM4LjM4MlY5LjYxOEwzNS4zNzYgMjR6IiBpZD0iQ29tYmluZWQtU2hhcGUiIHN0cm9rZT0iI0NFQUMwMSIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9IiNGRkZGRkYiLz48L2c+PC9zdmc+';

let lightdonfirst = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIAogICAgIHZpZXdCb3g9IjAgMCA0NyAyNiIgCiAgICAgd2lkdGg9IjQ3IiAKICAgICBoZWlnaHQ9IjI2Ij4KICA8Zz4KICAgIDxwYXRoIGNsYXNzPSJoYWxmLWNpcmNsZSIgCiAgICAgICAgICBkPSJNMi44LDIzLjhjMC0xMS45LDkuNC0yMS42LDIxLTIxLjZzMjEsOS43LDIxLDIxLjZIMi44eiIgCiAgICAgICAgICBmaWxsPSIjNjkxRkYwIi8+CiAgICA8cGF0aCBjbGFzcz0iYXJjIiAKICAgICAgICAgIGQ9Ik04LjcsMjAuNWMxLjUtOC42LDkuNi0xNC4zLDE3LjktMTIuN2M2LjIsMS4yLDExLjEsNi4xLDEyLjMsMTIuNCIKICAgICAgICAgIHN0cm9rZT0iI2ZmZmZmZiIgCiAgICAgICAgICBzdHJva2Utd2lkdGg9IjQiIAogICAgICAgICAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiAKICAgICAgICAgIGZpbGw9Im5vbmUiLz4KICA8L2c+Cjwvc3ZnPgo=';
let lightdonSecond = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIAogICAgIHZpZXdCb3g9IjAgMCA0NyAyNiIgCiAgICAgd2lkdGg9IjQ3IiAKICAgICBoZWlnaHQ9IjI2Ij4KICA8ZyB0cmFuc2Zvcm09InJvdGF0ZSgxODAsIDIzLjUsIDEzKSI+IDwhLS0gQWRqdXN0IHRoZSBvcmlnaW4gcG9pbnQgLS0+CiAgICA8cGF0aCBjbGFzcz0iaGFsZi1jaXJjbGUiIAogICAgICAgICAgZD0iTTIuOCwyMy44YzAtMTEuOSw5LjQtMjEuNiwyMS0yMS42czIxLDkuNywyMSwyMS42SDIuOHoiIAogICAgICAgICAgZmlsbD0iIzY5MUZGMCIvPgogICAgPHBhdGggY2xhc3M9ImFyYyIgCiAgICAgICAgICBkPSJNOC43LDIwLjVjMS41LTguNiw5LjYtMTQuMywxNy45LTEyLjdjNi4yLDEuMiwxMS4xLDYuMSwxMi4zLDEyLjQiCiAgICAgICAgICBzdHJva2U9IiNmZmZmZmYiIAogICAgICAgICAgc3Ryb2tlLXdpZHRoPSI0IiAKICAgICAgICAgIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgCiAgICAgICAgICBmaWxsPSJub25lIi8+CiAgPC9nPgo8L3N2Zz4K';

export const customBlockArray = [
  {
    type: 'colour_picker',
    message0: '%1',
    args0: [
      {
        type: 'field_colour',
        name: 'COLOUR',
        colour: '#ff0000',
      },
    ],
    output: 'Colour',
    colour: '#A65BA5',
    helpUrl: '%{BKY_COLOUR_PICKER_HELPURL}',
    tooltip: '%{BKY_COLOUR_PICKER_TOOLTIP}',
    extensions: ['parent_tooltip_when_inline'],
  },
  {
    type: 'when_program_start',
    message0: '%1 When Program Start',
    args0: [
      {
        type: 'field_image',
        src: eventStart,
        width: 40,
        height: 40,
        alt: '*',
      },
    ],
    nextStatement: 'trigger',
    colour: '#f5c402',
    tooltip: 'Triggers when the specified color is detected.',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'when_color_is',
    message0: '%1 When color is %2',
    args0: [
      {
        type: 'field_dropdown',
        name: 'WHEN_COLOR_DROPDOWN',
        options: [
          ['A', 'A'],
          ['B', 'B'],
          ['C', 'C'],
          ['D', 'D'],
          ['E', 'E'],
          ['F', 'F'],
        ],
      },
      {
        type: 'field_custom_colour', // This should match the registered field name
        name: 'WHEN_COLOR_PICKER',
        value: '#ff0000',
        dropdownBgColor: '#b09400',
      },
    ],
    nextStatement: 'trigger',
    colour: '#f5c402',
    tooltip: 'Triggers when the specified color is detected.',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'set_power_button_light',
    message0: 'Set Power Button light to %1',
    args0: [
      {
        type: 'field_custom',
        name: 'SET_POWER_COLOR_PICKER',
        value: '#C061F1',
      },
    ],
    // output: null,
    previousStatement: null,
    nextStatement: 'action',
    colour: '#8F60ED',
    tooltip: 'Sets the power button light to the specified color.',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'write_text',
    message0: 'Write %1',
    args0: [
      {
        type: 'field_input',
        name: 'WRITE_TEXT',
        text: '',
      },
    ],
    // output: "String",
    previousStatement: null,
    nextStatement: null,
    colour: '#9b6af6',
    tooltip: 'Write text',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'play_sound_until_done',
    message0: 'Play sound %1 until done',
    args0: [
      {
        type: 'field_dropdown',
        name: 'SOUND_NAME',
        options: [
          ['LIBRARY', 'Library'],
          ['BLUE', 'Blue'],
          ['UPLOAD', 'Upload'],
        ],
      },
    ],
    // output: null,
    previousStatement: null,
    nextStatement: null,
    colour: '#904DBE',
    tooltip: 'Plays the specified sound until it completes.',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'turn_on_for_seconds',
    message0: 'Turn on %1 for %2 seconds',
    args0: [
      // {
      //   type: "field_image",
      //   src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStwx33xUBEkAVHHw4cnyIKKtFW3DM2JQaMeA&s", // Update with your icon URL
      //   width: 25,  // Width of the icon
      //   height: 25, // Height of the icon
      //   alt: "*",   // Alt text (shown when icon is not available)
      //   flipRtl: false, // Optional: flip in RTL languages
      // },
      {
        type: 'field_bitmap',
        name: 'TURN_ON_BITMAP',
        width: 2,
        height: 2,
        fieldHeight: 20,
        buttons: {
          randomize: false,
          clear: false,
        },
        colours: {
          filled: '#ffffff',
          empty: '#4D1FA7',
        },
        value: [
          [1, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
        ],
      },
      {
        type: 'field_input',
        name: 'TURN_ON_SECOND',
      },
    ],
    // output: null,
    previousStatement: null,
    nextStatement: null,
    colour: '#9b6af6',
    tooltip: 'Turn on for a specified time',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'turn_on',
    message0: 'Turn on %1',
    args0: [
      {
        type: 'field_bitmap',
        name: 'TURN_ON',
        width: 2,
        height: 2,
        fieldHeight: 20,
        buttons: {
          randomize: false,
          clear: false,
        },
        colours: {
          filled: '#ffffff',
          empty: '#4D1FA7',
        },
        value: [
          [1, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0],
        ],
      },
    ],
    // output: null,
    previousStatement: null,
    nextStatement: null,
    colour: '#8F60ED',
    tooltip: 'Turn on',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'turn_off_pixel',
    message0: 'Turn off pixel',
    previousStatement: null,
    nextStatement: null,
    colour: '#8F60ED',
    tooltip: 'Turn off pixel',
    helpUrl: '',
    check: ['xyz'],
    // extensions: ['block_validation'],
  },
  {
    type: 'set_pixel',
    message0: 'Set Pixel brightness to %1 %',
    args0: [
      {
        type: 'field_input',
        name: 'TEXT',
        text: '',
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#8F60ED',
    tooltip: 'Set Pixel brightness',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'set_pixel_at',
    message0: 'Set Pixel at %1 , %2 to %3',
    args0: [
      {
        type: 'field_dropdown',
        name: 'pixelone',
        options: [
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
        ],
      },
      {
        type: 'field_dropdown',
        name: 'pixeltwo',
        options: [
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
        ],
      },
      {
        type: 'field_input',
        name: 'TEXT',
        text: '',
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#8F60ED',
    tooltip: 'Set Pixel at specific numbers',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'rotate',
    message0: 'Rotate %1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'pixelone',
        options: [['ALL', 'All']],
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#8F60ED',
    tooltip: 'Rotate',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'light_up',
    message0: '%1 Light up %2',
    args0: [
      {
        type: 'field_dropdown',
        name: 'optionone',
        options: [['ALL', 'All']],
      },
      {
        type: 'field_dropdown',
        name: 'optiontwo',
        options: [['ALL', 'All']],
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#8F60ED',
    tooltip: 'Rotate',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'flippermotor',
    message0: '%1 %2 run  %3 for %4 %5',
    args0: [
      {
        type: 'field_image',
        src: motorImage,
        width: 40,
        height: 40,
        alt: 'Motor',
      },
      {
        type: 'field_port_selector',
        name: 'PORT',
        value: 'A',
        isButtonShow: false,
      },
      {
        type: 'field_direction',
        name: 'CHOICE',
        options: [
          [
            {
              src: 'https://cdn4.iconfinder.com/data/icons/arrows-set-12/512/rightdirectionr-256.png',
              width: 20,
              height: 20,
            },
            'Right',
          ],
          [
            {
              src: 'https://cdn3.iconfinder.com/data/icons/arrows-set-12/512/leftdirection-64.png',
              width: 20,
              height: 20,
            },
            'Left',
          ],
        ],
      },
      {
        type: 'field_number',
        name: 'INCREMENT_NUMBER',
        min: 0,
        // max: 50,
      },
      {
        type: 'field_dropdown',
        name: 'INCREMENTS',
        options: [
          ['rotations', 'Rotations'],
          ['degrees', 'degrees'],
          ['seconds', 'seconds'],
        ],
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: '#0090F5',
    tooltip: 'Run motor for specified rotations',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'set_speed_to',
    message0: '%1 %2 set speed to %3 %',
    args0: [
      {
        type: 'field_image',
        src: motorImage,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_port_selector',
        name: 'PORT',
        value: 'A',
        isButtonShow: false,
      },
      {
        type: 'field_number',
        name: 'ENTER_NUMBER',
        min: 0,
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#0090F5',
    tooltip: 'Rotate',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'set_movement_speed_to',
    message0: '%1 set movement speed to %2%',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_number',
        name: 'ENTER_NUMBER',
        min: 0,
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'set_movement_motors_to',
    message0: '%1 set movement motors to %2',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_port_selector',
        name: 'PORT',
        value: 'A',
        isButtonShow: false,
        bgColor: '#ec5ac8',
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'start_moving',
    message0: '%1 start moving %2',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_direction',
        name: 'CHOICE',
        options: [
          [
            {
              src: 'https://cdn4.iconfinder.com/data/icons/navigation-40/24/to-up-512.png',
              width: 20,
              height: 20,
            },
            'Forward',
          ],
          [
            {
              src: 'https://cdn4.iconfinder.com/data/icons/navigation-40/24/to-down-512.png',
              width: 20,
              height: 20,
            },
            'Reverse',
          ],
        ],
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'stop_moving',
    message0: '%1 stop moving',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'forever',
    message0: 'forever',
    message1: '%1',
    args1: [
      {
        type: 'input_statement',
        name: 'DO0',
      },
    ],
    previousStatement: null,
    colour: '#ffb515',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'if_then',
    message0: 'if %1 then',
    args0: [
      {
        type: 'input_value',
        name: 'IF0',
        check: 'Boolean',
      },
    ],
    message1: '%1',
    args1: [
      {
        type: 'input_statement',
        name: 'DO0',
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: '#f4b844',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'is_color',
    message0: '%1 %2 is colour %3 ?',
    args0: [
      {
        type: 'field_image',
        src: squareImage,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_ports',
        name: 'PORT',
        options: [
          ['A', 'A'],
          ['B', 'B'],
          ['C', 'C'],
          ['D', 'D'],
          ['E', 'E'],
          ['F', 'F'],
        ],
        bgColor:"#34d2f1"
      },
      {
        type: 'field_custom_colour',
        name: 'WHEN_COLOR_PICKER',
        value: '#ff0000',
        dropdownBgColor: '#2596be',
      },
    ],
    inputsInline: true,
    output: 'Boolean',
    colour: '#34ccf1',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'light_up',
    message0: '%1 %2 light up %3',
    args0: [
      {
        type: 'field_image',
        src: redioImage,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_port_selector',
        name: 'PORT',
        value: '#691ff0',
        isButtonShow: true,
      },
      {
        type: 'field_light_up',
        name: 'CHOICE',
        options: [
          [{
            src: lightdonfirst,
            width: 40,
            height: 21,
            alt: 'Custom Icon',
            direction: "down"
          }, 'RED'],
          [{
            src: lightdonfirst,
            width: 40,
            height: 21,
            alt: 'Custom Icon',
            direction: "down"
          }, 'white'],
          [{
            src: lightdonSecond,
            width: 40,
            height: 21,
            alt: 'Custom Icon',
            direction: "up"
          }, 'black'],
          [{
            src: lightdonSecond,
            width: 40,
            height: 21,
            alt: 'Custom Icon',
            direction: "up"
          }, 'green'],
        ],
      }
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#691ff0',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'is_sensors_2',
    message0: '%1 %2 is %3 %4 %5?',
    args0: [
      {
        type: 'field_image',
        src: redioImage,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_ports',
        name: 'PORT',
        options: [
          ['A', 'A'],
          ['B', 'B'],
          ['C', 'C'],
          ['D', 'D'],
          ['E', 'E'],
          ['F', 'F'],
        ],
        bgColor:"#34d2f1"
      },
      {
        type: 'field_dropdown',
        name: 'DISTANCE_COMPARISON',
        options: [
          ['closer than', 'closer than'],
          ['farther than', 'farther than'],
          ['exactly at', 'exactly at'],
        ],
      },
      {
        type: 'field_number',
        name: 'ENTER_NUMBER',
        min: 0,
      },
      {
        type: 'field_dropdown',
        name: 'UNITS',
        options: [
          ['%', '%'],
          ['cm', 'cm'],
          ['inches', 'inches'],
        ],
      },
    ],
    inputsInline: true,
    output: 'Boolean',
    colour: '#67c9ed',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'move_forved_for_rotation',
    message0: '%1 move %2 for %3 %4',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_direction',
        name: 'CHOICE',
        options: [
          [
            {
              src: 'https://cdn4.iconfinder.com/data/icons/navigation-40/24/to-up-512.png',
              width: 20,
              height: 20,
            },
            'Forward',
          ],
          [
            {
              src: 'https://cdn4.iconfinder.com/data/icons/navigation-40/24/to-down-512.png',
              width: 20,
              height: 20,
            },
            'Reverse',
          ],
        ],
      },
      {
        type: 'field_number',
        name: 'ENTER_NUMBER',
        min: 0,
      },
      {
        type: 'field_dropdown',
        name: 'TIME_UNITS',
        options: [
          ['rotations', 'Rotations'],
          ['degrees', 'degrees'],
          ['seconds', 'seconds'],
        ],
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'move_right_for_rotation',
    message0: '%1 move %2 for %3 %4',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_custom_angles',
        name: 'ANGLE',
        value: 0,
        // min: -100,
        // max: 100,
        // precision: 5,
        // clockwise: true,
        // displayMin: -100,
        // displayMax: 100,
        // minorTick: 5,
        // majorTick: 20,
      },
      {
        type: 'field_number',
        name: 'ENTER_NUMBER',
        min: 0,
      },
      {
        type: 'field_dropdown',
        name: 'TIME_UNITS',
        options: [
          ['rotations', 'Rotations'],
          ['degrees', 'degrees'],
          ['seconds', 'seconds'],
        ],
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'start_moving_right',
    message0: '%1 start moving %2',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_custom_angles',
        name: 'ANGLE',
        value: 0,
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'if_then_else',
    message0: 'if %1 then',
    args0: [
      {
        type: 'input_value',
        name: 'IF0',
        check: 'Boolean',
      },
    ],
    message1: '%1 else',
    args1: [
      {
        type: 'input_statement',
        name: 'DO0',
      },
    ],
    message2: '%1',
    args2: [
      {
        type: 'input_statement',
        name: 'ELSE',
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: '#ffb515',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'wait_seconds',
    message0: 'wait %1 seconds',
    args0: [
      {
        type: 'field_number',
        name: 'WAIT_TIME',
        value: 0,
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ffb515',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: 'moment_with_time_direction',
    message0: '%1 move %2 for %3 %4',
    args0: [
      {
        type: 'field_image',
        src: motorImage2,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_direction',
        name: 'CHOICE',
        options: [
          [
            {
              src: 'https://cdn4.iconfinder.com/data/icons/navigation-40/24/to-up-512.png',
              width: 20,
              height: 20,
            },
            'Forward',
          ],
          [
            {
              src: 'https://cdn4.iconfinder.com/data/icons/navigation-40/24/to-down-512.png',
              width: 20,
              height: 20,
            },
            'Reverse',
          ],
        ],
      },
      {
        type: 'field_number',
        name: 'INCREMENT_NUMBER',
        min: 0,
        // max: 50,
      },
      {
        type: 'field_dropdown',
        name: 'INCREMENTS',
        options: [
          ['rotations', 'Rotations'],
          ['degrees', 'degrees'],
          ['seconds', 'seconds'],
          ['cm', 'cm'],
          ['inches', 'inches'],
        ],
      },
    ],
    check: ['xyz'],
    previousStatement: null,
    nextStatement: null,
    colour: '#ec5ac8',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: "controls_wait_until",
    message0: "wait until %1",
    args0: [
      {
        type: "input_value",
        name: "CONDITION",
        check: "Boolean"
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: "#ffb515",
    tooltip: "Waits until the condition becomes true",
    helpUrl: "https://www.example.com/help#wait_until"
  },
  {
    type: "event_button_action",
    message0: "when %1 button is %2",
    args0: [
      {
        type: "field_dropdown",
        name: "BUTTON_SIDE",
        options: [
          ["left", "LEFT"],
          ["right", "RIGHT"]
        ]
      },
      {
        type: "field_dropdown",
        name: "ACTION",
        options: [
          ["pressed", "PRESSED"],
          ["released", "RELEASED"]
        ]
      }
    ],
    // previousStatement: null,
    nextStatement: null,
    colour: "#f5c402",  // Event blocks typically use color 65 (green)
    tooltip: "Triggers when a button action occurs",
    helpUrl: "https://www.example.com/help#button_events"
  },
  {
    type: "motor_reset_timer",
    message0: "reset timer",
    args0: [],
    previousStatement: null,
    nextStatement: null,
    colour: "#34ccf1",  // Event blocks typically use color 65 (green)
    tooltip: "Reset Timer",
  },
  {
    type: "controls_repeat_ext",
    message0: "repeat %1 %2 %3",
    args0: [
      {
        type: "field_number",
        name: "REPEAT_TIMES",
      },
      {
        type: "input_dummy"
      },
      {
        type: "input_statement",
        name: "DO0"
      }
    ],
    previousStatement: null,
    nextStatement: null,
    colour: "#ffb515",
    // mutator: "controls_repeat_mutator"
  },
  {
    type: 'stop_action',
    message0: 'Stop %1',
    args0: [
      {
        type: 'field_dropdown',
        name: 'STOP',
        options: [
          ['ALL', 'All'],
          ['THIS STACK', 'This Stack'],
          ['AND EXIT PROGRAM', 'And Exit Program'],
        ],
      },
    ],
    previousStatement: null,
    colour: '#ffb515',
    tooltip: 'Stops all processes.',
    helpUrl: '',
  },
  {
    type: 'sensors_press_option',
    message0: '%1 %2 is colour %3 ?',
    args0: [
      {
        type: 'field_image',
        src: squareImage,
        width: 40,
        height: 40,
        alt: '*',
      },
      {
        type: 'field_port_selector',
        name: 'PORT',
        value: 'A',
        isButtonShow: true,
        bgColor: '#2596be',
      },
      {
        type: "field_dropdown",
        name: "PRESS_OPTIONS",
        options: [
          ["pressed", "pressed"],
          ["hard-pressed", "hard-pressed"],
          ["released", "released"]
        ]
      }

    ],
    inputsInline: true,
    output: 'Boolean',
    colour: '#34ccf1',
    tooltip: '',
    helpUrl: '',
    // extensions: ['block_validation'],
  },
  {
    type: "math_add",
    message0: "%1 + %2",
    args0: [
      { type: "input_value", name: "A", check: "Number" },
      { type: "input_value", name: "B", check: "Number" }
    ],
    output: "Number",
    inputsInline: true,
    colour: 230
  },
  {
    type: "math_subtract",
    message0: "%1 - %2",
    args0: [
      { type: "input_value", name: "A", check: "Number" },
      { type: "input_value", name: "B", check: "Number" }
    ],
    output: "Number",
    inputsInline: true,
    colour: 230
  },
  {
    type: "math_multiply",
    message0: "%1 × %2",
    args0: [
      { type: "input_value", name: "A", check: "Number" },
      { type: "input_value", name: "B", check: "Number" }
    ],
    output: "Number",
    inputsInline: true,
    colour: 230
  },
  {
    type: "math_divide",
    message0: "%1 ÷ %2",
    args0: [
      { type: "input_value", name: "A", check: "Number" },
      { type: "input_value", name: "B", check: "Number" }
    ],
    inputsInline: true,
    output: "Number",
    colour: 230

  },
  {
    type: "logic_compare",
    message0: "%1 %2 %3",
    args0: [
      { type: "input_value", name: "A" },
      {
        type: "field_dropdown",
        name: "OP",
        options: [
          ["=", "EQ"],
          ["≠", "NEQ"],
          ["<", "LT"],
          ["≤", "LTE"],
          [">", "GT"],
          ["≥", "GTE"]
        ]
      },
      { type: "input_value", name: "B" }
    ],
    output: "Boolean",
    inputsInline: true,
    colour: 210
  },
  {
    type: "logic_equal",
    message0: "%1 = %2",
    args0: [
      { type: "input_value", name: "A" },
      { type: "input_value", name: "B" }
    ],
    output: "Boolean",
    colour: "#00b94d",
    tooltip: "Returns true if values are equal"
  },
  {
    type: "logic_not_equal",
    message0: "%1 ≠ %2",
    args0: [
      { type: "input_value", name: "A" },
      { type: "input_value", name: "B" }
    ],
    output: "Boolean",
    colour: "#00b94d",
    tooltip: "Returns true if values are not equal"
  },
  {
    type: "logic_less",
    message0: "%1 < %2",
    args0: [
      {
        'type': 'input_value',
        'name': 'FirstValue',
        'check': 'Number',
      },
      {
        'type': 'input_value',
        'name': 'secondValue',
        'check': 'Number',
      },
    ],
    output: "Boolean",
    inputsInline: true,
    colour: "#00b94d",
    tooltip: "Returns true if left value is smaller"
  },
  {
    type: "logic_less_eq",
    message0: "%1 ≤ %2",
    args0: [
      { type: "input_value", name: "A" },
      { type: "input_value", name: "B" }
    ],
    output: "Boolean",
    colour: "#00b94d",
    tooltip: "Returns true if left value is smaller or equal"
  },
  {
    type: "logic_greater",
    message0: "%1 > %2",
    args0: [
      {
        'type': 'input_value',
        'name': 'FirstValue',
        'check': 'Number',
      },
      {
        'type': 'input_value',
        'name': 'secondValue',
        'check': 'Number',
      },
    ],
    output: "Boolean",
    inputsInline: true,
    colour: "#00b94d",
    tooltip: "Returns true if left value is larger"
  },
  {
    type: "logic_greater_eq",
    message0: "%1 ≥ %2",
    args0: [
      { type: "input_value", name: "A" },
      { type: "input_value", name: "B" }
    ],
    output: "Boolean",
    colour: "#00b94d",
    tooltip: "Returns true if left value is larger or equal"
  },
  {
    type: "logic_and",
    message0: "%1 and %2",
    args0: [
      { type: "input_value", name: "A", check: "Boolean" },
      { type: "input_value", name: "B", check: "Boolean" }
    ],
    output: "Boolean",
    colour: "#00b94d",
    inputsInline: true,
    tooltip: "Returns true if both conditions are true"
  },
  {
    type: "logic_or",
    message0: "%1 or %2",
    args0: [
      { type: "input_value", name: "A", check: "Boolean" },
      { type: "input_value", name: "B", check: "Boolean" }
    ],
    output: "Boolean",
    colour: "#00b94d",
    inputsInline: true,
    tooltip: "Returns true if at least one condition is true"
  },
  {
    type: "logic_not",
    message0: "not %1",
    args0: [
      { type: "input_value", name: "BOOL", check: "Boolean" }
    ],
    output: "Boolean",
    colour: "#00b94d",
    inputsInline: true,
    tooltip: "Returns the opposite of the input value"
  },
  {
    type: "random_number_picker",
    message0: "pick random %1 to %2",
    args0: [
      {type: "input_value", name: "FROM", check: "Number"},
      {type: "input_value", name: "TO", check: "Number"}
    ],
    inputsInline: true,
    output: "Number",
    colour: "#00b94d",
    tooltip: "Picks a random number between two values",
    helpUrl: ""
  },
  {
    type: 'timer_block',
    message0: 'timer',
    args0: [],
    output: null,
    colour: "#34ccf1",
    helpUrl: '%{BKY_VARIABLES_GET_HELPURL}',
    tooltip: '%{BKY_VARIABLES_GET_TOOLTIP}',
    extensions: ['contextMenu_variableSetterGetter'],
  },
  {
    type: "sensor_distance_in",
    message0: "%1 distance in %2",
    args0: [
      {
        type: 'field_ports',
        name: 'PORT',
        options: [
          ['A', 'A'],
          ['B', 'B'],
          ['C', 'C'],
          ['D', 'D'],
          ['E', 'E'],
          ['F', 'F'],
        ],
        bgColor:"#34d2f1"
      },
      {
        type: 'field_dropdown',
        name: 'UNITS',
        options: [
          ['%', '%'],
          ['cm', 'cm'],
          ['inches', 'inches'],
        ],
      },
    ],
    inputsInline: true,
    output: "Number",
    colour: "#34ccf1",
    tooltip: "Picks a random number between two values",
    helpUrl: ""
  }

];


// {
//   type: 'field_custom_colour', // This should match the registered field name
//   name: 'WHEN_COLOR_PICKER',
//   options: [
//     ['#E700A7', '#E700A7'],
//     ['#0091f5', '#0091f5'],
//     ['#78E8FF', '#78E8FF'],
//     ['#00A745', '#00A745'],
//     ['#FFE360', '#FFE360'],
//     ['#FF000C', '#FF000C'],
//     ['#FFFFFF', '#FFFFFF'],
//     ['#000000', '#000000'],
//     ["#FFFFFF", 'none'],  
//   ],
//   dropdownBgColor: '#b09400',
// },