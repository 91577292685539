import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { PartListProps } from './types';
import DraggablePiece from './DraggablePiece';

const PartList: React.FC<PartListProps> = React.memo(
  ({ parts, inventory, onPartClick, setPage, currentPage, lastPage }) => {
    return (
      <div className="absolute bottom-4 overflow-auto left-1/2 transform -translate-x-1/2 bg-white rounded-full shadow-lg py-2 px-4 max-w-full-calc">
        <div className="flex space-x-4 items-center">
          {parts.length === 0 ? (
            [...Array(10)].map((_, index) => (
              <div key={index} className="relative">
                <div className="w-20 h-20 bg-gray-200 rounded-full overflow-hidden flex-shrink-0 animate-pulse">
                  <div className="w-full h-full bg-gray-300"></div>
                </div>
                <div className="absolute bottom-0 right-0 bg-gray-400 text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center shadow-md z-10 transform translate-x-1/4 translate-y-1/4 animate-pulse">
                  <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
                </div>
              </div>
            ))
          ) : (
            <>
              <button>
                <FaAngleLeft
                  type="button"
                  className={`${
                    currentPage !== 1 ? 'cursor-pointer' : 'cursor-not-allowed'
                  }`}
                  onClick={() => currentPage !== 1 && setPage(currentPage - 1)}
                />
              </button>
              {parts.map((piece, index) => {
                const remainingQuantity = inventory[piece.filename] || 0;
                const isDisabled = remainingQuantity === 0;

                return (
                  <DraggablePiece
                    key={index}
                    piece={piece}
                    isDisabled={isDisabled}
                    remainingQuantity={remainingQuantity}
                    onPartClick={onPartClick}
                  />
                );
              })}
              <button>
                <FaAngleRight
                  type="button"
                  className={`block ${
                    lastPage !== currentPage
                      ? 'cursor-pointer'
                      : 'cursor-not-allowed'
                  }`}
                  onClick={() =>
                    currentPage < lastPage && setPage(currentPage + 1)
                  }
                />
              </button>
            </>
          )}
        </div>
      </div>
    );
  },
);

export default PartList;
