import { FieldAngle} from '@blockly/field-angle';
import * as Blockly from 'blockly';
import { ImageProperties } from 'blockly/core/field_dropdown';

interface ColorOption {
  value: string;
}
type MenuOption = [string | ImageProperties, string];


class FieldCustomColour extends Blockly.Field<string | undefined> {
  private colours: ColorOption[];
  private isFieldDisposed = false;
  private dropdownBgColor: string;
  private noneSvg = `
    <svg width="30" height="30" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" fill="white" stroke="#888" stroke-width="1"/>
      <line x1="5" y1="5" x2="18" y2="18" stroke="red" stroke-width="2"/>
      <line x1="18" y1="5" x2="5" y2="18" stroke="red" stroke-width="2"/>
    </svg>
  `;

  constructor(opt_value?: string, dropdownBgColor?: string) {
    super(opt_value || '#E700A7');
    this.dropdownBgColor = dropdownBgColor || '#FFFFFF'; // Default color
    this.colours = [
      { value: '#E700A7' },
      { value: '#0091f5' },
      { value: '#78E8FF' },
      { value: '#00A745' },
      { value: '#FFE360' },
      { value: '#ff0000' },
      { value: '#FFFFFF' },
      { value: '#000000' },
      { value: 'none' },
    ];
  }

  public init(): void {
    super.init();
    // Ensure the field is initialized with the initial color specified
    this.setValue(this.getValue());
  }

  static fromJson(options: any): FieldCustomColour {
    return new FieldCustomColour(
      options?.value as string,
      options?.dropdownBgColor as string,
    );
  }

  protected showEditor_(): void {
    Blockly.DropDownDiv.clearContent();
    // border of arrow color
    Blockly.DropDownDiv.setColour(this.dropdownBgColor, '#9e8500');

    const dropdownDiv = Blockly.DropDownDiv.getContentDiv() as HTMLElement;
    const grid = document.createElement('div');
    grid.className = 'custom-dropdown';

    // Use the instance property for setting the background color
    grid.style.backgroundColor = this.dropdownBgColor;

    this.colours.forEach(colour => {
      const buttonWrapper = document.createElement('div');
      buttonWrapper.style.display = 'flex';
      buttonWrapper.style.justifyContent = 'center';
      buttonWrapper.style.alignItems = 'center';
      buttonWrapper.style.border = 'solid 1px rgba(0, 0, 0, 0.25)';
      buttonWrapper.style.borderRadius = '5px';
      buttonWrapper.style.padding = '5px';

      const button = document.createElement('button');
      button.className = 'custom-dropdown-button';
      button.style.width = '30px';
      button.style.height = '30px';
      button.style.padding = '0';
      button.style.borderRadius = '50%';
      button.style.cursor = 'pointer';
      button.style.position = 'relative';

      if (colour.value === 'none') {
        button.innerHTML = this.noneSvg;
      } else {
        button.style.backgroundColor = colour.value;
      }

      button.addEventListener('click', () => {
        this.setValue(colour.value);
        Blockly.DropDownDiv.hideIfOwner(this, true);
      });

      buttonWrapper.appendChild(button);
      grid.appendChild(buttonWrapper);
    });

    dropdownDiv.appendChild(grid);
    Blockly.DropDownDiv.showPositionedByField(this, () => true);
  }

  public setValue(newValue: string): void {
    super.setValue(newValue);

    // Get the field rect element
    const fieldRect = this.fieldGroup_?.querySelector(
      'rect.blocklyFieldRect',
    ) as SVGRectElement;
    if (!fieldRect) return;

    if (newValue === 'none') {
      // Create a new SVG element for the "none" icon
      const svgContainer = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg',
      );
      svgContainer.setAttribute('width', '24');
      svgContainer.setAttribute('height', '24');
      svgContainer.setAttribute('viewBox', '0 0 24 24');
      svgContainer.innerHTML = `
        <line x1="5" y1="10" x2="15" y2="20" stroke="red" stroke-width="2"/>
        <line x1="15" y1="10" x2="5" y2="20" stroke="red" stroke-width="2"/>
      `;

      // Remove any existing SVG
      const existingSvg = this.fieldGroup_?.querySelector('svg');
      if (existingSvg) existingSvg.remove();

      // Add the new SVG
      this.fieldGroup_?.appendChild(svgContainer);

      // Set background to transparent
      fieldRect.style.fill = '#ffffff ';
    } else {
      // Remove any existing SVG
      const existingSvg = this.fieldGroup_?.querySelector('svg');
      if (existingSvg) existingSvg.remove();

      // Set the color
      fieldRect.style.fill = newValue;
    }
  }

  public dispose(): void {
    this.isFieldDisposed = true;
    super.dispose();
  }

  public doClassValidation_(newValue?: string): string | undefined {
    if (!newValue) {
      return '#ff0000';
    }
    if (newValue === 'none' || /^#[0-9a-f]{6}$/i.test(newValue)) {
      return newValue;
    }
    return undefined;
  }

  public getValue(): string {
    return String(this.value_ || '#ff0000');
  }

  public getText(): string {
    return '';
  }

  public clone(): FieldCustomColour {
    return new FieldCustomColour(this.getValue());
  }
}

class FieldColorDropdown extends Blockly.FieldDropdown {
  private dropdownBgColor: string;
  private noneSvg: string;

  constructor(menuGenerator: Blockly.MenuOption[],
    validator?: Blockly.FieldDropdownValidator,
    config?: Blockly.FieldDropdownConfig & { dropdownBgColor?: string }) {
      super(menuGenerator, validator, config);
    this.dropdownBgColor = config?.dropdownBgColor || '#f9f9f9';

    // Default "none" option SVG (if required)
    this.noneSvg = `
      <svg width="30" height="30" viewBox="0 0 24 24">
      <line x1="18" y1="5" x2="5" y2="18" stroke="red" stroke-width="2"/>
    </svg>
    `;
  }
  public initView(): void {
    super.initView();
    this.setValue(this.getValue() || '#FF000C');
  }

  public render_(): void {
    // Call the parent render method first
    super.render_();

    // Attempt to modify border radius after rendering
    if (this.borderRect_) {
      // Multiple methods to set border radius
      this.borderRect_.setAttribute('rx', '15');
      this.borderRect_.setAttribute('ry', '15');

      this.borderRect_.style.stroke = '#ffffff'; // White border
      this.borderRect_.style.strokeWidth = '1px'; // Optional: Border width
    }
  }

  /**
   * Dropdown box ka UI show karne wala method.
   */
  protected showEditor_(e: MouseEvent): void {
    super.showEditor_(e);
    Blockly.DropDownDiv.clearContent();
    // border of arrow color
    Blockly.DropDownDiv.setColour(this.dropdownBgColor, this.dropdownBgColor);

    const dropdownDiv = Blockly.DropDownDiv.getContentDiv() as HTMLElement;
    const grid = document.createElement('div');
    grid.className = 'custom-dropdown';

    const options = this.getOptions();

    // Use the instance property for setting the background color
    grid.style.backgroundColor = this.dropdownBgColor

    options.forEach(([label, value]) => {
      const buttonWrapper = document.createElement('div');
      buttonWrapper.style.display = 'flex';
      buttonWrapper.style.justifyContent = 'center';
      buttonWrapper.style.alignItems = 'center';
      buttonWrapper.style.border = 'solid 1px rgba(0, 0, 0, 0.25)';
      buttonWrapper.style.borderRadius = '5px';
      buttonWrapper.style.padding = '5px';

      const button = document.createElement('button');
      button.className = 'custom-dropdown-button';
      button.style.width = '30px';
      button.style.height = '30px';
      button.style.padding = '0';
      button.style.borderRadius = '50%';
      button.style.cursor = 'pointer';
      button.style.position = 'relative';

      // 🎨 Handle 'none' as special case (if required)
      if (value === 'none') {
        button.style.backgroundColor = "#FFFFFF";
        button.innerHTML = this.noneSvg; // 👈 None ka special case handle karo
      } else {
        button.style.backgroundColor = value;
      }

      // ✅ On click, set selected value
      button.addEventListener('click', () => {
        this.setValue(value);
        Blockly.DropDownDiv.hideIfOwner(this, true);
      });

      buttonWrapper.appendChild(button);
      grid.appendChild(buttonWrapper);
    });

    dropdownDiv.appendChild(grid);
    Blockly.DropDownDiv.showPositionedByField(this, () => true);
  }

  /**
   * Field ka color update karne ka logic.
   */
  public applyColour(): void {
    const selectedColor = this.getValue();
    const fieldRect = this.fieldGroup_?.querySelector(
      'rect.blocklyFieldRect',
    ) as SVGRectElement;

    if (fieldRect) {
      if (selectedColor === "none") {
        fieldRect.style.fill = "FFFFFF"
      } else {
        fieldRect.style.fill = selectedColor || '#C061F1';
      }
    }
  }

  /**
   * Value set karne ke baad field ka color update karo.
   */
  public setValue(newValue: string): void {
    super.setValue(newValue);

    const fieldRect = this.fieldGroup_?.querySelector(
      'rect.blocklyFieldRect',
    ) as SVGRectElement;
    if (!fieldRect) return;

    if (newValue === 'none') {
      this.showNoneIcon(); // Alag method me SVG ko handle karo
      fieldRect.style.fill = '#ffffff'; // Transparent background
    } else {
      this.removeNoneIcon();
      this.applyColour();
    }
  }

  private showNoneIcon(): void {
    const existingSvg = this.fieldGroup_?.querySelector('svg');
    if (existingSvg) existingSvg.remove();

    const svgContainer = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svgContainer.setAttribute('width', '24');
    svgContainer.setAttribute('height', '24');
    svgContainer.setAttribute('viewBox', '0 0 24 24');
    svgContainer.innerHTML = `
      <line x1="20" y1="5" x2="5" y2="20" stroke="red" stroke-width="3"/>
    `;
    this.fieldGroup_?.appendChild(svgContainer);
  }

  private removeNoneIcon(): void {
    const existingSvg = this.fieldGroup_?.querySelector('svg');
    if (existingSvg) existingSvg.remove();
  }

  /**
   * Field text ko empty rakhna hai, sirf color dikhe.
   */
  public getText(): string {
    return '';
  }
}


class FieldCustom extends Blockly.Field<string | undefined> {
  private colours: ColorOption[];
  private isFieldDisposed = false;

  constructor(opt_value?: string) {
    super(opt_value || '#C061F1');

    this.colours = [
      { value: '#E700A7' },
      { value: '#C061F1' },
      { value: '#0091f5' },
      { value: '#77E8FF' },
      { value: '#00CB54' },
      { value: '#00A845' },
      { value: '#FFE360' },
      { value: '#FCAC00' },
      { value: '#FF000C' },
      { value: '#FFFFFF' },
      { value: '#571CC1' },
    ];
  }

  public init(): void {
    super.init();
    // Ensure the field is initialized with the initial color specified
    this.setValue(this.getValue());
  }

  static fromJson(options: any): FieldCustom {
    return new FieldCustom(options?.value as string);
  }

  protected showEditor_(): void {
    Blockly.DropDownDiv.clearContent();
    Blockly.DropDownDiv.setColour('#691ff0', '#691ff0');

    const dropdownDiv = Blockly.DropDownDiv.getContentDiv() as HTMLElement;
    const grid = document.createElement('div');
    grid.className = 'custom-dropdown-power';

    this.colours.forEach(colour => {
      const button = document.createElement('button');
      button.className = 'custom-dropdown-power-button';
      button.style.backgroundColor = colour.value;

      if (this.getValue() === colour.value) {
        button.style.border = '2px solid white';
        button.style.transform = 'scale(1.2)';
        button.style.zIndex = '1';
        button.style.borderRadius = '4px';
      } else {
        button.style.border = '0px';
      }

      button.addEventListener('click', () => {
        this.setValue(colour.value);
        Blockly.DropDownDiv.hideIfOwner(this, true);
      });

      grid.appendChild(button);
    });

    dropdownDiv.appendChild(grid);
    Blockly.DropDownDiv.showPositionedByField(this, () => true);
  }

  public setValue(newValue: string): void {
    super.setValue(newValue);

    // Get the field rect element
    const fieldRect = this.fieldGroup_?.querySelector(
      'rect.blocklyFieldRect',
    ) as SVGRectElement;
    if (!fieldRect) return;

    // Set the color
    fieldRect.style.fill = newValue;
  }

  public dispose(): void {
    this.isFieldDisposed = true;
    super.dispose();
  }

  public doClassValidation_(newValue?: string): string | undefined {
    if (!newValue) {
      return '#ff0000';
    }
    if (newValue === 'none' || /^#[0-9a-f]{6}$/i.test(newValue)) {
      return newValue;
    }
    return undefined;
  }

  public getValue(): string {
    return String(this.value_ || '#ff0000');
  }

  public getText(): string {
    return '';
  }

  public clone(): FieldCustom {
    return new FieldCustom(this.getValue());
  }
}

class CustomDropdownField extends Blockly.Field {
  private selectedPorts: Set<string>;
  private isMultipleSelection: boolean;
  private portsOne: { value: string; label: string }[];
  private allButton: HTMLButtonElement;
  private multipleButton: HTMLButtonElement;
  private isButtonShow: boolean;
  private bgColor: string;

  constructor(
    opt_value?: string,
    isButtonShow?: boolean,
    bgColor: string = '#0090F5',
  ) {
    super(opt_value || 'A');
    this.isButtonShow = isButtonShow || false; // Default false
    this.selectedPorts = new Set((opt_value || 'A').split('+'));
    this.isMultipleSelection = false;
    this.bgColor = bgColor;
    this.allButton = document.createElement('button');
    this.multipleButton = document.createElement('button');
    this.portsOne = [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'E', label: 'E' },
      { value: 'F', label: 'F' },
    ];
  }

  init() {
    super.init();
    // Placeholder for updating UI
    this.updateUI_();
  }

  showEditor_() {
    Blockly.DropDownDiv.clearContent();
    Blockly.DropDownDiv.setColour(this.bgColor, '#0062A6');

    const dropdownDiv = Blockly.DropDownDiv.getContentDiv();
    const Mainwrapper = document.createElement('div');
    Mainwrapper.style.height = '300px';
    Mainwrapper.style.width = '290px';
    Mainwrapper.style.display = 'flex';
    Mainwrapper.style.justifyContent = 'center';

    const wrapper = document.createElement('div');
    wrapper.className = 'lls-port-selector';
    wrapper.style.backgroundColor = this.bgColor;
    wrapper.style.width = '162px';

    const hubWrapper = document.createElement('div');
    hubWrapper.className = 'lls-port-selector__hub-wrapper';

    const hub = document.createElement('div');
    hub.className = 'lls-port-selector__hub';

    const leftSensors = document.createElement('div');
    leftSensors.className =
      'lls-port-selector__sensors lls-port-selector__sensors--left';

    const rightSensors = document.createElement('div');
    rightSensors.className =
      'lls-port-selector__sensors lls-port-selector__sensors--right';

    const updateButtonStates = () => {
      const buttons = wrapper.querySelectorAll(
        '.sensor-port-pair__port-button',
      );
      buttons.forEach(button => {
        const buttonEl = button as HTMLElement;
        const port = buttonEl.getAttribute('data-port');
        if (port) {
          buttonEl.classList.toggle('selected', this.selectedPorts.has(port));
        }
      });
    };

    this.ports.forEach((port, index) => {
      const portPair = document.createElement('div');
      portPair.className = 'sensor-port-pair';

      const button = document.createElement('button');
      button.className = 'button sensor-port-pair__port-button';
      button.setAttribute('data-testid', `button-${port.value}`);
      button.setAttribute('data-port', port.value);
      button.textContent = port.label;

      if (this.selectedPorts.has(port.value)) {
        button.classList.add('selected');
      }

      button.addEventListener('click', () => {
        if (this.isMultipleSelection) {
          this.toggleSelection(port.value);
        } else {
          this.selectedPorts.clear();
          this.selectedPorts.add(port.value);
          Blockly.DropDownDiv.hideIfOwner(this, true);
        }
        this.updateUI_();
        updateButtonStates();
      });

      portPair.appendChild(button);

      if (index % 2 === 0) {
        leftSensors.appendChild(portPair);
      } else {
        rightSensors.appendChild(portPair);
      }
    });

    // Multiple and All Buttons

    hub.appendChild(leftSensors);
    hub.appendChild(rightSensors);
    hubWrapper.appendChild(hub);
    wrapper.appendChild(hubWrapper);

    this.createControlButtons(wrapper);
    Mainwrapper.appendChild(wrapper);
    dropdownDiv.appendChild(Mainwrapper);
    Blockly.DropDownDiv.showPositionedByField(this, () => true);
  }

  updateControlButtonStates() {
    const allSelected = this.ports.every(port =>
      this.selectedPorts.has(port.value),
    );
    const multipleSelected = this.selectedPorts.size > 1;

    this.allButton.classList.toggle('selected', allSelected);
    this.multipleButton.classList.toggle('selected', multipleSelected);
  }

  toggleSelection(port: string) {
    if (this.isMultipleSelection) {
      // Allow toggling multiple selections
      if (this.selectedPorts.has(port)) {
        this.selectedPorts.delete(port);
      } else {
        this.selectedPorts.add(port);
      }
    } else {
      // Allow only one selection
      this.selectedPorts.clear();
      this.selectedPorts.add(port);
    }
    this.updateUI_();
    this.updateButtonStates();
    this.updateControlButtonStates();
  }

  updateButtonStates() {
    const buttons = document.querySelectorAll('.sensor-port-pair__port-button');
    buttons.forEach(button => {
      const port = button.getAttribute('data-port');
      if (port) {
        button.classList.toggle('selected', this.selectedPorts.has(port));
      }
    });
  }

  createControlButtons(parent: any) {
    const multipleActions = document.createElement('div');
    multipleActions.className = 'lls-port-selector__multiple-actions';

    this.multipleButton = document.createElement('button');
    this.multipleButton.className = `button lls-port-selector__button-multiple ${this.isButtonShow ? 'display-none' : ''
      }`;
    this.multipleButton.textContent = 'Multiple';

    this.allButton = document.createElement('button');
    this.allButton.className = `button lls-port-selector__button-all ${this.isButtonShow ? 'display-none' : ''
      }`;
    this.allButton.textContent = 'All';

    this.multipleButton.addEventListener('click', () => {
      this.isMultipleSelection = !this.isMultipleSelection;
      // Update button state immediately
      this.multipleButton.classList.toggle(
        'selected',
        this.isMultipleSelection,
      );

      if (!this.isMultipleSelection) {
        const firstSelected = Array.from(this.selectedPorts)[0];
        this.selectedPorts.clear();
        if (firstSelected) this.selectedPorts.add(firstSelected);

        // Deselect the "All" button if "Multiple" is turned off
        this.allButton.classList.remove('selected');
      }

      this.updateUI_();
      this.updateButtonStates();
    });

    this.allButton.addEventListener('click', () => {
      const allSelected = this.ports.every(port =>
        this.selectedPorts.has(port.value),
      );
      if (allSelected) {
        this.selectedPorts.clear();
      } else {
        this.ports.forEach(port => this.selectedPorts.add(port.value));
        this.isMultipleSelection = true; // Ensure Multiple is active with All
        this.multipleButton.classList.add('selected');
      }
      this.updateUI_();
      this.updateButtonStates();
      this.updateControlButtonStates();
    });
    this.updateControlButtonStates();
    multipleActions.appendChild(this.multipleButton);
    multipleActions.appendChild(this.allButton);
    parent.appendChild(multipleActions);
  }

  selectAllPorts() {
    this.portsOne.forEach(port => this.selectedPorts.add(port.value));
    this.updateUI_();
  }

  updateUI_() {
    const value = Array.from(this.selectedPorts).join('+');
    super.setValue(value);
    if (this.textElement_) {
      this.textElement_.textContent = value;
    }
  }

  setValue(newValue: string) {
    this.selectedPorts = new Set(newValue.split('+'));
    super.setValue(newValue);
    this.updateUI_();
    this.updateButtonStates();
  }

  ports = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
  ];

  static fromJson(options: any): CustomDropdownField {
    return new CustomDropdownField(
      options?.value as string,
      options?.isButtonShow as boolean,
      (options?.bgColor as string) ?? '#0090F5',
    );
  }
}

class CustomPortSelector extends Blockly.FieldDropdown {
  private bgColor: string;

  constructor(options: (string | [string, string])[], opt_value?: string, bgColor: string = '#0090F5') {
    // Convert options to the format Blockly expects
    const formattedOptions = options.map(option => {
      if (Array.isArray(option)) {
        return option;
      }
      return [option, option] as [string, string];
    });
    
    super(() => formattedOptions);
    this.setValue(opt_value || formattedOptions[0][1]);
    this.bgColor = bgColor;
  }

  public init(): void {
    super.init();
    // Ensure the field is initialized with the initial color specified
    this.setValue(this.getValue());
  }

  showEditor_() {
    super.showEditor_()
    Blockly.DropDownDiv.clearContent();
    Blockly.DropDownDiv.setColour("#34d2f1", '#368fa9');

    const dropdownDiv = Blockly.DropDownDiv.getContentDiv();
    const mainWrapper = document.createElement('div');
    mainWrapper.style.cssText = `
      height: 300px;
      width: 290px;
      display: flex;
      justify-content: center;
    `;

    const wrapper = document.createElement('div');
    wrapper.style.cssText = `
      background-color: ${this.bgColor};
      width: 162px;
      padding: 10px;
      border-radius: 8px;
    `;

    const hubWrapper = document.createElement('div');
    hubWrapper.className = 'lls-port-selector__hub-wrapper';

    const hub = document.createElement('div');
    hub.className = 'lls-port-selector__hub';
    hub.style.cssText = `
      display: flex;
      justify-content: space-between;
    `;

    const leftSensors = document.createElement('div');
    leftSensors.className = 'lls-port-selector__sensors lls-port-selector__sensors--left';

    const rightSensors = document.createElement('div');
    rightSensors.className = 'lls-port-selector__sensors lls-port-selector__sensors--right';

    const options = this.getOptions();

    options.forEach(([label, value], index) => {
      const portPair = document.createElement('div');
      portPair.className = 'sensor-port-pair';
      portPair.style.marginBottom = '8px';
      portPair.style.borderColor = '#368fa9';

      const button = document.createElement('button');
      button.className = 'button sensor-port-pair__port-button';
      button.style.cssText = `
        height: 30px;
        color: #368fa9;
        cursor: pointer;
        transition: all 0.2s;
      `;
      button.setAttribute('data-testid', `button-${value}`);
      button.setAttribute('data-port', value);
      
      // Handle both string and image labels
      if (typeof label === 'string') {
        button.textContent = label;
      } else {
        const img = document.createElement('img');
        img.src = label.src;
        img.alt = label.alt || '';
        img.style.maxWidth = '100%';
        img.style.maxHeight = '100%';
        button.innerHTML = '';
        button.appendChild(img);
      }

      if (this.getValue() === value) {
        button.classList.add('selected');
        button.style.color = "#34d2f1";
        portPair.style.borderColor = '#000';
      }

      button.addEventListener('click', () => {
        this.setValue(value);
        
        button.classList.add('selected');
        button.style.color = "#368fa9";
        portPair.style.borderColor = '#000';

        Blockly.DropDownDiv.hideIfOwner(this, true);
      });

      portPair.appendChild(button);
      (index % 2 === 0 ? leftSensors : rightSensors).appendChild(portPair);
    });

    hub.appendChild(leftSensors);
    hub.appendChild(rightSensors);
    hubWrapper.appendChild(hub);
    wrapper.appendChild(hubWrapper);
    mainWrapper.appendChild(wrapper);
    dropdownDiv.appendChild(mainWrapper);
    Blockly.DropDownDiv.showPositionedByField(this, this.dropdownDispose_.bind(this));
  }

}

class IconDropdown extends Blockly.FieldDropdown {
  constructor(
    options: MenuOption[],
    validator?: Blockly.FieldDropdownValidator,
  ) {
    super(() => options, validator); // ✅ Wrap options in a function for compatibility
  }

  // ✅ Custom dropdown rendering
  protected createDropdown_() {
    const menu = document.createElement('div');
    menu.style.display = 'flex';
    menu.style.gap = '8px';
    menu.style.padding = '5px';
    menu.style.borderRadius = '6px';
    menu.style.background = '#007BFF';

    const options = this.getOptions(); // ✅ Always returns MenuOption[]

    options.forEach(([label, value]: MenuOption) => {
      const optionElement = document.createElement('div');
      optionElement.style.padding = '8px';
      optionElement.style.borderRadius = '4px';
      optionElement.style.cursor = 'pointer';
      optionElement.style.display = 'flex';
      optionElement.style.alignItems = 'center';
      optionElement.style.justifyContent = 'center';
      optionElement.style.background =
        this.getValue() === value ? '#0056b3' : 'transparent';
      optionElement.style.border =
        this.getValue() === value ? '2px solid white' : '1px solid transparent';

      // ✅ Render as text or image based on label content
      if (typeof label === 'string') {
        if (label.startsWith('http')) {
          // ✅ Safe now
          const img = document.createElement('img');
          img.src = label;
          img.width = 24;
          img.height = 24;
          optionElement.appendChild(img);
        } else {
          optionElement.textContent = label;
        }
      } else {
        // ✅ Handling ImageProperties Type
        const img = document.createElement('img');
        img.src = label.src;
        img.width = label.width || 24;
        img.height = label.height || 24;
        img.alt = label.alt || '';
        optionElement.appendChild(img);
      }

      optionElement.onclick = () => {
        this.setValue(value);
        Blockly.DropDownDiv.hideIfOwner(this);
      };

      menu.appendChild(optionElement);
    });

    Blockly.DropDownDiv.getContentDiv().appendChild(menu);
  }
}

export class CustomFieldAngle extends FieldAngle {
  SERIALIZABLE = true;
  CURSOR = 'pointer';
  private angleValue: number = 5;
  private maxAngle: number = 100;
  private stepSize: number = 5;
  private valueDisplay: HTMLElement | null = null;
  private angleLine: SVGElement | null = null;
  private handleGroup: SVGElement | null = null;

  constructor(value: string, validator?: (value: string) => boolean | null, config?: object) {
    super(value);
    this.angleValue = Number(value) || 0;
    this.angleValue = Math.round(this.angleValue / this.stepSize) * this.stepSize;
    this.setPrecision(1);
    this.setValue(String(this.angleValue));
  }
  public init(): void {
    super.init();
    // Get the current value and ensure it's not null before setting
    const value = this.getValue();
    if (value !== null) {
      // Convert to string if it's a number
      this.setValue(String(value));
    } else {
      // Use a default value like "0" or your initial value if getValue() returns null
      this.setValue("0");
    }
    this.updateSize_();
  }

  protected showEditor_(): void {
    const dropdownDiv = document.createElement('div');
    dropdownDiv.className = "blocklyDropDownContentFieldAngleMainDiv";

    const dropdownContent = document.createElement('div');
    dropdownContent.className = 'blocklyDropDownContentFieldAngleDiv';
    dropdownContent.setAttribute('role', 'menu');
    dropdownContent.setAttribute('aria-haspopup', 'true');

    // Create rotation wheel
    const rotationWheel = document.createElement('div');
    rotationWheel.className = 'lls-rotation-wheel';

    // Create wheel SVG
    const wheelSvg = this.createWheelSvg();
    rotationWheel.appendChild(wheelSvg);

    // Create controls
    const buttonContainer = this.createButtonContainer();
    rotationWheel.appendChild(buttonContainer);

    dropdownContent.appendChild(rotationWheel);
    dropdownDiv.appendChild(dropdownContent);

    // Append the dropdown div to the dropdown container
    Blockly.DropDownDiv.getContentDiv().appendChild(dropdownDiv);

    // Position and show the dropdown
    Blockly.DropDownDiv.setColour('#C80090', '#B2248A');
    Blockly.DropDownDiv.showPositionedByField(this, this.onHide_.bind(this));
  }

  private createWheelSvg(): SVGElement {
    const wheelSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    wheelSvg.setAttribute('height', '125px');
    wheelSvg.setAttribute('width', '125px');
    wheelSvg.setAttribute('version', '1.1');
    wheelSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    // Create circle
    const circle = this.createSvgElement('circle', {
      class: 'rotationWheelCircle',
      cx: '60', cy: '60', r: '47',
      height: '125px', width: '125px',
      style: 'fill: #C80090; stroke: #B2248A; stroke-width: 1;'
    });
    wheelSvg.appendChild(circle);

    // Create center point
    const centerPoint = this.createSvgElement('circle', {
      class: 'blocklyAngleCenterPoint',
      cx: '60', cy: '60', r: '2',
      style: 'stroke: #fff; stroke-width: 1; fill: #fff;'
    });
    wheelSvg.appendChild(centerPoint);

    // Create angle markers
    this.createAngleMarkers(wheelSvg);

    // Create angle line
    this.angleLine = this.createSvgElement('line', {
      class: 'blocklyAngleLine',
      x1: '60', y1: '60', x2: '60', y2: '13',
      style: 'stroke: #fff; stroke-width: 1; stroke-linecap: round; pointer-events: none;'
    });
    wheelSvg.appendChild(this.angleLine);

    // Calculate initial position
    const initialAngle = this.calculateAdjustedAngle(this.angleValue);
    const [handleX, handleY] = this.getCoordinatesFromAngle(initialAngle);

    // Update angle line position
    this.angleLine.setAttribute('x2', String(handleX));
    this.angleLine.setAttribute('y2', String(handleY));

    // Create and position handle
    this.handleGroup = this.createHandleGroup(handleX, handleY, initialAngle);
    wheelSvg.appendChild(this.handleGroup);

    // Add event listeners
    this.addWheelEventListeners(wheelSvg);

    return wheelSvg;
  }

  private createAngleMarkers(wheelSvg: SVGElement): void {
    // Create markers (0 to 360 degrees)
    for (let i = 0; i <= 20; i++) {
      const angle = i * 18 - 90; // 18 degrees per marker, starting from top
      const line = this.createSvgElement('line', {
        class: 'blocklyAngleMarks',
        transform: `rotate(${angle},60,60)`,
        x1: '94', x2: '100', y1: '60', y2: '60',
        style: 'stroke: #fff;stroke-width: 1;stroke-opacity: 0.5;'
      });
      wheelSvg.appendChild(line);
    }
  }

  private createButtonContainer(): HTMLElement {
    const buttonContainer = document.createElement('div');
    buttonContainer.style.cssText = `
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      background-color: #B2248A;
    `;

    // Create minus button
    const minusButton = document.createElement('button');
    minusButton.textContent = '-';
    minusButton.className = "fieldAngleButton";
    minusButton.addEventListener('click', () => {
      const newValue = this.angleValue - 1;
      this.setValue(String(newValue), "btn");
    });

    // Create value display
    this.valueDisplay = document.createElement('div');
    this.valueDisplay.id = 'angleValueDisplay';
    this.valueDisplay.style.cssText = `
      color: #ffffff;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
    `;
    this.updateDisplayText();

    // Create plus button
    const plusButton = document.createElement('button');
    plusButton.textContent = '+';
    plusButton.className = "fieldAngleButton";
    plusButton.addEventListener('click', () => {
      const newValue = this.angleValue + 1;
      this.setValue(String(newValue), "btn");
    });

    buttonContainer.appendChild(minusButton);
    buttonContainer.appendChild(this.valueDisplay);
    buttonContainer.appendChild(plusButton);

    return buttonContainer;
  }

  private addWheelEventListeners(wheelSvg: SVGElement): void {
    wheelSvg.addEventListener('mousedown', (e) => {
      e.preventDefault();
      const onMouseMove = (moveEvent: MouseEvent) => this.handleWheelMouseMove(wheelSvg, moveEvent);
      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
      
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    });
  }

  private handleWheelMouseMove(wheelSvg: SVGElement, e: MouseEvent): void {
    const svgRect = wheelSvg.getBoundingClientRect();
    const centerX = svgRect.left + svgRect.width / 2;
    const centerY = svgRect.top + svgRect.height / 2;
    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;

    // Calculate angle in degrees
    let angle = Math.atan2(mouseY, mouseX) * (180 / Math.PI);
    angle = (angle + 90 + 360) % 360;

    // Convert angles greater than 180 to negative for left side
    if (angle > 180) {
      angle -= 360;
    }
    
    // Map to range -maxAngle to maxAngle
    let value = Math.round((angle / 180) * this.maxAngle);
    
    // Apply step size and limits
    value = Math.round(value / this.stepSize) * this.stepSize;
    value = Math.max(-this.maxAngle, Math.min(this.maxAngle, value));
    
    if (this.angleValue !== value) {
      this.setValue(String(value));
      this.updateSize_();
    }
  }

  private updateDisplayText(): void {
    if (!this.valueDisplay) return;
    
    const displayText = this.angleValue === 0 ?
      `straight : 0` :
      this.angleValue > 0 ?
        `right: ${Math.abs(this.angleValue)}` :
        `left: ${this.angleValue}`;
    
    this.valueDisplay.textContent = displayText;
  }

  private updateAngleVisuals(): void {
    if (!this.angleLine || !this.handleGroup) return;

    const adjustedAngle = this.calculateAdjustedAngle(this.angleValue);
    const [newX, newY] = this.getCoordinatesFromAngle(adjustedAngle);

    // Update angle line
    this.angleLine.setAttribute('x2', String(newX));
    this.angleLine.setAttribute('y2', String(newY));

    // Update handle position
    this.handleGroup.setAttribute('transform', `translate(${newX},${newY}) rotate(${adjustedAngle})`);
  }

  private calculateAdjustedAngle(value: number): number {
    return (value / this.maxAngle) * 180 - 90;
  }

  private getCoordinatesFromAngle(angle: number): [number, number] {
    const radius = 47;
    const x = 60 + radius * Math.cos(angle * (Math.PI / 180));
    const y = 60 + radius * Math.sin(angle * (Math.PI / 180));
    return [x, y];
  }

  private createHandleGroup(handleX: number, handleY: number, handleAngle: number): SVGElement {
    const handleGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    handleGroup.setAttribute('transform', `translate(${handleX},${handleY}) rotate(${handleAngle})`);

    const handleCircle = this.createSvgElement('circle', {
      class: 'blocklyAngleDragHandle',
      cx: '0', cy: '0', r: '10',
      style: 'stroke: #fff; stroke-width: 5; stroke-opacity: 0.25; fill: #fff; cursor: pointer;'
    });
    handleGroup.appendChild(handleCircle);

    const arrowSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    arrowSvg.setAttribute('height', '10');
    arrowSvg.setAttribute('width', '10');
    arrowSvg.setAttribute('x', '-5');
    arrowSvg.setAttribute('y', '-5');
    arrowSvg.setAttribute('viewBox', '0 0 14 14');

    const arrowPath = this.createSvgElement('path', {
      d: 'M8.587 5.5L6 3.1 8.074 1 14 7l-5.926 6L6 10.9l2.587-2.4H0v-3h8.587z',
      fill: '#B2248A',
      'fill-rule': 'nonzero'
    });
    arrowSvg.appendChild(arrowPath);
    handleGroup.appendChild(arrowSvg);

    return handleGroup;
  }

  private createSvgElement(name: string, attributes: Record<string, string>): SVGElement {
    const element = document.createElementNS('http://www.w3.org/2000/svg', name);
    for (const [key, value] of Object.entries(attributes)) {
      element.setAttribute(key, value);
    }
    return element;
  }

  setValue(newValue: string, btn?: any): this {
    // Parse and constrain the value
    let parsedValue = Number(newValue) || 0;
    parsedValue = Math.max(-this.maxAngle, Math.min(this.maxAngle, parsedValue));

    // Round to nearest step size
    if (btn) {
      parsedValue = Math.round(parsedValue);
    } else {
      parsedValue = Math.round(parsedValue / this.stepSize) * this.stepSize;
    }

    // Store the value
    this.angleValue = parsedValue;

    // Update the text element
    if (this.textElement_) {
      const displayText = this.getFormattedValue(parsedValue);
      this.textElement_.textContent = displayText;
    }

    // Update the visual display
    this.updateDisplayText();
    this.updateAngleVisuals();

    // Call parent's update method
    // const displayText = this.getFormattedValue(parsedValue);
    super.doValueUpdate_(parsedValue);

    return this;
  }

  private getFormattedValue(value: number): string {
    return `${value === 0 ? "straight" : value > 0 ? "right" : "left"}: ${String(value)}`;
  }

  onHide_(): void {
    // Cleanup if needed
  }

  static fromJson(options: any): CustomFieldAngle {
    return new CustomFieldAngle(options['value']);
  }
}

class CustomLightField extends Blockly.FieldDropdown {
  private selectedOptions: Set<string> = new Set();
  private customSvg_: SVGGElement | null = null;
  private arrow_: SVGElement | null = null;
  private optionElements: Map<string, HTMLElement> = new Map();
  private waveElements: any;
  private optionWaveMap: any;
  private options: Blockly.MenuOption[];

  constructor(
    options: Blockly.MenuOption[],
    validator?: Blockly.FieldDropdownValidator,
  ) {
    super(() => options, validator);

    this.options = options;
    this.optionWaveMap = new Map();
  }

  public initView(): void {
    this.waveElements = [];
    super.initView();

    // Border ko transparent karo
    if (this.borderRect_) {
      this.borderRect_.style.fill = 'transparent';
      this.borderRect_.style.stroke = 'transparent';
      this.borderRect_.style.outline = 'none';
    }

    // // Text content ko khali karo
    // if (this.textElement_) {
    //   this.textElement_.textContent = '';
    // }

    // Agar custom SVG nahi hai, tabhi create karo
    if (!this.customSvg_) {
      this.customSvg_ = Blockly.utils.dom.createSvgElement(
        Blockly.utils.Svg.G,
        {
          'width': '69.8',
          'height': '34.8',
          'x': '0',
          'y': '5',
          'viewBox': '0 0 69.8 34.8',
          'transform': 'translate(0)',
        },
        this.fieldGroup_
      );

      // Background path
      Blockly.utils.dom.createSvgElement(
        Blockly.utils.Svg.PATH,
        {
          'fill': '#571cc1',
          'stroke-miterlimit': '10',
          'd': 'M17.1,1.3h35.3c8.8,0,16,7.2,16,16l0,0c0,8.8-7.2,16-16,16H17.1c-8.8,0-16-7.2-16-16l0,0C1.1,8.4,8.3,1.3,17.1,1.3z',
        },
        this.customSvg_
      );

      // White arrow
      Blockly.utils.dom.createSvgElement(
        Blockly.utils.Svg.PATH,
        {
          'fill': '#ffffff',
          'stroke-miterlimit': '10',
          'd': 'M57,20.7c-0.4,0-0.7-0.2-1-0.4L52,16.4c-0.5-0.6-0.5-1.4,0-2c0.6-0.6,9.3-0.6,9.9,0c0.5,0.6,0.5,1.4,0,2L58,20.3C57.7,20.6,57.4,20.7,57,20.7z',
        },
        this.customSvg_
      );

      // Wave-like patterns
      const wavePaths = [
        'M9.7,14.8c0.7-3.6,4.2-6,7.8-5.4c2.7,0.5,4.8,2.6,5.3,5.2',
        'M32.4,14.8c0.7-3.6,4.2-6,7.8-5.4c2.7,0.5,4.8,2.6,5.3,5.2',
        'M22.9,17.3c-0.7,3.6-4.2,6-7.8,5.4c-2.7-0.5-4.8-2.6-5.3-5.2',
        'M45.6,17.3c-0.7,3.6-4.2,6-7.8,5.4c-2.7-0.5-4.8-2.6-5.3-5.2',
      ];

      wavePaths.forEach((pathData, index) => {
        const waveElement = Blockly.utils.dom.createSvgElement(
          Blockly.utils.Svg.PATH,
          {
            fill: 'none',
            stroke: '#FFFFFF',
            'stroke-width': '2',
            'stroke-linecap': 'round',
            'stroke-miterlimit': '10',
            opacity: '0.2', // Default opacity
            d: pathData,
          },
          this.customSvg_
        );

        this.waveElements.push(waveElement);

        const optionKey = this.options[index]?.[1]; // Map wave to option name
        if (optionKey) {
          this.optionWaveMap.set(optionKey, waveElement);
        }
      });

    }

    // Dropdown arrow ko hide karo
    if (this.arrow_) {
      this.arrow_.style.display = 'none';
    }

    this.updateWaveOpacity();
  }

  private updateWaveOpacity(): void {
    if (!this.waveElements || this.waveElements.length === 0) {
      console.warn('waveElements not initialized!');
      return;
    }

    // Reset all wave opacity to 0.2 (unselected)
    this.waveElements.forEach((wave: any) => {
      wave.setAttribute('opacity', '0.2');
    });

    // Highlight the wave for selected options
    this.selectedOptions.forEach((option) => {
      const wave = this.optionWaveMap.get(option);
      if (wave) {
        wave.setAttribute('opacity', '1');
      }
    });
  }


  protected showEditor_(e?: MouseEvent): void {
    super.showEditor_(e);
    this.applyCustomStyling();
  
    // Agar sourceBlock_ exist karta hai to workspace ko access karo
    if (this.sourceBlock_ && this.sourceBlock_.workspace) {
      const workspace = this.sourceBlock_.workspace;
  
      // Check if workspace is WorkspaceSvg
      if (workspace instanceof Blockly.WorkspaceSvg) {
        // Correct positioning for dropdown
        Blockly.DropDownDiv.showPositionedByField(
          this,                             // Field jahan dropdown dikhega
          this.dropdownDispose_.bind(this), // Optional: Callback when dropdown hides
          5                                 // Optional: Secondary Y-offset (0 by default)
        );
      }
    }
  }
  


  private applyCustomStyling(): void {
    // Ensure optionElements is initialized
    if (!this.optionElements) {
      this.optionElements = new Map();
    }

    Blockly.DropDownDiv.clearContent();
    Blockly.DropDownDiv.setColour("#691ff0", "#691ff0");

    const dropdownDiv = Blockly.DropDownDiv.getContentDiv() as HTMLElement;

    const optionsContainer = document.createElement('div');
    optionsContainer.style.display = 'grid';
    optionsContainer.style.gridTemplateColumns = '1fr 1fr';

    // Clear previous option elements map
    this.optionElements.clear();

    const options = this.getOptions();

    options.forEach((option: any) => {
      if (Array.isArray(option)) {
        const [displayContent, value] = option;
        const optionElement = document.createElement('div');

        // Styling for option container
        optionElement.style.cursor = 'pointer';
        optionElement.style.display = 'flex';
        optionElement.style.alignItems = 'center';
        optionElement.style.justifyContent = 'center';

        // Handle image options
        if (typeof displayContent === 'object' && displayContent.src) {
          const imgElement = document.createElement('img');
          imgElement.src = displayContent.src;
          imgElement.width = displayContent.width || 47;
          imgElement.height = displayContent.height || 26;
          imgElement.alt = displayContent.alt || 'Option Image';

          // Optional: Handle direction attribute if needed
          if (displayContent.direction) {
            imgElement.setAttribute('data-direction', displayContent.direction);
          }

          optionElement.appendChild(imgElement);
        }

        optionElement.setAttribute('data-value', value);

        // Store reference to option element
        this.optionElements.set(value, optionElement);

        // Toggle selection on click
        optionElement.addEventListener('click', () => {
          if (this.selectedOptions.has(value)) {
            this.selectedOptions.delete(value);
            optionElement.style.opacity = '0.2';
          } else {
            this.selectedOptions.add(value);
            optionElement.style.opacity = '1';
          }
        
          const selectedValues = Array.from(this.selectedOptions);
        
          // Set "Forever" if all selected, otherwise space-separated values
          this.setValue(
            selectedValues.length === this.options.length
              ? 'Forever'
              : selectedValues.join(' ')
          );
        
          this.updateWaveOpacity();
        });
        


        // Set initial opacity based on selected state
        optionElement.style.opacity = this.selectedOptions.has(value) ? '1' : '0.2';

        optionsContainer.appendChild(optionElement);
      }
    });

    dropdownDiv.appendChild(optionsContainer);
  }

  public setValue(newValue: string): void {
    if (!this.selectedOptions) {
      this.selectedOptions = new Set();
    }
    if (!this.optionElements) {
      this.optionElements = new Map();
    }
  
    // Clear previous selections
    this.selectedOptions.clear();
  
    // Check for "Forever" case
    if (newValue === 'Forever') {
      // Select all options
      this.options.forEach((option) => {
        if (Array.isArray(option)) {
          const value = option[1];
          this.selectedOptions.add(value);
  
          // Update visual state of option elements
          const optionElement = this.optionElements.get(value);
          if (optionElement) {
            optionElement.style.opacity = '1';
          }
        }
      });
    } else {
      // Split the value and add to selected options
      const values = newValue.split(' ').filter((v) => v.trim() !== '');
      values.forEach((value) => {
        this.selectedOptions.add(value);
  
        // Update visual state of option elements
        const optionElement = this.optionElements.get(value);
        if (optionElement) {
          optionElement.style.opacity = '1';
        }
      });
    }
  
    // Update opacity for non-selected options
    this.optionElements.forEach((element, value) => {
      if (!this.selectedOptions.has(value)) {
        element.style.opacity = '0.2';
      }
    });
  
    // Call parent method
    super.setValue(newValue);
  
    // Update wave opacity when value is set programmatically
    this.updateWaveOpacity();
  }
  

  // Override getValue to return selected options
  public getValue(): string {
    if (!this.selectedOptions) {
      this.selectedOptions = new Set();
    }
  
    // Check if options are defined before accessing them
    if (!this.options || this.options.length === 0) {
      return '';
    }
  
    const selectedValues = Array.from(this.selectedOptions);
  
    // If all options are selected, return "Forever"
    if (selectedValues.length === this.options.length) {
      return 'Forever';
    }
  
    return selectedValues.length > 0 ? selectedValues.join(' ') : '';
  }
  
  

  static createDropdown(
    options: Blockly.MenuOption[],
  ): CustomLightField {
    return new CustomLightField(options);
  }
}


export function registerCustomField() {
  try {
    Blockly.fieldRegistry.register('field_custom_colour', FieldCustomColour);
    Blockly.fieldRegistry.register('field_custom', FieldCustom);
    Blockly.fieldRegistry.register('field_port_selector', CustomDropdownField);
    Blockly.fieldRegistry.register('field_ports', CustomPortSelector);
    Blockly.fieldRegistry.register('field_direction', IconDropdown);
    Blockly.fieldRegistry.register('field_custom_angles', CustomFieldAngle);
    Blockly.fieldRegistry.register('field_light_up', CustomLightField);
  } catch (error) { }
}
