import React, {useEffect, useRef, useState} from 'react';
import {Canvas} from '@react-three/fiber';
import {useDrag} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import GLBModel from './GLBModel';

interface DraggablePieceProps {
  piece: {
    filename: string;
    quantity: number;
  };
  isDisabled: boolean;
  remainingQuantity: number;
  onPartClick: (filename: string) => void;
}

const DraggablePiece: React.FC<DraggablePieceProps> = ({
  piece,
  isDisabled,
  remainingQuantity,
  onPartClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [sourceCanvas, setSourceCanvas] = useState<HTMLCanvasElement | null>(
    null,
  );

  // Reset sourceCanvas when piece changes
  useEffect(() => {
    setSourceCanvas(null);
  }, [piece.filename]); // Add dependency on piece.filename

  // Update source canvas reference when it's available
  useEffect(() => {
    if (containerRef.current) {
      const canvas = containerRef.current.querySelector('canvas');
      if (canvas) {
        setSourceCanvas(canvas);
      }
    }
  }, [piece]);

  const [, drag, preview] = useDrag(
    () => ({
      type: 'PIECE',
      item: () => ({
        type: 'PIECE',
        filename: piece.filename,
        sourceCanvas: sourceCanvas,
      }),
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: !isDisabled && sourceCanvas !== null,
    }),
    [sourceCanvas, piece.filename, isDisabled],
  );

  // Use empty image as drag preview since we'll create a custom one
  useEffect(() => {
    preview(getEmptyImage(), {captureDraggingState: true});
  }, [preview, piece.filename]);

  return (
    <div
      ref={containerRef}
      className="relative"
      style={{
        cursor: isDisabled ? 'not-allowed' : 'grab',
      }}>
      <div
        ref={drag}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 2,
          pointerEvents: isDisabled ? 'none' : 'auto',
          cursor: 'grab',
        }}
      />

      <div
        className={`w-20 h-20 bg-gray-100 rounded-full overflow-hidden flex-shrink-0 ${
          isDisabled ? 'opacity-50' : ''
        }`}
        style={{pointerEvents: 'none'}}
        onClick={() => !isDisabled && onPartClick(piece.filename)}>
        <div className="w-full h-full">
          <Canvas
            camera={{position: [4, 5, 5], fov: 80}}
            gl={{preserveDrawingBuffer: true}}>
            <ambientLight intensity={0.2} />
            <pointLight position={[10, 10, 10]} />
            <GLBModel
              url={piece.filename}
              filename={piece.filename}
              animation={true}
              position={[0, 0, 0]}
            />
          </Canvas>
        </div>
      </div>
      <div
        className="absolute bottom-0 right-0 bg-blue text-white text-xs font-bold rounded-full w-6 h-6 flex items-center justify-center shadow-md transform translate-x-1/4 translate-y-1/4"
        style={{zIndex: 1, pointerEvents: 'none'}}>
        {remainingQuantity}
      </div>
    </div>
  );
};

export default DraggablePiece;
