import React, {useRef, useState, useCallback, useEffect, useMemo} from 'react';
import * as THREE from 'three';
import {ThreeEvent} from '@react-three/fiber';
import {
  TransformControls as DreiTransformControls,
  Html,
} from '@react-three/drei';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {AssemblyValidator} from '../Validation/newValidation';
import {InteractiveGLBModelProps} from './types';
import {getLessonBlocks} from '../Validation/RequirePositons';
import {
  setTransformInteracting,
  getTransformInteracting,
} from './transformState';

type Mode = 'rotate' | 'scale' | 'translate' | undefined;
type MovementType = 'free' | 'grid';

const InteractiveGLBModel: React.FC<InteractiveGLBModelProps> = React.memo(
  ({
    url,
    position,
    rotation,
    gltf,
    isModify,
    onPositionChange,
    onRotationChange,
    isSelected,
    onSelect,
    onDelete,
    onDuplicate,
    callBlockPosition,
    modelFiles,
    orbitControlsRef,
    setSelectedBlock,
    selectedBlock,
  }) => {
    const ref = useRef<THREE.Group>(null);
    const rotationRef = useRef<[number, number, number]>([0, 0, 0]);
    const blockPositionRef = useRef<[number, number, number]>([0, 0, 0]);
    const transformRef = useRef<any>(null);
    const [mode, setMode] = useState<Mode>('translate');
    const [movementType, setMovementType] = useState<MovementType>('grid');
    const [controls, setControls] = useState<OrbitControls | null>(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [pivotCentered, setPivotCentered] = useState(false);

    // // Calculate object offset from bounding box
    // useEffect(() => {
    //   if (gltf) {
    //     const box = new THREE.Box3().setFromObject(gltf);
    //     const center = box.getCenter(new THREE.Vector3());

    //     // Update the object's position to be centered
    //     if (gltf.position) {
    //       gltf.position.set(-center.x, -center.y, -center.z);
    //     }
    //   }
    // }, [gltf]);

    // Memoize config for snapping functionality
    const config = useMemo(() => {
      const urls: string[] = [];
      const snapPointNames: string[] = [];

      modelFiles.forEach(element => {
        const gltfs = element.gltf?.children
          .filter(item => item.name.includes('Anchor'))
          .map(item => item.name);

        urls.push(element.url);
        snapPointNames.push(...(gltfs || []));
      });
      if (gltf && !pivotCentered) {
        const box = new THREE.Box3().setFromObject(gltf);
        const center = box.getCenter(new THREE.Vector3());
        gltf.position.set(-center.x, -center.y, -center.z); // Center it around its pivot
        setPivotCentered(true); // Ensure this operation only happens once
      }
      return {
        modelPaths: urls,
        snapPointNames,
        snappingThreshold: 1,
      };
    }, [gltf, modelFiles, pivotCentered]);

    // Optimized handleOptionClick with memoization
    const handleOptionClick = useCallback(
      (action: 'delete' | 'duplicate' | 'rotateX' | 'rotateY' | 'rotateZ') => {
        switch (action) {
          case 'delete':
            onDelete();
            break;
          case 'duplicate':
            onDuplicate();
            break;
        }
      },
      [onDelete, onDuplicate],
    );

    const resetRotation = useCallback(() => {
      if (ref.current && isSelected) {
        // Reset to [0, 0, 0] rotation
        const resetRotation: [number, number, number] = [0, 0, 0];
        ref.current.rotation.set(...resetRotation);
        rotationRef.current = resetRotation;
        onRotationChange(resetRotation);

        // Show feedback to user
        setErrorMessage('Rotation Reset');
        setShowError(true);
        setTimeout(() => setShowError(false), 1000);
      }
    }, [isSelected, onRotationChange]);

    // Validation function
    const validateBlockPlacement = useCallback(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const lessonId = urlParams.get('lessonId');
      const Blocks = getLessonBlocks(lessonId);
      const currentBlock = modelFiles.find(block => block.id === selectedBlock);

      if (!currentBlock) return;

      const validator = new AssemblyValidator(Blocks, modelFiles);
      const status = validator.getBlockStatus(currentBlock.id);

      if (!status.isCorrect) {
        let message = status.positionOccupied
          ? 'Position already occupied'
          : !status.position && !status.rotation
          ? 'Position and rotation need adjustment'
          : !status.position
          ? 'Position needs adjustment'
          : 'Rotation needs adjustment';

        setErrorMessage(message);
        setShowError(true);
      } else {
        setShowError(false);
      }
    }, [modelFiles, selectedBlock]);

    // Event handlers with cleanup
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (!getTransformInteracting()) {
          setShowError(false);
          if (!mode) {
            setSelectedBlock();
          }
        }
      };

      const handleKeyPress = (event: KeyboardEvent) => {
        if (!isSelected) return;

        switch (event.key.toLowerCase()) {
          case 'r':
            onSelect();
            setMode('rotate');
            break;
          case 'e':
            onSelect();
            setMode('translate');
            break;
          case 'f':
            setMovementType('free');
            // Show feedback to user
            setErrorMessage('Free Movement Mode');
            setShowError(true);
            setTimeout(() => setShowError(false), 1000);
            break;
          case 'g':
            setMovementType('grid');
            // Show feedback to user
            setErrorMessage('Grid Movement Mode');
            setShowError(true);
            setTimeout(() => setShowError(false), 1000);
            break;
          case 'z':
            resetRotation();
            break;
          case 'delete':
          case 'backspace':
            handleOptionClick('delete');
            break;
        }
      };

      // Add event listeners
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyPress);

      // Cleanup function
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [isSelected, mode, onSelect, handleOptionClick, setSelectedBlock]);

    // Transform handlers
    const handleTransform = useCallback(
      (event: any) => {
        if (!ref.current || !event?.target?.object) return;

        const target = event.target;
        const worldPosition = new THREE.Vector3();
        ref.current.getWorldPosition(worldPosition);

        if (movementType === 'free') {
          blockPositionRef.current = [
            worldPosition.x,
            worldPosition.y,
            worldPosition.z,
          ];

          target.setTranslationSnap(0);
          target.setRotationSnap(0);
          target.setScaleSnap(0);
        } else {
          blockPositionRef.current = [
            Math.round(worldPosition.x * 10) / 10,
            Math.round(worldPosition.y * 10) / 10,
            Math.round(worldPosition.z * 10) / 10,
          ];

          target.setTranslationSnap(config.snappingThreshold);
          target.setRotationSnap(THREE.MathUtils.degToRad(10));
          target.setScaleSnap(0.3);
        }

        if (mode === 'rotate') {
          const rotation = target.object.rotation;
          rotationRef.current = [rotation.x, rotation.y, rotation.z];
        }
      },
      [mode, config.snappingThreshold, movementType],
    );

    const handleTransformEnd = useCallback(() => {
      if (!controls) return;

      if (mode === 'translate') {
        onPositionChange(blockPositionRef.current);
        callBlockPosition(blockPositionRef.current);
      } else if (mode === 'rotate') {
        onRotationChange(rotationRef.current);
      }

      controls.enabled = true;
      validateBlockPlacement();
    }, [
      controls,
      mode,
      onPositionChange,
      onRotationChange,
      callBlockPosition,
      validateBlockPlacement,
    ]);

    // Pointer handler
    const onPointerDown = useCallback(
      (e: ThreeEvent<PointerEvent>) => {
        e.stopPropagation();

        // Instead of selecting immediately, wait a tiny bit to see if a transform interaction is about to happen
        setTimeout(() => {
          if (!getTransformInteracting()) {
            // console.log('No transform interaction detected after delay, selecting piece');
            if (isModify) {
              onSelect();
            }
          }
          // else {
          //   console.log('Transform interaction detected after delay, preventing selection');
          // }
        }, 50); // 50ms delay should be imperceptible but enough to catch the transform interaction
      },
      [isModify, onSelect],
    );

    // OrbitControls setup
    useEffect(() => {
      if (typeof orbitControlsRef !== 'function' && orbitControlsRef.current) {
        setControls(orbitControlsRef.current);
      }
    }, [orbitControlsRef]);

    // Render
    return isSelected ? (
      <DreiTransformControls
        ref={transformRef}
        size={0.5}
        mode={mode}
        space="local"
        position={position}
        rotation={rotation}
        onMouseDown={() => {
          // console.log('Transform controls onMouseDown fired');
          setTransformInteracting(true);
          if (controls) controls.enabled = false;
        }}
        onMouseUp={() => {
          // console.log('Transform controls onMouseUp fired');
          handleTransformEnd();
          setTransformInteracting(false);
        }}
        onChange={handleTransform}>
        <group ref={ref} onPointerDown={onPointerDown}>
          <primitive object={gltf} />
          {showError && (
            <Html position={[5, 5, 0]} className="!z-[10]" center>
              <div className="bg-red text-white px-2 py-1 rounded text-[8px]">
                {errorMessage}
              </div>
            </Html>
          )}
        </group>
      </DreiTransformControls>
    ) : (
      <group
        ref={ref}
        position={position}
        rotation={rotation}
        onPointerDown={onPointerDown}>
        <primitive object={gltf} />
      </group>
    );
  },
);

export default InteractiveGLBModel;
