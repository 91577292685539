export let Lesson1 = [
  {
    id: '/models/32525.glb-1736485581480',
    position: [0, 1, 0],
    rotation: [0, 0, 0],
    url: '/models/32525.glb',
  },
  {
    id: '/models/48989.glb-1739958082323',
    position: [0, 3, -8],
    rotation: [
      -1.5707963267948963, -4.440892098500626e-16, -1.5707963267948963,
    ],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1739958083593',
    position: [0, 3, 8],
    rotation: [
      -1.5707963267948972, -3.3306690738754696e-16, -1.5707963267948977,
    ],
    url: '/models/48989.glb',
  },
  {
    id: '/models/41239.glb-1739958237609',
    position: [0, 5, 0],
    rotation: [0, 0, 0],
    url: '/models/41239.glb',
  },
  {
    id: '/models/55615.glb-1739958264764',
    position: [0, 8, 11],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/55615.glb',
  },
  {
    id: '/models/55615.glb-1739958297229',
    position: [0, 8, -11],
    rotation: [1.5707963267948966, -3.3306690738754696e-16, 1.570796326794897],
    url: '/models/55615.glb',
  },
  {
    id: '/models/2780.glb-1739958347480',
    position: [0, 6, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1739958348362',
    position: [0, 6, -2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39793.glb-1739958375146',
    position: [0, 9, 0],
    rotation: [
      0.0013217471415443041, -0.0006406050436298093, 1.5703381679859638,
    ],
    url: '/models/39793.glb',
  },
  {
    id: '/models/32009.glb-1739958429915',
    position: [-4, 9, -14],
    rotation: [1.5707963267948974, 4.710277376051326e-16, 3.141592653589793],
    url: '/models/32009.glb',
  },
  {
    id: '/models/32009.glb-1739958432468',
    position: [-4, 9, 14],
    rotation: [1.5707963267948963, -3.9252311467094373e-16, 3.1415926535897927],
    url: '/models/32009.glb',
  },
  {
    id: '/models/2780.glb-1739958622483',
    position: [0, 13, 13],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1739958644061',
    position: [0, 13, -13],
    rotation: [-1.5707963267948963, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/3749.glb-1739958684263',
    position: [0, 17, -13],
    rotation: [-1.5707963267948963, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1739958685469',
    position: [0, 17, 13],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/32526.glb-1739958779312',
    position: [-2, 17, 12],
    rotation: [1.5707963267948972, 0, 3.141592653589793],
    url: '/models/32526.glb',
  },
  {
    id: '/models/32526.glb-1739958849331',
    position: [-2, 17, -12],
    rotation: [1.5707963267948963, 1.232595164407831e-32, -3.141592653589793],
    url: '/models/32526.glb',
  },
  {
    id: '/models/32270.glb-1739958921888',
    position: [1.5175177423857664, 9, 0],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/32270.glb',
  },
  {
    id: '/models/32498.glb-1739960719046',
    position: [-2, 9, 0],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/32498.glb',
  },
  {
    id: '/models/4519.glb-1739960730031',
    position: [0.5, 9, 0],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/4519.glb',
  },
];
export let Lesson2 = [
  {
    id: '/models/39369.glb-1739951020893',
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    url: '/models/39369.glb',
  },
  {
    id: '/models/2780.glb-1741069154528',
    position: [-10, 1, 18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069172214',
    position: [-10, 1, 10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069185395',
    position: [-10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069193877',
    position: [-10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069231928',
    position: [-10, 1, -2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069240204',
    position: [-10, 1, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069248887',
    position: [10, 1, 18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069260818',
    position: [10, 1, 10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069275650',
    position: [10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069283332',
    position: [10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069289015',
    position: [10, 1, -2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741069295096',
    position: [10, 1, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32523.glb-1741069365432',
    position: [-10, 2, 4],
    rotation: [0, 0, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741069383635',
    position: [10, 2, 4],
    rotation: [0, 0, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32316.glb-1741069394527',
    position: [-10, 2, 14],
    rotation: [0, 0, 0],
    url: '/models/32316.glb',
  },
  {
    id: '/models/32316.glb-1741069402669',
    position: [10, 2, 14],
    rotation: [0, 0, 0],
    url: '/models/32316.glb',
  },
  {
    id: '/models/40490.glb-1741069414380',
    position: [10, 2, -10],
    rotation: [0, 0, 0],
    url: '/models/40490.glb',
  },
  {
    id: '/models/40490.glb-1741069446294',
    position: [-10, 2, -10],
    rotation: [0, 0, 0],
    url: '/models/40490.glb',
  },
  {
    id: '/models/48989.glb-1741069474152',
    position: [-4, 2, 10],
    rotation: [1.5707963267948963, 0, 1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741069518975',
    position: [4, 2, 10],
    rotation: [-1.5707963267948963, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/53444c01.glb-1741069717877',
    position: [0, 15, 9],
    rotation: [-1.5707963267948974, -1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/55422c01.glb-1741069785557',
    position: [0, 15, 10],
    rotation: [-1.5707963267948966, -1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
];
export let Lesson3 = [
  {
    id: '/models/39369.glb-1737464410286',
    position: [0, 0, -10],
    rotation: [0, 0, 0],
    url: '/models/39369.glb',
  },
  {
    id: '/models/55615.glb-1741072731833',
    position: [-4, 3, -7],
    rotation: [1.5707963267948963, 0, 1.570796326794897],
    url: '/models/55615.glb',
  },
  {
    id: '/models/55615.glb-1741072838238',
    position: [4, 3, -7],
    rotation: [3.141592653589793, 1.2246467991473532e-16, -1.5707963267948963],
    url: '/models/55615.glb',
  },
  {
    id: '/models/39793.glb-1741072903278',
    position: [-4, 4, -12],
    rotation: [1.5707963267948966, 0, 1.5707963267948966],
    url: '/models/39793.glb',
  },
  {
    id: '/models/39793.glb-1741072959504',
    position: [4, 4, -12],
    rotation: [1.5707963267948963, -2.220446049250313e-16, 1.5707963267948966],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1741072995072',
    position: [-4, 2, -15],
    rotation: [-1.570796326794897, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741073018112',
    position: [-4, 6, -15],
    rotation: [-1.5707963267948963, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741073044074',
    position: [4, 2, -15],
    rotation: [-1.570796326794897, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741073063322',
    position: [4, 6, -15],
    rotation: [-1.5707963267948963, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39793.glb-1741073104399',
    position: [-4, 4, -18],
    rotation: [-1.5707963267948966, 2.220446049250313e-16, 1.5707963267948963],
    url: '/models/39793.glb',
  },
  {
    id: '/models/39793.glb-1741073130279',
    position: [4, 4, -18],
    rotation: [-1.5707963267948966, 2.220446049250313e-16, 1.5707963267948963],
    url: '/models/39793.glb',
  },
  {
    id: '/models/6558.glb-1741073167280',
    position: [-4, 6, -10],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1741073201649',
    position: [-4, 6, -16],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1741073229580',
    position: [4, 6, -16],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1741073254542',
    position: [4, 6, -10],
    rotation: [0, 0, 1.570796326794897],
    url: '/models/6558.glb',
  },
  {
    id: '/models/32009.glb-1741082142592',
    position: [2, 10, -16],
    rotation: [3.141592653589793, 1.2246467991473535e-16, -1.5707963267948963],
    url: '/models/32009.glb',
  },
  {
    id: '/models/32009.glb-1741082179524',
    position: [-2, 10, -16],
    rotation: [3.141592653589793, 1.224646799147354e-16, -1.5707963267948963],
    url: '/models/32009.glb',
  },
  {
    id: '/models/32009.glb-1741082936635',
    position: [6, 10, -14],
    rotation: [3.141592653589793, 1.2246467991473535e-16, -1.5707963267948963],
    url: '/models/32009.glb',
  },
  {
    id: '/models/32009.glb-1741082987040',
    position: [-6, 10, -14],
    rotation: [
      -3.141592653589793, -1.2246467991473535e-16, -1.5707963267948963,
    ],
    url: '/models/32009.glb',
  },
  {
    id: '/models/2780.glb-1741083029735',
    position: [-7, 6, -8],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741083095675',
    position: [-7, 6, -12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741083114145',
    position: [-7, 6, -14],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741083164413',
    position: [-7, 6, -18],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39793.glb-1741083270181',
    position: [-8, 6, -10],
    rotation: [1.5707963267948966, 2.220446049250313e-16, -1.5707963267948963],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1741083380775',
    position: [7, 6, -8],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741083420494',
    position: [7, 6, -12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741083481590',
    position: [7, 6, -14],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741083513749',
    position: [7, 6, -18],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39793.glb-1741083555433',
    position: [8, 6, -10],
    rotation: [-1.5707963267948966, 3.3306690738754696e-16, 1.570796326794897],
    url: '/models/39793.glb',
  },
  {
    id: '/models/39793.glb-1741083614015',
    position: [8, 6, -16],
    rotation: [-1.5707963267948966, 3.3306690738754696e-16, 1.570796326794897],
    url: '/models/39793.glb',
  },
  {
    id: '/models/55615.glb-1741084349408',
    position: [6, 3, 7],
    rotation: [
      1.5707963267948966, -1.1102230246251565e-16, -1.5707963267948963,
    ],
    url: '/models/55615.glb',
  },
  {
    id: '/models/55615.glb-1741084412552',
    position: [-6, 3, 7],
    rotation: [1.5707963267948966, 2.220446049250313e-16, -1.5707963267948963],
    url: '/models/55615.glb',
  },
  {
    id: '/models/32316.glb-1741084454663',
    position: [6, 2, 10],
    rotation: [-1.5707963267948963, 0, 0],
    url: '/models/32316.glb',
  },
  {
    id: '/models/32316.glb-1741084455710',
    position: [-6, 2, 10],
    rotation: [-1.5707963267948963, 0, 0],
    url: '/models/32316.glb',
  },
  {
    id: '/models/2780.glb-1741084524876',
    position: [11, 0, -22],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741084557311',
    position: [11, 0, -6],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39794.glb-1741084591986',
    position: [18, 0, -14],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/39794.glb',
  },
  {
    id: '/models/2780.glb-1741084634664',
    position: [24, 1, -8],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741084691543',
    position: [24, 1, -16],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/64179.glb-1741084742521',
    position: [24, 6, -12],
    rotation: [-1.5707963267948966, 2.220446049250313e-16, 1.5707963267948963],
    url: '/models/64179.glb',
  },
  {
    id: '/models/2780.glb-1741084791088',
    position: [24, 11, -8],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741084816519',
    position: [24, 11, -16],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/53444c01.glb-1741084835907',
    position: [23, 23, -12],
    rotation: [3.141592653589793, -1.2246467991473535e-16, 1.5707963267948963],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/55422c01.glb-1741084908917',
    position: [24, 23, -12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/44294.glb-1741084956298',
    position: [0, 6, -6],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/44294.glb',
  },
  {
    id: '/models/41239.glb-1741084999050',
    position: [1.098296800727816e-16, 9.989075738403653, 5.276889357012388],
    rotation: [
      -0.34906585039886595, -5.551115123125783e-17, -1.5707963267948963,
    ],
    url: '/models/41239.glb',
  },
  {
    id: '/models/41239.glb-1741085032240',
    position: [2, 9.985788731649782, 5.301823390259729],
    rotation: [
      -0.34906585039886595, -5.551115123125783e-17, -1.5707963267948963,
    ],
    url: '/models/41239.glb',
  },
  {
    id: '/models/41239.glb-1741085263980',
    position: [-2, 10.058077132966226, 5.334313353356831],
    rotation: [
      -0.34906585039886595, -2.7755575615628914e-17, -1.5707963267948963,
    ],
    url: '/models/41239.glb',
  },
  {
    id: '/models/6558.glb-1741085365624',
    position: [-2.0122792321330962e-16, 13.588654298897794, 15.23126819364177],
    rotation: [
      -0.34906585039886595, -5.551115123125783e-17, -1.5707963267948963,
    ],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1741085447524',
    position: [-5.984795992119984e-17, 7.303162585725029, -2.2011274549268136],
    rotation: [
      -0.3561539647086198, -1.1102230246251565e-16, -1.5707963267948963,
    ],
    url: '/models/6558.glb',
  },
  {
    id: '/models/37308c01.glb-1741085554500',
    position: [0, 13.8, 21.6],
    rotation: [-0.3490658503988665, -1.5707963267948966, 0],
    url: '/models/37308c01.glb',
  },
  {
    id: '/models/32524.glb-1741085598481',
    position: [2, 19.173125912233488, 30.246178391446687],
    rotation: [
      -0.34906585039886595, -2.7755575615628914e-17, -1.5707963267948963,
    ],
    url: '/models/32524.glb',
  },
  {
    id: '/models/32524.glb-1741085650036',
    position: [-2, 19.2, 30.3],
    rotation: [
      -0.36361375498072784, -5.551115123125783e-17, -1.5707963267948966,
    ],
    url: '/models/32524.glb',
  },
  {
    id: '/models/6558.glb-1741085736487',
    position: [-0.0001892321539028255, 21.29655302318695, 35.887160531061845],
    rotation: [-1.9464687702848709, -0.000858961245775656, 1.5664279583421679],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1741085827061',
    position: [-5.672528766154095e-10, 19.963676227655405, 32.1132207719297],
    rotation: [-0.35840683742903384, 0, -1.5641222931183556],
    url: '/models/6558.glb',
  },
  {
    id: '/models/32526.glb-1741085889293',
    position: [0, 22.5, 33.3],
    rotation: [2.7925268031909276, -5.551115123125783e-17, -1.5707963267948963],
    url: '/models/32526.glb',
  },
  {
    id: '/models/6558.glb-1741086094388',
    position: [-4, 11.1, 7.7],
    rotation: [
      -0.34906585039886595, 2.7755575615628914e-17, 1.5707963267948963,
    ],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1741086185672',
    position: [4, 11.10407831934598, 7.815607834427657],
    rotation: [
      -0.3490658503988661, 2.7755575615628914e-17, -1.5707963267948963,
    ],
    url: '/models/6558.glb',
  },
  {
    id: '/models/2780.glb-1741086900787',
    position: [-3, 20.6, 34],
    rotation: [-0.349065850398866, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741086948352',
    position: [-3, 17.2, 24.7],
    rotation: [-0.349065850398866, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32278.glb-1741088646747',
    position: [-4, 15.79615796541477, 20.810589456507905],
    rotation: [
      -0.3490658503988659, -1.1102230246251565e-16, -1.5707963267948963,
    ],
    url: '/models/32278.glb',
  },
  {
    id: '/models/2780.glb-1741088833182',
    position: [3, 20.7, 34],
    rotation: [-0.349065850398866, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741088915342',
    position: [3, 17.3, 24.6],
    rotation: [-0.349065850398866, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32278.glb-1741088987458',
    position: [4, 15.816156763503882, 20.86691346585575],
    rotation: [-0.3490658503988659, 1.1102230246251565e-16, 1.5707963267948963],
    url: '/models/32278.glb',
  },
  {
    id: '/models/2780.glb-1741089157532',
    position: [-5, 16.4, 22.7],
    rotation: [-0.3490658503988662, 2.7755575615628914e-17, 1.570796326794897],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741089305022',
    position: [-5, 14.9739628907724, 18.945873756171803],
    rotation: [
      -0.3490658503988659, -1.1102230246251565e-16, -1.5707963267948963,
    ],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741089423912',
    position: [5, 16.4, 22.8],
    rotation: [-0.349065850398866, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741089482848',
    position: [5.3, 15.2, 19],
    rotation: [-0.349065850398866, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/48989.glb-1741089589241',
    position: [-4, 11.9, 22.3],
    rotation: [-0.34906585039886595, 1.5707963267948966, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741090081260',
    position: [4, 12, 22.3],
    rotation: [-0.34906585039886584, 1.5707963118937354, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/32523.glb-1741090266195',
    position: [-6, 13.134038232387478, 19.695763415508555],
    rotation: [1.221730476396031, -1.1102230246251565e-16, 1.570796326794897],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741090268764',
    position: [-5.9823899111343275, 14.495465893083418, 23.413286038865603],
    rotation: [1.22570643515537, -0.015384456994007315, 1.5693676168347408],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741090441265',
    position: [6, 13.247752556274769, 19.691810561185726],
    rotation: [1.2217304763960306, 1.1102230246251565e-16, -1.5707963267948963],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741090447521',
    position: [6, 14.573543227371452, 23.462682187678876],
    rotation: [1.2217304763960308, 3.3306690738754696e-16, -1.5707963267948963],
    url: '/models/32523.glb',
  },
  {
    id: '/models/2780.glb-1741090726368',
    position: [5, 12.5, 11.5],
    rotation: [-0.3490658503988662, 2.7755575615628914e-17, 1.5707963267948966],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741090820712',
    position: [5, 14.6, 17.2],
    rotation: [
      -0.3427959984113704, -2.7755575615628914e-17, 1.5709698262651663,
    ],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741091063245',
    position: [5, 13.169322354256037, 13.371600154729153],
    rotation: [-0.349065850398866, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741091160344',
    position: [5.000000000000002, 17.932260124647325, 26.65498843297132],
    rotation: [-0.349065850398866, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741091362349',
    position: [5.000000000000002, 19.363837058698174, 30.375390877297622],
    rotation: [
      -0.3490658503988661, -2.7755575615628914e-17, 1.5707963267948963,
    ],
    url: '/models/2780.glb',
  },
  {
    id: '/models/48989.glb-1741091497585',
    position: [5.9999999982826395, 11.784279781206807, 9.664765304227313],
    rotation: [-1.9383062298421474, 2.7482233155851077e-10, 1.5707963118962702],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741091503225',
    position: [5.999965697224227, 13.887205504940466, 15.254653147402237],
    rotation: [-1.9278986225578747, -0.0002687374529878375, 1.5771157537310145],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741091511090',
    position: [6, 18.7, 28.5],
    rotation: [-1.9198621771937627, 5.551115123125783e-17, 1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/2780.glb-1741091977379',
    position: [-5, 12.401065433260936, 11.390920331937787],
    rotation: [-0.349065850398866, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741092090203',
    position: [-5, 17.8, 26.5],
    rotation: [
      -0.34906585039886584, 2.7755575615628914e-17, -1.5707963267948963,
    ],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741092091364',
    position: [-5, 19.2, 30.3],
    rotation: [-0.349065850398866, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741092092359',
    position: [-5, 14.5, 16.9],
    rotation: [-0.349065850398866, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741092093217',
    position: [-5, 13.1, 13.2],
    rotation: [-0.349065850398866, -2.7755575615628914e-17, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/48989.glb-1741094495483',
    position: [-5.973680207446734, 13.785617877265445, 15.031913481226017],
    rotation: [1.2280441024434787, 0.035792461635094745, -1.590671059127364],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741094711452',
    position: [-6.085579610443604, 11.752160875795898, 9.466455668895593],
    rotation: [1.2140965308353566, -0.012467045895602607, -1.5722067455183975],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741095133065',
    position: [-5.9712302406664355, 18.573743321637433, 28.382350696227917],
    rotation: [1.2217304763960306, 1.1102230246251565e-16, -1.5707963267948961],
    url: '/models/48989.glb',
  },
  {
    id: '/models/32525.glb-1741095458506',
    position: [-6, 19.7, 25.8],
    rotation: [-0.34906585039886595, 0, 0],
    url: '/models/32525.glb',
  },
  {
    id: '/models/40490.glb-1741095505354',
    position: [-6, 12.5, 5],
    rotation: [-0.349065850398866, 0, 0],
    url: '/models/40490.glb',
  },
  {
    id: '/models/32525.glb-1741095539428',
    position: [6, 19.85132341788711, 25.754113850421522],
    rotation: [-0.3490658503988659, 0, 0],
    url: '/models/32525.glb',
  },
  {
    id: '/models/40490.glb-1741095592213',
    position: [6, 12.5, 5],
    rotation: [-0.349065850398866, 0, 0],
    url: '/models/40490.glb',
  },
  {
    id: '/models/40490.glb-1741095641902',
    position: [6, 9.823481251469442, 10.300644593681664],
    rotation: [-0.34880747664419853, 0, 0],
    url: '/models/40490.glb',
  },
  {
    id: '/models/40490.glb-1741095703447',
    position: [-6, 9.97964487063838, 10.143805684628887],
    rotation: [-0.34906585039886595, 0, 0],
    url: '/models/40490.glb',
  },
];
export let Lesson4 = [
  {
    id: '/models/39794.glb-1739861506418',
    position: [0, 5, 0],
    rotation: [0, 0, 0],
    url: '/models/39794.glb',
  },
  {
    id: '/models/2780.glb-1740468689173',
    position: [-10, 6, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740468704768',
    position: [-6, 6, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740468723055',
    position: [10, 6, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740468749210',
    position: [6, 6, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740468770265',
    position: [10, 6, -6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740468789426',
    position: [6, 6, -6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740468801858',
    position: [-6, 6, -6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740468819584',
    position: [-10, 6, -6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/54696c01.glb-1740468848717',
    position: [7, 9, 2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/54696c01.glb-1740468906543',
    position: [-9, 9, 2],
    rotation: [0, 0, 1.570796326794897],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/4519.glb-1740468956188',
    position: [-14, 9, 8],
    rotation: [0, 0, 1.570796326794897],
    url: '/models/4519.glb',
  },
  {
    id: '/models/3705.glb-1740474228227',
    position: [13, 9, 8],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/3705.glb',
  },
  {
    id: '/models/32278.glb-1740474373850',
    position: [6, 13, -4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/3713.glb-1740474386830',
    position: [12, 9, 8],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/3713.glb',
  },
  {
    id: '/models/30367pb01.glb-1740474468076',
    position: [14, 9, 8],
    rotation: [0, 0, 1.570796326794897],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/30367pb01.glb-1740474532040',
    position: [-14, 9, 8],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/x346.glb-1740476432012',
    position: [16, 11, 8],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/x346.glb',
  },
  {
    id: '/models/x346.glb-1740476485495',
    position: [-16, 11, 8],
    rotation: [-3.141592653589793, 1.2246467991473535e-16, 1.5707963267948963],
    url: '/models/x346.glb',
  },
  {
    id: '/models/2780.glb-1740476563941',
    position: [-6, 12, -6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476579005',
    position: [-6, 12, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476636190',
    position: [6, 12, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476653317',
    position: [6, 12, -6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32278.glb-1740476718045',
    position: [-6, 13, -4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/2780.glb-1740476754830',
    position: [6, 14, 10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476775214',
    position: [-6, 14, 10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476798862',
    position: [-6, 14, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476828535',
    position: [6, 14, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476859239',
    position: [-6, 14, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740476885600',
    position: [6, 14, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/53444c01.glb-1740476943802',
    position: [0, 18, -1],
    rotation: [1.9626155733547223e-17, 1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/55422c01.glb-1740477034739',
    position: [0, 17, -1],
    rotation: [-1.224646799147353e-16, 1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/32524.glb-1740477223645',
    position: [0, 15, -18],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/32524.glb',
  },
  {
    id: '/models/2780.glb-1740477395065',
    position: [-2, 14, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740477508209',
    position: [2, 14, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740477544740',
    position: [2, 8, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39793.glb-1740477571882',
    position: [0, 11, -18],
    rotation: [1.5707963267948972, 1.5707963118937354, 0],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1740477730438',
    position: [-2, 8, -18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39370.glb-1740478059851',
    position: [0, 6, -18],
    rotation: [-1.570796326794897, -1.5707963267948966, 0],
    url: '/models/39370.glb',
  },
  {
    id: '/models/52629.glb-1740478107699',
    position: [0, 3, -18],
    rotation: [0, 0, 0],
    url: '/models/52629.glb',
  },
  {
    id: '/models/49283.glb-1740478389115',
    position: [-4, 20, 12],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1740478499715',
    position: [4, 19.9, 12],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/3749.glb-1740478575199',
    position: [-4, 19, 11],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1740478722440',
    position: [4, 19, 11],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/3749.glb',
  },
];
export let Lesson5 = [
  {
    id: '/models/2780.glb-1740067767940',
    position: [5, 1, -9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740067790618',
    position: [5, 1, 3],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740067843065',
    position: [1, 1, 3],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740067877548',
    position: [1, 1, -9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740067894328',
    position: [-15, 1, -9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740067906197',
    position: [-15, 1, 3],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740067928398',
    position: [-11, 1, 3],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740067941337',
    position: [-11, 1, -9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/54696c01.glb-1740067963081',
    position: [4, 4, -1],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/54696c01.glb-1740068013312',
    position: [-14, 4, -1],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/4519.glb-1740068060405',
    position: [-19, 4, 5],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/4519.glb',
  },
  {
    id: '/models/4519.glb-1740068094070',
    position: [9, 4, 5],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/4519.glb',
  },
  {
    id: '/models/30367pb01.glb-1740068170078',
    position: [9, 4, 5],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/30367pb01.glb-1740068256663',
    position: [-19, 5, 5],
    rotation: [
      -7.850462293418876e-17, -7.850462293418875e-17, 1.5707963267948963,
    ],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/x346.glb-1740068400892',
    position: [11, 6, 5],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/x346.glb',
  },
  {
    id: '/models/x346.glb-1740068447954',
    position: [-21, 6, 5],
    rotation: [3.141592653589793, -1.224646799147353e-16, 1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/2780.glb-1740068589885',
    position: [1, 7, 3],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740068609419',
    position: [1, 7, -9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740068631397',
    position: [-11, 7, 3],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740068650260',
    position: [-11, 7, -9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32278.glb-1740068706953',
    position: [1, 8, -7],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/32278.glb-1740068750392',
    position: [-11, 8, -7],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/2780.glb-1740068904805',
    position: [-11, 9, 7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740068928219',
    position: [1, 9, 7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740068967692',
    position: [-11, 9, -13],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740069148397',
    position: [1, 9, -13],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/53444c01.glb-1740069313405',
    position: [-5, 13, -4],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/55422c01.glb-1740069402204',
    position: [-5, 12, -4],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/3749.glb-1740069478095',
    position: [-9, 14, -16],
    rotation: [-1.570796326794897, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1740069503018',
    position: [-1, 14, -16],
    rotation: [-1.570796326794897, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/49283.glb-1740069553352',
    position: [-1, 15, -17],
    rotation: [1.570796326794897, 0, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1740069599462',
    position: [-9, 15, -17],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/2780.glb-1740069791257',
    position: [-11, 9, -19],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740069832971',
    position: [1, 9, -19],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/6558.glb-1740069878860',
    position: [-11, 8, -21],
    rotation: [-3.141592653589793, 0, 0],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1740069933753',
    position: [-11, 8, -17],
    rotation: [0, 0, -3.141592653589793],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1740070008290',
    position: [1, 8, -21],
    rotation: [-3.141592653589793, 0, 0],
    url: '/models/6558.glb',
  },
  {
    id: '/models/6558.glb-1740070037965',
    position: [1, 8, -17],
    rotation: [0, 0, -3.141592653589793],
    url: '/models/6558.glb',
  },
  {
    id: '/models/32524.glb-1740070105880',
    position: [-5, 6, -21],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/32524.glb',
  },
  {
    id: '/models/32524.glb-1740070130692',
    position: [-5, 6, -17],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/32524.glb',
  },
  {
    id: '/models/32524.glb-1740070132470',
    position: [-11, 10, -23],
    rotation: [0, 0, 0],
    url: '/models/32524.glb',
  },
  {
    id: '/models/2780.glb-1740070174004',
    position: [-5, 5, -21],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740070202951',
    position: [-5, 5, -17],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/37308c01.glb-1740070238346',
    position: [-5, 2, -19],
    rotation: [-3.141592653589793, 0, 0],
    url: '/models/37308c01.glb',
  },
  {
    id: '/models/32524.glb-1740070310584',
    position: [1, 10, -23],
    rotation: [0, 0, 0],
    url: '/models/32524.glb',
  },
  {
    id: '/models/2780.glb-1740070387351',
    position: [-11, 9, -29],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740070420827',
    position: [1, 9, -29],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32524.glb-1740070525533',
    position: [-5, 8, -29],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32524.glb',
  },
  {
    id: '/models/48989.glb-1740070578844',
    position: [-5, 6, -29],
    rotation: [-1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/39370.glb-1740070661486',
    position: [-5, 3, -29],
    rotation: [-1.5707963267948963, -1.5707963267948966, 0],
    url: '/models/39370.glb',
  },
  {
    id: '/models/52629.glb-1740070705654',
    position: [-5, 0, -29],
    rotation: [0, 0, 0],
    url: '/models/52629.glb',
  },
  {
    id: '/models/39794.glb-1740114832426',
    position: [-5, 0, -3],
    rotation: [0, 0, 0],
    url: '/models/39794.glb',
  },
];
export let Lesson6 = [
  {
    id: '/models/39794.glb-1740071250950',
    position: [0, 0, -1],
    rotation: [0, 0, 0],
    url: '/models/39794.glb',
  },
  {
    id: '/models/2780.glb-1740071387453',
    position: [-10, 1, 5],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740071401797',
    position: [10, 1, 5],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740071417273',
    position: [-6, 1, 5],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740071435148',
    position: [6, 1, 5],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740071456784',
    position: [-10, 1, -7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740071479212',
    position: [10, 1, -7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740071492778',
    position: [6, 1, -7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740071505086',
    position: [-6, 1, -7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/54696c01.glb-1740071543872',
    position: [-9, 4, 1],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/54696c01.glb-1740071579023',
    position: [9, 4, 1],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/4519.glb-1740071665727',
    position: [14, 4, 7],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/4519.glb',
  },
  {
    id: '/models/4519.glb-1740071688102',
    position: [-14, 4, 7],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/4519.glb',
  },
  {
    id: '/models/30367pb01.glb-1740071737043',
    position: [-14, 4, 7],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/30367pb01.glb-1740071763519',
    position: [14, 4, 7],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/x346.glb-1740071793220',
    position: [16, 2, 7],
    rotation: [-3.141592653589793, -2.7733391199176196e-32, -1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/x346.glb-1740071962753',
    position: [-16, 6, 7],
    rotation: [3.141592653589793, -1.2246467991473532e-16, 1.5707963267948963],
    url: '/models/x346.glb',
  },
  {
    id: '/models/2780.glb-1740072082575',
    position: [-6, 7, 5],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072098249',
    position: [6, 7, 5],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072118482',
    position: [-6, 7, -7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072144738',
    position: [6, 7, -7],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32278.glb-1740072223611',
    position: [-6, 8, -5],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/32278.glb-1740072290410',
    position: [6, 8, -5],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/2780.glb-1740072316100',
    position: [6, 9, 9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072350538',
    position: [-6, 9, 9],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072367968',
    position: [-6, 9, -11],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072398802',
    position: [-6, 9, -17],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072423538',
    position: [6, 9, -17],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072445653',
    position: [6, 9, -11],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/53444c01.glb-1740072528135',
    position: [0, 13, -2],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/55422c01.glb-1740072586203',
    position: [0, 12, -2],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/3749.glb-1740072621291',
    position: [-4, 14, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1740072644602',
    position: [4, 14, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/49283.glb-1740072674884',
    position: [4, 15, 11],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1740072706550',
    position: [-4, 15, 11],
    rotation: [1.570796326794897, 0, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/32524.glb-1740072745096',
    position: [0, 10, -17],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32524.glb',
  },
  {
    id: '/models/2780.glb-1740072775896',
    position: [-2, 9, -17],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740072795227',
    position: [2, 9, -17],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39793.glb-1740073035287',
    position: [0, 6, -17],
    rotation: [-1.5707963267948963, -1.5707963267948966, 0],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1740073066182',
    position: [-2, 3, -17],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1740073093964',
    position: [2, 3, -17],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39370.glb-1740073122471',
    position: [0, 1, -17.1],
    rotation: [-1.5707963267948972, -1.5707963267948966, 0],
    url: '/models/39370.glb',
  },
  {
    id: '/models/52629.glb-1740073158727',
    position: [0, -2, -17],
    rotation: [0, 0, 0],
    url: '/models/52629.glb',
  },
  {
    id: '/models/55615.glb-1740113662161',
    position: [-6, 5, -18],
    rotation: [3.141592653589793, -1.2246467991473535e-16, 1.5707963267948963],
    url: '/models/55615.glb',
  },
  {
    id: '/models/55615.glb-1740113665979',
    position: [6, 5, -18],
    rotation: [3.141592653589793, -1.2246467991473535e-16, 1.5707963267948963],
    url: '/models/55615.glb',
  },
  {
    id: '/models/37316c01.glb-1740113771870',
    position: [0, 2, -23],
    rotation: [3.1415926535897927, 1.2246467991473527e-16, -3.141592653589793],
    url: '/models/37316c01.glb',
  },
  {
    id: '/models/32524.glb-1741243692808',
    position: [0, 6, -21],
    rotation: [1.5707963267948963, 1.5707963267948966, 0],
    url: '/models/32524.glb',
  },
];
export let Lesson7 = [
  {
    id: '/models/39794.glb-1741328638349',
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    url: '/models/39794.glb',
  },
  {
    id: '/models/2780.glb-1741328764079',
    position: [10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328781529',
    position: [10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328793880',
    position: [6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328810510',
    position: [-6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328826229',
    position: [-10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328835628',
    position: [-10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/54696c01.glb-1741328868835',
    position: [9, 4, -2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/54696c01.glb-1741328933032',
    position: [-9, 4, -2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/2780.glb-1741329067042',
    position: [13, 4, 6],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329090394',
    position: [13, 4, 2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329152209',
    position: [-13, 4, 6],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329181651',
    position: [-13, 4, 2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/4519.glb-1741329215650',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/4519.glb',
  },
  {
    id: '/models/4519.glb-1741329248082',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/4519.glb',
  },
  {
    id: '/models/2780.glb-1741329333794',
    position: [10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329355524',
    position: [6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329370423',
    position: [6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329388538',
    position: [10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329408739',
    position: [-6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329427539',
    position: [-10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329443171',
    position: [-6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329473849',
    position: [-10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32523.glb-1741329539851',
    position: [-6, 4, 14],
    rotation: [1.570796326794897, -1.5700924586837754e-16, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329557665',
    position: [-8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329581294',
    position: [6, 4, 14],
    rotation: [-1.570796326794897, -1.5700924586837757e-16, -3.141592653589793],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329607343',
    position: [8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/53444c01.glb-1741329701348',
    position: [0, 11, -1],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/48989.glb-1741329920622',
    position: [8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741329960090',
    position: [-8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/32278.glb-1741330016349',
    position: [10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/32278.glb-1741330046834',
    position: [-10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/3749.glb-1741330070322',
    position: [10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330105611',
    position: [-10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330146158',
    position: [-1, 6, -16],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330162271',
    position: [-10, 9, 12],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/49283.glb-1741330197761',
    position: [9.042049454863422, 10, -4],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1741330279829',
    position: [-9.061989436582307, 10, -4],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330347134',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330431829',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/x346.glb-1741330458100',
    position: [16, 5.928308821883176, 4.035016003550636],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/x346.glb-1741330541394',
    position: [-16, 5.942780097379632, 3.9493257008633567],
    rotation: [-3.141592653589793, 1.224646799147353e-16, 1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/48989.glb-1741330726467',
    position: [6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741330756547',
    position: [-6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/54675c01.glb-1741330866911',
    position: [0, 3, 8],
    rotation: [0, 0, -3.141592653589793],
    url: '/models/54675c01.glb',
  },
  {
    id: '/models/2780.glb-1741330970528',
    position: [5, 6, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741330998382',
    position: [5, 2, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331033591',
    position: [-5, 6, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331041591',
    position: [-5, 2, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32062.glb-1741331107771',
    position: [0, 6, 14],
    rotation: [0, 0, 0],
    url: '/models/32062.glb',
  },
  {
    id: '/models/39793.glb-1741331297652',
    position: [0, 6, -12],
    rotation: [0, 0, 0],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1741331422436',
    position: [-2, 7, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331441222',
    position: [2.1, 6.95705531837115, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331474403',
    position: [0, 5, -14],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331490613',
    position: [0, 5, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39370.glb-1741331513298',
    position: [0, 3, -12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/39370.glb',
  },
  {
    id: '/models/52629.glb-1741331590827',
    position: [0, 0, -12],
    rotation: [0, 0, 0],
    url: '/models/52629.glb',
  },
  {
    id: '/models/49283.glb-1741331681991',
    position: [-2, 5, -16],
    rotation: [
      -1.5707963267948963, -1.1102230246251565e-16, 1.5707963267948966,
    ],
    url: '/models/49283.glb',
  },
  {
    id: '/models/2780.glb-1741331817802',
    position: [-10, 7, 18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331836579',
    position: [-10, 7, 14],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/49283.glb-1741331878728',
    position: [-9, 10, 12],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/55422c01.glb-1741763202050',
    position: [0, 10, -1],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/37308c01.glb-1741779682938',
    position: [-10, 4, 16],
    rotation: [0, 0, 3.141592653589793],
    url: '/models/37308c01.glb',
  },
  {
    id: '/models/32270.glb-1741842906377',
    position: [0, 7.498026830198629, 14],
    rotation: [0, 0, 0],
    url: '/models/32270.glb',
  },
];
export let Lesson8 = [
  {
    id: '/models/39794.glb-1741328638349',
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    url: '/models/39794.glb',
  },
  {
    id: '/models/2780.glb-1741328764079',
    position: [10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328781529',
    position: [10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328793880',
    position: [6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328810510',
    position: [-6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328826229',
    position: [-10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328835628',
    position: [-10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/54696c01.glb-1741328868835',
    position: [9, 4, -2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/54696c01.glb-1741328933032',
    position: [-9, 4, -2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/2780.glb-1741329067042',
    position: [13, 4, 6],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329090394',
    position: [13, 4, 2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329152209',
    position: [-13, 4, 6],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329181651',
    position: [-13, 4, 2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/4519.glb-1741329215650',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/4519.glb',
  },
  {
    id: '/models/4519.glb-1741329248082',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/4519.glb',
  },
  {
    id: '/models/2780.glb-1741329333794',
    position: [10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329355524',
    position: [6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329370423',
    position: [6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329388538',
    position: [10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329408739',
    position: [-6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329427539',
    position: [-10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329443171',
    position: [-6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329473849',
    position: [-10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32523.glb-1741329539851',
    position: [-6, 4, 14],
    rotation: [1.570796326794897, -1.5700924586837754e-16, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329557665',
    position: [-8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329581294',
    position: [6, 4, 14],
    rotation: [-1.570796326794897, -1.5700924586837757e-16, -3.141592653589793],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329607343',
    position: [8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/53444c01.glb-1741329701348',
    position: [0, 11, -1],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/48989.glb-1741329920622',
    position: [8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741329960090',
    position: [-8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/32278.glb-1741330016349',
    position: [10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/32278.glb-1741330046834',
    position: [-10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/3749.glb-1741330070322',
    position: [10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330105611',
    position: [-10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330146158',
    position: [-1, 6, -16],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330162271',
    position: [-10, 9, 12],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/49283.glb-1741330197761',
    position: [9.042049454863422, 10, -4],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1741330279829',
    position: [-9.061989436582307, 10, -4],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330347134',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330431829',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/x346.glb-1741330458100',
    position: [16, 5.928308821883176, 4.035016003550636],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/x346.glb-1741330541394',
    position: [-16, 5.942780097379632, 3.9493257008633567],
    rotation: [-3.141592653589793, 1.224646799147353e-16, 1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/48989.glb-1741330726467',
    position: [6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741330756547',
    position: [-6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/54675c01.glb-1741330866911',
    position: [0, 3, 8],
    rotation: [0, 0, -3.141592653589793],
    url: '/models/54675c01.glb',
  },
  {
    id: '/models/2780.glb-1741330970528',
    position: [5, 6, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741330998382',
    position: [5, 2, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331033591',
    position: [-5, 6, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331041591',
    position: [-5, 2, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32062.glb-1741331107771',
    position: [0, 6, 14],
    rotation: [0, 0, 0],
    url: '/models/32062.glb',
  },
  {
    id: '/models/39793.glb-1741331297652',
    position: [0, 6, -12],
    rotation: [0, 0, 0],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1741331422436',
    position: [-2, 7, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331441222',
    position: [2.1, 6.95705531837115, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331474403',
    position: [0, 5, -14],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331490613',
    position: [0, 5, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39370.glb-1741331513298',
    position: [0, 3, -12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/39370.glb',
  },
  {
    id: '/models/52629.glb-1741331590827',
    position: [0, 0, -12],
    rotation: [0, 0, 0],
    url: '/models/52629.glb',
  },
  {
    id: '/models/49283.glb-1741331681991',
    position: [-2, 5, -16],
    rotation: [
      -1.5707963267948963, -1.1102230246251565e-16, 1.5707963267948966,
    ],
    url: '/models/49283.glb',
  },
  {
    id: '/models/2780.glb-1741331817802',
    position: [-10, 7, 18],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331836579',
    position: [-10, 7, 14],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/49283.glb-1741331878728',
    position: [-9, 10, 12],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/55422c01.glb-1741763202050',
    position: [0, 10, -1],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/37308c01.glb-1741779682938',
    position: [-10, 4, 16],
    rotation: [0, 0, 3.141592653589793],
    url: '/models/37308c01.glb',
  },
  {
    id: '/models/32270.glb-1741842906377',
    position: [0, 7.498026830198629, 14],
    rotation: [0, 0, 0],
    url: '/models/32270.glb',
  },
];
export let Lesson9 = [
  {
    id: '/models/39794.glb-1741328638349',
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    url: '/models/39794.glb',
  },
  {
    id: '/models/2780.glb-1741328764079',
    position: [10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328781529',
    position: [10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328793880',
    position: [6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328810510',
    position: [-6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328826229',
    position: [-10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328835628',
    position: [-10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/54696c01.glb-1741328868835',
    position: [9, 4, -2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/54696c01.glb-1741328933032',
    position: [-9, 4, -2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/2780.glb-1741329067042',
    position: [13, 4, 6],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329090394',
    position: [13, 4, 2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329152209',
    position: [-13, 4, 6],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329181651',
    position: [-13, 4, 2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/4519.glb-1741329215650',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/4519.glb',
  },
  {
    id: '/models/4519.glb-1741329248082',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/4519.glb',
  },
  {
    id: '/models/2780.glb-1741329333794',
    position: [10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329355524',
    position: [6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329370423',
    position: [6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329388538',
    position: [10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329408739',
    position: [-6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329427539',
    position: [-10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329443171',
    position: [-6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329473849',
    position: [-10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32523.glb-1741329539851',
    position: [-6, 4, 14],
    rotation: [1.570796326794897, -1.5700924586837754e-16, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329557665',
    position: [-8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329581294',
    position: [6, 4, 14],
    rotation: [-1.570796326794897, -1.5700924586837757e-16, -3.141592653589793],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329607343',
    position: [8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/53444c01.glb-1741329701348',
    position: [0, 11, -1],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/48989.glb-1741329920622',
    position: [8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741329960090',
    position: [-8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/32278.glb-1741330016349',
    position: [10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/32278.glb-1741330046834',
    position: [-10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/3749.glb-1741330070322',
    position: [10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330105611',
    position: [-10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330146158',
    position: [-1, 6, -16],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330162271',
    position: [-10, 9, 12],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/49283.glb-1741330197761',
    position: [9.042049454863422, 10, -4],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1741330279829',
    position: [-9.061989436582307, 10, -4],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330347134',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330431829',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/x346.glb-1741330458100',
    position: [16, 5.928308821883176, 4.035016003550636],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/x346.glb-1741330541394',
    position: [-16, 5.942780097379632, 3.9493257008633567],
    rotation: [-3.141592653589793, 1.224646799147353e-16, 1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/48989.glb-1741330726467',
    position: [6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741330756547',
    position: [-6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/54675c01.glb-1741330866911',
    position: [0, 3, 8],
    rotation: [0, 0, -3.141592653589793],
    url: '/models/54675c01.glb',
  },
  {
    id: '/models/2780.glb-1741330970528',
    position: [5, 6, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741330998382',
    position: [5, 2, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331033591',
    position: [-5, 6, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331041591',
    position: [-5, 2, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32062.glb-1741331107771',
    position: [0, 6, 14],
    rotation: [0, 0, 0],
    url: '/models/32062.glb',
  },
  {
    id: '/models/39793.glb-1741331297652',
    position: [0, 6, -12],
    rotation: [0, 0, 0],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1741331422436',
    position: [-2, 7, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331441222',
    position: [2.1, 6.95705531837115, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331474403',
    position: [0, 5, -14],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331490613',
    position: [0, 5, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39370.glb-1741331513298',
    position: [0, 3, -12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/39370.glb',
  },
  {
    id: '/models/52629.glb-1741331590827',
    position: [0, 0, -12],
    rotation: [0, 0, 0],
    url: '/models/52629.glb',
  },
  {
    id: '/models/49283.glb-1741331681991',
    position: [-2, 5, -16],
    rotation: [
      -1.5707963267948963, -1.1102230246251565e-16, 1.5707963267948966,
    ],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1741331878728',
    position: [-9, 10, 12],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/55422c01.glb-1741763202050',
    position: [0, 10, -1],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/32270.glb-1741842906377',
    position: [0, 7.498026830198629, 14],
    rotation: [0, 0, 0],
    url: '/models/32270.glb',
  },
];
export let Lesson10 = [
  {
    id: '/models/39794.glb-1741328638349',
    position: [0, 0, 0],
    rotation: [0, 0, 0],
    url: '/models/39794.glb',
  },
  {
    id: '/models/2780.glb-1741328764079',
    position: [10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328781529',
    position: [10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328793880',
    position: [6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328810510',
    position: [-6, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328826229',
    position: [-10, 1, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741328835628',
    position: [-10, 1, 2],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/54696c01.glb-1741328868835',
    position: [9, 4, -2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/54696c01.glb-1741328933032',
    position: [-9, 4, -2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/54696c01.glb',
  },
  {
    id: '/models/2780.glb-1741329067042',
    position: [13, 4, 6],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329090394',
    position: [13, 4, 2],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329152209',
    position: [-13, 4, 6],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329181651',
    position: [-13, 4, 2],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/4519.glb-1741329215650',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/4519.glb',
  },
  {
    id: '/models/4519.glb-1741329248082',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/4519.glb',
  },
  {
    id: '/models/2780.glb-1741329333794',
    position: [10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329355524',
    position: [6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329370423',
    position: [6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329388538',
    position: [10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329408739',
    position: [-6, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329427539',
    position: [-10, 7, 6],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329443171',
    position: [-6, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741329473849',
    position: [-10, 7, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32523.glb-1741329539851',
    position: [-6, 4, 14],
    rotation: [1.570796326794897, -1.5700924586837754e-16, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329557665',
    position: [-8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329581294',
    position: [6, 4, 14],
    rotation: [-1.570796326794897, -1.5700924586837757e-16, -3.141592653589793],
    url: '/models/32523.glb',
  },
  {
    id: '/models/32523.glb-1741329607343',
    position: [8, 4, 10],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/32523.glb',
  },
  {
    id: '/models/53444c01.glb-1741329701348',
    position: [0, 11, -1],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/53444c01.glb',
  },
  {
    id: '/models/48989.glb-1741329920622',
    position: [8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741329960090',
    position: [-8, 6, 10],
    rotation: [1.5707963267948963, 0, 0],
    url: '/models/48989.glb',
  },
  {
    id: '/models/32278.glb-1741330016349',
    position: [10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/32278.glb-1741330046834',
    position: [-10, 8, 4],
    rotation: [0, 0, 0],
    url: '/models/32278.glb',
  },
  {
    id: '/models/3749.glb-1741330070322',
    position: [10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330105611',
    position: [-10, 9, -4],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330146158',
    position: [-1, 6, -16],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/3749.glb',
  },
  {
    id: '/models/3749.glb-1741330162271',
    position: [-10, 9, 12],
    rotation: [0, 0, 0],
    url: '/models/3749.glb',
  },
  {
    id: '/models/49283.glb-1741330197761',
    position: [9.042049454863422, 10, -4],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1741330279829',
    position: [-9.061989436582307, 10, -4],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330347134',
    position: [-14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/30367pb01.glb-1741330431829',
    position: [14, 4, 4],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/30367pb01.glb',
  },
  {
    id: '/models/x346.glb-1741330458100',
    position: [16, 5.928308821883176, 4.035016003550636],
    rotation: [0, 0, -1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/x346.glb-1741330541394',
    position: [-16, 5.942780097379632, 3.9493257008633567],
    rotation: [-3.141592653589793, 1.224646799147353e-16, 1.570796326794897],
    url: '/models/x346.glb',
  },
  {
    id: '/models/48989.glb-1741330726467',
    position: [6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/48989.glb-1741330756547',
    position: [-6, 4, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/48989.glb',
  },
  {
    id: '/models/54675c01.glb-1741330866911',
    position: [0, 3, 8],
    rotation: [0, 0, -3.141592653589793],
    url: '/models/54675c01.glb',
  },
  {
    id: '/models/2780.glb-1741330970528',
    position: [5, 6, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741330998382',
    position: [5, 2, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331033591',
    position: [-5, 6, 12],
    rotation: [0, 0, -1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331041591',
    position: [-5, 2, 12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/2780.glb',
  },
  {
    id: '/models/32062.glb-1741331107771',
    position: [0, 6, 14],
    rotation: [0, 0, 0],
    url: '/models/32062.glb',
  },
  {
    id: '/models/39793.glb-1741331297652',
    position: [0, 6, -12],
    rotation: [0, 0, 0],
    url: '/models/39793.glb',
  },
  {
    id: '/models/2780.glb-1741331422436',
    position: [-2, 7, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331441222',
    position: [2.1, 6.95705531837115, -12],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331474403',
    position: [0, 5, -14],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/2780.glb-1741331490613',
    position: [0, 5, -10],
    rotation: [0, 0, 0],
    url: '/models/2780.glb',
  },
  {
    id: '/models/39370.glb-1741331513298',
    position: [0, 3, -12],
    rotation: [0, 0, 1.5707963267948963],
    url: '/models/39370.glb',
  },
  {
    id: '/models/52629.glb-1741331590827',
    position: [0, 0, -12],
    rotation: [0, 0, 0],
    url: '/models/52629.glb',
  },
  {
    id: '/models/49283.glb-1741331681991',
    position: [-2, 5, -16],
    rotation: [
      -1.5707963267948963, -1.1102230246251565e-16, 1.5707963267948966,
    ],
    url: '/models/49283.glb',
  },
  {
    id: '/models/49283.glb-1741331878728',
    position: [-9, 10, 12],
    rotation: [0, -1.5707963267948966, 0],
    url: '/models/49283.glb',
  },
  {
    id: '/models/55422c01.glb-1741763202050',
    position: [0, 10, -1],
    rotation: [0, 1.5707963267948966, 0],
    url: '/models/55422c01.glb',
  },
  {
    id: '/models/32270.glb-1741842906377',
    position: [0, 7.498026830198629, 14],
    rotation: [0, 0, 0],
    url: '/models/32270.glb',
  },
];

// Create a mapping between lesson IDs and their corresponding arrays
export const getLessonBlocks = lessonId => {
  const lessonMap = {
    1: Lesson1,
    2: Lesson2,
    3: Lesson3,
    4: Lesson4,
    5: Lesson5,
    6: Lesson6,
    7: Lesson7,
    8: Lesson8,
    9: Lesson9,
    10: Lesson10,
    // Add more lessons as needed
  };

  return lessonMap[lessonId] || Lesson1; // Default to Lesson1 if invalid lessonId
};
