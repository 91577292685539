import { blocklyTheme } from './ThemeConfig';

export const workspaceConfiguration = {
  // Disable trashcan if not needed
  trashcan: false,
  collapse: false,
  theme: blocklyTheme,
  // toolbox?: Blockly.utils.toolbox.ToolboxDefinition;
  // readOnly?: boolean;
  // maxInstances?: { [type: string]: number; };
  // maxTrashcanContents?: number;
  // comments?: boolean;
  // disable?: boolean;
  // sounds?: boolean;
  // rtl?: boolean;
  // horizontalLayout?: boolean;
  // toolboxPosition?: string;
  // css?: boolean;
  // oneBasedIndex?: boolean;
  // media?: string;
  // Grid settings
  // move?: {
  //   scrollbars?: boolean;
  //   drag?: boolean;
  //   wheel?: boolean;
  // };
  grid: {
    spacing: 20,
    length: 3,
    colour: '#ccc',
    snap: true,
  },

  // Zoom controls
  zoom: {
    controls: true,
    wheel: true,
    startScale: 0.7,
    maxScale: 3,
    minScale: 0.3,
    scaleSpeed: 1.01, // Add this (default is 1.2, try lower values like 1.1 or 1.05)
    // pinch?: boolean;
  },
  // Renderer choice
  renderer: 'zelos', //zelos, geras, 'thrasos
};
