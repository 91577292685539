import React, {useRef, useEffect, useState} from 'react';
import * as THREE from 'three';
import {useLoader, useFrame} from '@react-three/fiber';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

import {GLBModelProps} from './types';

const GLBModel: React.FC<GLBModelProps> = ({
  url,
  filename,
  animation,
  position,
}) => {
  const ref = useRef<THREE.Group | null>(null);
  const [mixer, setMixer] = useState<THREE.AnimationMixer | null>(null);
  const [error, setError] = useState<string | null>(null);
  // const [objectOffset, setObjectOffset] = useState<THREE.Vector3>(
  //   new THREE.Vector3(),
  // );

  const gltf = useLoader(GLTFLoader, url, undefined, event => {
    if (event.type === 'progress') {
      // Handle progress event if needed
    } else {
      console.error(`Error loading model ${url}:`, event);
      setError(`Failed to load model: ${event.type}`);
    }
  });

  useEffect(() => {
    if (gltf) {
      if (!gltf.scene || !gltf.scene.children.length) {
        setError('Loaded model has no visible content');
        return;
      }

      // Set up the animation mixer
      const mixer = new THREE.AnimationMixer(gltf.scene);
      if (gltf.animations && gltf.animations.length > 0) {
        gltf.animations.forEach(clip => {
          mixer.clipAction(clip).play();
        });
      }
      setMixer(mixer);

      // Calculate the geometric center
      const box = new THREE.Box3().setFromObject(gltf.scene);
      const center = box.getCenter(new THREE.Vector3());
      // setObjectOffset(center);

      // Center the model
      gltf.scene.position.set(-center.x, -center.y, -center.z);
    }
  }, [gltf]);

  useFrame((state, delta) => {
    if (mixer) {
      mixer.update(delta);
    }
    if (ref.current && animation) {
      ref.current.rotation.y += 0.01;
    }
  });

  if (error) {
    console.warn(`Model ${filename} not loaded: ${error}`);
    return (
      <mesh position={position}>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color="red" />
      </mesh>
    );
  }

  if (!gltf || !gltf.scene) {
    return null;
  }

  return (
    <group ref={ref} key={url}>
      <primitive object={gltf.scene} position={position} />
    </group>
  );
};

export default GLBModel;
