import * as Blockly from 'blockly';
import toast from 'react-hot-toast';
import { LESSON_CONFIGURATIONS } from '../Config/lessonsConfig';
import { NeqLessonConfig, FieldValidationResult } from '../Typs';

export function validateWorkspace(workspace: Blockly.Workspace, lessonId: string, actionFrom?: string): boolean {
  // Serialize the workspace
  const state = Blockly.serialization.workspaces.save(workspace);

  // Get the lesson configuration
  const config = LESSON_CONFIGURATIONS[lessonId];
  if (!config) {
    toast.error('Invalid lesson configuration');
    return false;
  }


  // Validate the sequence and fields
  const validationResult = validateSequence(workspace, config, state);
  if (validationResult.isValid) {
    // Workspace is valid
    return true;
  } else {
    // Workspace is invalid
    if (!actionFrom) {
      toast.error(validationResult.message, { duration: 2000, id: 'error' });
    }
    return false;
  }
}

// Validate the sequence of blocks against the lesson configuration.
function validateSequence(workspace: Blockly.Workspace, config: NeqLessonConfig, serializedState: any): FieldValidationResult {
  // Get all top-level blocks in the workspace
  const topBlocks = workspace.getTopBlocks(false);
  let currentBlock: Blockly.Block | null = topBlocks[0]; // Get the first block
  let configIndex = 0;

  // Check if there are blocks in the workspace
  if (!serializedState?.blocks?.blocks?.length) {
    return {
      isValid: false,
      message: 'No blocks found in workspace'
    };
  }

  // Check if there are multiple start blocks
  const startBlockType = config[0];
  const startBlocks = serializedState.blocks.blocks.filter(
    (block: any) => block.type === startBlockType?.type
  );

  if (startBlocks.length > 1) {
    return {
      isValid: false,
      message: `Only one sequence starting with "${startBlockType.name}" is allowed.`
    };
  }

  // Find the root block (should be the start block)
  const rootBlock = serializedState.blocks.blocks.find(
    (block: any) => block.type === startBlockType.type
  );

  // If start block not found
  if (!rootBlock) {
    return {
      isValid: false,
      message: `Missing required start block "${startBlockType.name}".`
    };
  }

  // Clear all warnings before validation
  // clearAllWarnings(workspace);

  // Get the actual block references
  const startBlock = workspace.getBlockById(rootBlock.id);
  if (!startBlock) {
    return {
      isValid: false,
      message: "Start block not found in workspace"
    };
  }

  // Step 1: Validate the sequence of blocks
  while (currentBlock && configIndex < config.length) {
    const expectedBlockConfig: any = config[configIndex];

    // Check if the block type matches the expected type
    if (currentBlock.type !== expectedBlockConfig.type) {
      return {
        isValid: false,
        message: `Block order is incorrect.`,
        // message: `Expected block type "${expectedBlockConfig.type}" at position ${configIndex + 1}, but found "${currentBlock.type}".`,
      };
    }

    // Move to the next block in the sequence
    currentBlock = currentBlock.getNextBlock();
    configIndex++;
  }

  // Step 2: Check if all blocks in the configuration were validated
  if (configIndex < config.length) {
    return {
      isValid: false,
      message: `Block order is incorrect.`,
      // message: `Missing required block "${config[configIndex].name}" at position ${configIndex + 1}.`,
    };
  }

  // Step 3: Now that the sequence is correct, validate the field values of all blocks
  currentBlock = topBlocks[0];
  configIndex = 0;

  while (currentBlock && configIndex < config.length) {
    const expectedBlockConfig: any = config[configIndex];

    // Validate block fields
    const fieldValidation = validateFields(currentBlock, expectedBlockConfig.fields);
    if (!fieldValidation.isValid) {
      return fieldValidation;
    }

    // Validate nested blocks (if any)
    if (expectedBlockConfig.contains) {
      // Case 1: Blocks inside container blocks (like forever)
      const nestedValidation = validateNestedBlocks(currentBlock, expectedBlockConfig.contains);
      if (!nestedValidation.isValid) return nestedValidation;
    }
    else if (expectedBlockConfig.then || expectedBlockConfig.else) {
      // Case 2: Handle if-then/if-then-else blocks (condition + branches)

      // First validate condition if it exists
      if (expectedBlockConfig.condition) {
        const conditionValidation = validateConditionBlock(currentBlock, expectedBlockConfig);
        if (!conditionValidation.isValid) return conditionValidation;
      }

      // Then validate branches
      const branchValidation = validateBranches(currentBlock, expectedBlockConfig);
      if (!branchValidation.isValid) return branchValidation;
    }
    else if (expectedBlockConfig.condition) {
      // Case 3: Standalone condition blocks (like wait-until)
      const conditionValidation = validateConditionBlock(currentBlock, expectedBlockConfig);
      if (!conditionValidation.isValid) return conditionValidation;
    }

    // Move to the next block in the sequence
    currentBlock = currentBlock.getNextBlock();
    configIndex++;
  }

  // Step 4: Only after all sequence and field validations have passed, check for extra blocks
  // Helper function to recursively add blocks and their children to the valid set
  const validSequenceBlockIds = new Set<string>();

  function addBlockAndChildren(block: Blockly.Block | null) {
    if (!block) return;

    // Add this block
    validSequenceBlockIds.add(block.id);

    // Add all blocks connected to inputs (like condition blocks, etc.)
    for (let i = 0; i < block.inputList.length; i++) {
      const input = block.inputList[i];
      if (input.connection && input.connection.targetBlock()) {
        const childBlock = input.connection.targetBlock();
        // Recursively add this child and its children
        addBlockAndChildren(childBlock);
      }
    }

    // Add the next block in sequence
    addBlockAndChildren(block.getNextBlock());
  }

  // Start from the top block and collect all valid blocks
  addBlockAndChildren(topBlocks[0]);

  // Check for any blocks in the workspace that aren't in our valid set
  const allBlocks = workspace.getAllBlocks(false);
  const extraBlocks = allBlocks.filter(block => !validSequenceBlockIds.has(block.id));

  if (extraBlocks.length > 0) {
    return {
      isValid: false,
      message: `Extra block "${extraBlocks[0].type}" found in workspace that is not part of the expected sequence.`,
    };
  }

  // If all checks pass, return success
  return { isValid: true, message: 'Sequence and field values are valid' };
}



function validateConditionBlock(block: Blockly.Block, config: any): FieldValidationResult {
  const conditionInputName = block.type === 'controls_wait_until' ? 'CONDITION' : 'IF0';
  const conditionBlock = block.getInputTargetBlock(conditionInputName); // or 'IF0' depending on your block
  if (!conditionBlock) {
    return { isValid: false, 
      message: `A required condition block is missing. Please add it to proceed.` 
      // message: `Missing condition block in ${block.type}` 
    };
  }

  // Validate condition block type
  if (conditionBlock.type !== config.condition.type) {
    return {
      isValid: false,
      message: `Incorrect condition block used`
      // message: `Expected condition type "${config.condition.type}" but found "${conditionBlock.type}"`
    };
  }

  // Validate condition fields
  return validateFields(conditionBlock, config.condition.fields || {});
}

function validateBranches(block: Blockly.Block, config: any): FieldValidationResult {
  // Validate THEN branch
  if (config.then) {
    let thenBlock = block.getInputTargetBlock('DO0');
    let thenIndex = 0;

    while (thenBlock && thenIndex < config.then.length) {
      const thenValidation = validateBlockRecursive(thenBlock, config.then[thenIndex]);
      if (!thenValidation.isValid) return thenValidation;

      thenBlock = thenBlock.getNextBlock();
      thenIndex++;
    }

    if (thenIndex < config.then.length) {
      return { isValid: false, 
        message: `Block order is incorrect.`
        // message: `Missing THEN block: ${config.then[thenIndex].name}`
       };
    }
    
    // Check for extra blocks in the THEN branch
    if (thenBlock) {
      return { 
        isValid: false, 
        message: `Extra block "${thenBlock.type}" found after the expected sequence.` 
      };
    }
  }

  // Validate ELSE branch (if exists)
  if (config.else && (block.type === 'if_then_else' || config.type === 'if_then_else')) {
    let elseBlock = block.getInputTargetBlock('ELSE');
    let elseIndex = 0;

    while (elseBlock && elseIndex < config.else.length) {
      const elseConfig = config.else[elseIndex];
      
      // First validate the block type
      if (elseBlock.type !== elseConfig.type) {
        return {
          isValid: false,
          message: `Block order is incorrect.`
          // message: `Expected block type "${elseConfig.type}" in ELSE branch at position ${elseIndex + 1}, but found "${elseBlock.type}".`
        };
      }

      // For if_then_else blocks in ELSE branch, validate recursively
      if (elseBlock.type === 'if_then_else') {
        const nestedValidation = validateBlockRecursive(elseBlock, elseConfig);
        if (!nestedValidation.isValid) return nestedValidation;
      } 
      // For other blocks, validate fields
      else {
        const fieldValidation = validateFields(elseBlock, elseConfig.fields || {});
        if (!fieldValidation.isValid) return fieldValidation;
      }

      elseBlock = elseBlock.getNextBlock();
      elseIndex++;
    }

    if (elseIndex < config.else.length) {
      return { isValid: false, message: `Block order is incorrect.` };
    }
    
    // Check for extra blocks in the ELSE branch
    if (elseBlock) {
      return { 
        isValid: false, 
        message: `Extra block "${elseBlock.type}" found after the expected sequence.` 
      };
    }
  }

  return { isValid: true, message: '' };
}

function validateBlockRecursive(block: Blockly.Block, config: any): FieldValidationResult {
  // Validate current block
  if (block.type !== config.type) {
    return { isValid: false, message: `Block order is incorrect.` };
  }

  // Validate fields first
  const fieldValidation = validateFields(block, config.fields || {});
  if (!fieldValidation.isValid) return fieldValidation;

  // Handle different block types
  if (config.condition) {
    // Validate condition block
    const conditionValidation = validateConditionBlock(block, config);
    if (!conditionValidation.isValid) return conditionValidation;

    // Validate logical operator inputs if specified
    if (config.condition.inputs) {
      const conditionBlock = block.getInputTargetBlock(block.type === 'controls_wait_until' ? 'CONDITION' : 'IF0');
      if (conditionBlock) {
        const inputsValidation = validateLogicalOperatorInputs(conditionBlock, config.condition);
        if (!inputsValidation.isValid) return inputsValidation;
      }
    }
  }

  // Validate branches (then/else)
  if (config.then || config.else) {
    return validateBranches(block, config);
  }

  // Validate contained blocks (for loops, etc.)
  if (config.contains) {
    return validateNestedBlocks(block, config.contains);
  }

  return { isValid: true, message: '' };
}

// Validate the field values of a block.
function validateFields(block: Blockly.Block, expectedFields: Record<string, any>): FieldValidationResult {
  for (const [fieldName, expectedValue] of Object.entries(expectedFields || {})) {
    const actualValue = block?.getFieldValue(fieldName);

    // Skip if field doesn't exist
    if (actualValue === undefined || actualValue === null) {
      return {
        isValid: false,
        message: `Missing required field "${fieldName}" in block "${block.type}".`,
      };
    }

    let isFieldValid = false;
    // Convert actualValue to number if it is numeric
    const numericActualValue = isNaN(Number(actualValue)) ? actualValue : Number(actualValue);

    // Handle different types of expected values
    if (Array.isArray(expectedValue)) {
      if (Array.isArray(expectedValue[0])) {
        // This is a bitmap array
        isFieldValid = areArraysEqual(actualValue, expectedValue);
      } else {
        // Convert expected values to numbers for comparison
        const normalizedExpectedValues = expectedValue.map(value =>
          isNaN(Number(value)) ? value : Number(value)
        );

        isFieldValid = normalizedExpectedValues.includes(numericActualValue);
      }
    } else {
      // Direct comparison
      if (typeof actualValue === 'string') {
        isFieldValid = expectedValue.toString().toLowerCase().trim() === actualValue.toString().toLowerCase().trim();
      } else {
        isFieldValid = Number(expectedValue) === numericActualValue;
      }
    }

    if (!isFieldValid) {
      let expectedDisplay = Array.isArray(expectedValue) ?
        (Array.isArray(expectedValue[0]) ? "bitmap" : expectedValue.join(" or ")) :
        expectedValue;

      return {
        isValid: false,
        message: `Expected and Actual values are not matching`
      };
    }
  }

  return { isValid: true, message: 'Field values are valid' };
}
// Helper function for comparing 2D arrays (bitmaps)
function areArraysEqual(arr1: any[][], arr2: any[][]): boolean {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].length !== arr2[i].length) return false;
    for (let j = 0; j < arr1[i].length; j++) {
      if (arr1[i][j] !== arr2[i][j]) return false;
    }
  }

  return true;
}

// Validate nested blocks inside a container block.
function validateNestedBlocks(
  containerBlock: Blockly.Block,
  nestedConfig: any[]
): FieldValidationResult {
  // Check if the nestedConfig contains a sequence
  if (nestedConfig.length > 0 && nestedConfig[0].sequence) {
    const sequenceConfig = nestedConfig[0].sequence;

    let currentBlock = containerBlock.getInputTargetBlock('DO0'); // Adjust input name as needed
    let configIndex = 0;

    // Validate each block in the sequence
    while (currentBlock && configIndex < sequenceConfig.length) {
      const expectedConfig = sequenceConfig[configIndex];

      // Validate the block type
      if (currentBlock.type !== expectedConfig.type) {
        return {
          isValid: false,
          message: `Block order is incorrect.`,
          // message: `Expected block type "${expectedConfig.type}" at position ${configIndex + 1}, but found "${currentBlock.type}".`,
        };
      }

      // Validate block fields (if any)
      if (expectedConfig.fields) {
        const fieldValidation = validateFields(currentBlock, expectedConfig.fields);
        if (!fieldValidation.isValid) {
          return fieldValidation;
        }
      }

      if (currentBlock.type === "controls_wait_until" && expectedConfig.condition) {
        const conditionBlock = currentBlock.getInputTargetBlock('CONDITION');
        if (!conditionBlock) {
          return {
            isValid: false,
            message: `A required condition block is missing`,
            // message: `Missing condition block in wait_until at position ${configIndex + 1}`,
          };
        }

        // Validate condition block type
        if (conditionBlock.type !== expectedConfig.condition.type) {
          return {
            isValid: false,
            message: `Incorrect condition block used.`,
          };
        }

        // Validate condition fields
        const fieldValidation = validateFields(conditionBlock, expectedConfig.condition.fields || {});
        if (!fieldValidation.isValid) {
          return fieldValidation;
        }

        // Validate inputs to logical operators if provided
        if (expectedConfig.condition.inputs) {
          const inputsValidation = validateLogicalOperatorInputs(conditionBlock, expectedConfig.condition);
          if (!inputsValidation.isValid) {
            return inputsValidation;
          }
        }
      }

      // Validate nested blocks inside if_then or if_then_else blocks
      if (expectedConfig.type === 'if_then' || expectedConfig.type === 'if_then_else') {
        // Validate the condition block
        const conditionBlock = currentBlock.getInputTargetBlock('IF0');
        if (!conditionBlock) {
          return {
            isValid: false,
            message: `A required condition block is missing. `,
            // message: `Missing condition block in if-then/if-then-else statement at position ${configIndex + 1}.`,
          };
        }
        // Validate the condition block type and fields
        if (conditionBlock.type !== expectedConfig.condition.type) {
          return {
            isValid: false,
            message: `Incorrect condition block used`,
            // message: `Expected condition block type "${expectedConfig.condition.type}" in if-then/if-then-else statement at position ${configIndex + 1}, but found "${conditionBlock.type}".`,
          };
        }

        if (expectedConfig.condition.fields) {
          for (const [fieldName, expectedValue] of Object.entries(expectedConfig.condition.fields)) {
            const actualValue = conditionBlock.getFieldValue(fieldName);
            if (actualValue === null) {
              return {
                isValid: false,
                message: `Field "${fieldName}" is not set in block "${conditionBlock.type}".`,
              };
            }
            if (actualValue !== expectedValue) {
              return {
                isValid: false,
                message: `Expected and Actual values are not matching`,
                // message: `Invalid value for field "${fieldName}" in block "${conditionBlock.type}". Expected: ${expectedValue}, Actual: ${actualValue}`,
              };
            }
          }
        }
        // Validate the "then" blocks
        if (expectedConfig.then) {
          let thenBlock = currentBlock.getInputTargetBlock('DO0');
          let thenConfigIndex = 0;

          while (thenBlock && thenConfigIndex < expectedConfig.then.length) {
            const expectedThenConfig = expectedConfig.then[thenConfigIndex];

            // Validate the block type
            if (thenBlock.type !== expectedThenConfig.type) {
              return {
                isValid: false,
                message: `Block order is incorrect.`,
                // message: `Expected block type "${expectedThenConfig.type}" in "then" sequence at position ${thenConfigIndex + 1}, but found "${thenBlock.type}".`,
              };
            }


            // Validate block fields (if any)
            const blockValidation = validateBlockRecursive(thenBlock, expectedThenConfig);
            if (!blockValidation.isValid) {
              return blockValidation;
            }

            // Move to the next block in the "then" sequence
            thenBlock = thenBlock.getNextBlock();
            thenConfigIndex++;
          }

          // Check if all blocks in the "then" sequence were validated
          if (thenConfigIndex < expectedConfig.then.length) {
            return {
              isValid: false,
              message: `Block order is incorrect.`,
              // message: `Missing required block "${expectedConfig.then[thenConfigIndex].name}" in "then" sequence at position ${thenConfigIndex + 1}.`,
            };
          }
          if (thenBlock) {
            return {
              isValid: false,
              message: `Extra block "${thenBlock.type}" found after the expected sequence.`,
            };
          }
        }

        if (expectedConfig.condition.inputs) {
          const inputsValidation = validateLogicalOperatorInputs(conditionBlock, expectedConfig.condition);
          if (!inputsValidation.isValid) {
            return inputsValidation;
          }
        }

        // Validate the "else" blocks (only for if_then_else)
        if (expectedConfig.type === 'if_then_else' && expectedConfig.else) {
          let elseBlock = currentBlock.getInputTargetBlock('ELSE');
          let elseConfigIndex = 0;

          while (elseBlock && elseConfigIndex < expectedConfig.else.length) {
            const expectedElseConfig = expectedConfig.else[elseConfigIndex];

            // Validate the block type
            if (elseBlock.type !== expectedElseConfig.type) {
              return {
                isValid: false,
                message: `Block order is incorrect.`,
              };
            }

              // Validate block fields (if any)
              const blockValidation = validateBlockRecursive(elseBlock, expectedElseConfig);
              if (!blockValidation.isValid) {
                return blockValidation;
              }

            // Move to the next block in the "else" sequence
            elseBlock = elseBlock.getNextBlock();
            elseConfigIndex++;
          }

          // Check if all blocks in the "else" sequence were validated
          if (elseConfigIndex < expectedConfig.else.length) {
            return {
              isValid: false,
              message: `Block order is incorrect.`,
            };
          }

          if (elseBlock) {
            return {
              isValid: false,
              message: `Extra block "${elseBlock.type}" found after the expected sequence.`,
            };
          }
        }
      }

      // Move to the next block in the sequence
      currentBlock = currentBlock.getNextBlock();
      configIndex++;
    }

    // Check if all blocks in the sequence were validated
    if (configIndex < sequenceConfig.length) {
      return {
        isValid: false,
        message: `Block order is incorrect.`,
      };
    }

    // Check for extra nested blocks
    if (currentBlock) {
      return {
        isValid: false,
        message: `Extra block "${currentBlock.type}" found after the expected sequence.`,
      };
    }
  }

  return { isValid: true, message: 'Nested blocks are valid' };
}

// Add this new function to validate logical operator inputs
function validateLogicalOperatorInputs(
  operatorBlock: Blockly.Block,
  operatorConfig: any
): FieldValidationResult {
  // For each input defined in the config
  for (const [inputName, inputConfig] of Object.entries(operatorConfig.inputs || {}) as [
    string,
    Record<string, any>
  ][]) {
    // Get the actual block connected to this input
    const inputBlock = operatorBlock.getInputTargetBlock(inputName);

    // If config expects a block but none is connected
    if (!inputBlock && inputConfig.block) {
      return {
        isValid: false,
        message: `A required input is missing in one of the blocks`,
      };
    }   

    // If a block is connected, validate it against the config
    if (inputBlock && inputConfig.block) {
      // Check type
      if (inputBlock.type !== inputConfig.block.type) {
        return {
          isValid: false,
          message: `An incorrect block is used in one of the inputs`,
        };
      }

      // Validate fields
      const fieldValidation = validateFields(inputBlock, inputConfig.block.fields || {});
      if (!fieldValidation.isValid) {
        return fieldValidation;
      }

      // Recursively validate nested inputs if any
      if (inputConfig.block.inputs) {
        const nestedValidation = validateLogicalOperatorInputs(inputBlock, inputConfig.block);
        if (!nestedValidation.isValid) {
          return nestedValidation;
        }
      }
    }

    // Handle shadow blocks
    if (inputBlock && inputConfig.shadow) {
      // Specific validation for shadow blocks (e.g., math_number)
      if (inputBlock.type === "math_number" && inputConfig.shadow.fields?.NUM !== undefined) {
        const actualValue = Number(inputBlock.getFieldValue("NUM"));
        const expectedValue = Number(inputConfig.shadow.fields.NUM);

        if (actualValue !== expectedValue) {
          return {
            isValid: false,
             message: `Expected and Actual values are not matching`
          };
        }
      }
    }
  }

  return { isValid: true, message: "" };
}
