import React, { useState, useEffect, useRef } from 'react';
import { FaAlignLeft, FaTimes } from 'react-icons/fa';
import { FaRegCirclePlay, FaQuestion } from 'react-icons/fa6';
import * as Blockly from 'blockly';
import WordblocksPreview from '../model/WordblocksPreview';
import HotKeys from './HotKeys';
import Editor2D from './Editor2D';
import { validateWorkspace } from './Blockly/Validation/BlockValidation';
import toast from 'react-hot-toast';

interface ValidationResult {
  message: string;
  valid: boolean;
}

interface Actions {
  isValid: () => ValidationResult; // Now accepts undefined as a possible return
}

const DropdownExample: React.FC<Actions> = ({ isValid }) => {
  const [isOpenPlay, setIsOpenPlay] = useState(false);
  const [isOpenHotkey, setIsOpenHotkey] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [initialState, setInitialState] = useState<any>();

  const modalRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null); // Ref for the button

  const urlParams = new URLSearchParams(window.location.search);
  const lessonParam = urlParams.get('lessonId');
  const isLesson1 = lessonParam && Number(lessonParam) === 1;
  const isLesson8 = lessonParam && Number(lessonParam) === 8;
  const isLesson9 = lessonParam && Number(lessonParam) === 9;

  const toggleDropdown = () => setIsOpen(prev => !prev);

  const toggleOpenPlay = () => {
    let ThreeBlockValid = isValid();

    // If the lesson is 1, 8, or 9, check ThreeBlockValid.valid
    if (isLesson1 || isLesson8 || isLesson9) {
      if (ThreeBlockValid.valid) {
        setIsOpenPlay(!isOpenPlay);
      }
      return; // If invalid, do nothing
    }

    // If `initialState?.is_valid` is false, validate the workspace
    const workspace = Blockly.getMainWorkspace();
    let checkValidation: any = validateWorkspace(
      workspace,
      `lesson-${lessonParam}`,
    );

    if (!checkValidation) {
      return;
    }
    // If validation passes but `initialState?.is_valid` is false, show error
    if (!initialState?.is_valid) {
      toast.error('Please Save your changes', {
        duration: 1000,
        id: 'playbutton',
      });
      return;
    }

    // If validation passes but `ThreeBlockValid.valid` is false, do nothing
    if (!ThreeBlockValid.valid) {
      return;
    }

    // If everything is valid, toggle isOpenPlay
    setIsOpenPlay(!isOpenPlay);
    setIsOpen(false)
  };


  // Close the modal if the click is outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click is outside the modal and button
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpenHotkey(false); // Close the modal
      }
    };

    if (isOpenHotkey) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenHotkey]);

  useEffect(() => {
    const fetchWorkspaceState = async () => {
      if (process.env.REACT_APP_SKIP_API === 'true') {
        // In offline mode, we'll use a default state
        setInitialState({ is_valid: true });
        return;
      }

      try {
        const lessonNumber = lessonParam ? parseInt(lessonParam) : 1;
        const userID = urlParams.get('userId') || '1';

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/block-action-model/${lessonNumber}/${userID}`,
        );
        if (!response.ok) {
          throw new Error('Failed to fetch workspace state');
        }
        const data = await response.json();
        setInitialState(data?.data);
      } catch (error) {
        console.error('Error fetching workspace state:', error);
        // Use default state as fallback
        setInitialState({ is_valid: true });
      }
    };

    fetchWorkspaceState();
  }, [lessonParam]);

  return (
    <div className="absolute top-4 right-2 sm:right-5 left-2 sm:left-5 lg:left-auto z-10">
      <div className="flex space-x-6 justify-end mb-8">
        <button
          ref={buttonRef} // Attach button reference
          className={`btn btn-circle ${isOpenHotkey ? 'active_btn_hotkey' : ''
            }`}
          onClick={e => {
            e.preventDefault();
            setIsOpenHotkey(prev => !prev);
          }}>
          <FaQuestion style={{ width: '100%' }} />
        </button>
        {!isLesson1 && (
          <button
            className={`btn btn-circle ${isOpenPlay ? 'active_btn' : ''}`}
            onClick={toggleOpenPlay}>
            <FaRegCirclePlay style={{ width: '100%' }} />
          </button>
        )}
        <button
          disabled={isLesson1 || isLesson1 === null || isLesson8 || isLesson8 === null || isLesson9 || isLesson9 === null}
          className={`btn btn-circle ${isOpen ? 'active_btn' : ''}`}
          onClick={toggleDropdown}>
          <FaAlignLeft />
        </button>

        <button
          onClick={() => {
            setIsOpen(false);
            setIsOpenHotkey(false);
          }}>
          <FaTimes />
        </button>
      </div>

      {isOpen && (
        <div className="model_box">
          <Editor2D
            initialState={initialState}
            setInitialState={setInitialState}
          />
        </div>
      )}
      {isOpenPlay && <WordblocksPreview onClose={() => setIsOpenPlay(false)} />}

      {isOpenHotkey && (
        <div ref={modalRef}>
          <HotKeys onClose={() => setIsOpenHotkey(false)} />
        </div>
      )}
    </div>
  );
};

export default DropdownExample;
