import ReactDOM from 'react-dom/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './assets/css/index.css';

import Editor3D from './components/Editor3D';
import {Toaster} from 'react-hot-toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <DndProvider backend={HTML5Backend}>
    {/* <React.StrictMode> */}
    <Toaster />
    <Editor3D />
    {/* </React.StrictMode>, */}
  </DndProvider>,
);
