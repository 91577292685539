// Toolbox configuration
export const toolboxConfiguration2 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_program_start',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Light',
      id: '1',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#6E3ECA] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'turn_on_for_seconds',
        },
        {
          kind: 'block',
          type: 'write_text',
        },
      ],
    },
  ],
};

export const toolboxConfiguration3 = {
  // kind: "flyoutToolbox",
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_color_is',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Light',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#9b6af6] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'turn_on_for_seconds',
        },
        {
          kind: 'block',
          type: 'write_text',
        },
        {
          kind: 'block',
          type: 'set_power_button_light',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sound',
      id: '3',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#904DBE] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'play_sound_until_done',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'stop_action',
        },
      ],
    },
  ],
};

export const toolboxConfiguration4 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_program_start',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Motors',
      id: '1',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#0090F5] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_speed_to',
        },
        {
          kind: 'block',
          type: 'flippermotor',
        },
      ],
    },
  ],
};

export const toolboxConfiguration5 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_program_start',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        {
          kind: 'block',
          type: 'start_moving',
        },
        {
          kind: 'block',
          type: 'stop_moving',
        },
        {
          kind: 'block',
          type: 'move_right_for_rotation',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'forever',
        },
        {
          kind: 'block',
          type: 'if_then',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sensors',
      id: '7',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#34ccf1] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'is_color',
        },
      ],
    },
  ],
};

export const toolboxConfiguration6 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_program_start',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'start_moving',
        },
        {
          kind: 'block',
          type: 'move_forved_for_rotation',
        },
        {
          kind: 'block',
          type: 'move_right_for_rotation',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Light',
      id: '3',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#9b6af6] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'field_light_up',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'forever',
        },
        {
          kind: 'block',
          type: 'if_then',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sensors',
      id: '7',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#34ccf1] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'is_sensors_2',
        },
      ],
    },
  ],
};

export const toolboxConfiguration7 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_program_start',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'start_moving_right',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'forever',
        },
        {
          kind: 'block',
          type: 'if_then_else',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sensors',
      id: '7',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#34ccf1] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'is_color',
        },
      ],
    },
  ],
};

export const toolboxConfiguration8 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_program_start',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'start_moving_right',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'forever',
        },
        {
          kind: 'block',
          type: 'if_then_else',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sensors',
      id: '7',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#34ccf1] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'is_color',
        },
      ],
    },
  ],
};

export const toolboxConfiguration10 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'when_program_start',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'moment_with_time_direction',
        },
        // {
        //   kind: 'block',
        //   type: 'start_moving',
        // }
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'wait_seconds',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Motors',
      id: '1',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#0090F5] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'flippermotor',
        },
      ],
    },
  ],
};

export const toolboxConfiguration11 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '1',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'event_button_action',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'if_then_else',
        },
        {
          kind: 'block',
          type: 'controls_wait_until',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sensors',
      id: '3',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#34ccf1] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'is_color',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        {
          kind: 'block',
          type: 'start_moving',
        },
        {
          kind: 'block',
          type: 'stop_moving',
        },
        {
          kind: 'block',
          type: 'moment_with_time_direction',
        },
        {
          kind: 'block',
          type: 'move_right_for_rotation',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Light',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#9b6af6] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'turn_on_for_seconds',
        },
        {
          kind: 'block',
          type: 'write_text',
        },
      ],
    },
  ],
};

export const toolboxConfiguration12 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '1',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'event_button_action',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'forever',
        },
        {
          kind: 'block',
          type: 'if_then_else',
        },
        {
          kind: 'block',
          type: 'controls_wait_until',
        },
        {
          kind: 'block',
          type: 'controls_repeat_ext',
        },
        {
          kind: 'block',
          type: 'stop_action',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sensors',
      id: '3',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#34ccf1] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'sensors_press_option',
        },
        {
          kind: 'block',
          type: 'timer_block',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        {
          kind: 'block',
          type: 'start_moving',
        },
        {
          kind: 'block',
          type: 'stop_moving',
        },
        {
          kind: 'block',
          type: 'moment_with_time_direction',
        },
        {
          kind: 'block',
          type: 'move_right_for_rotation',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Light',
      id: '5',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#9b6af6] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'turn_on_for_seconds',
        },
        {
          kind: 'block',
          type: 'turn_on',
        },
        {
          kind: 'block',
          type: 'write_text',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Motors',
      id: '6',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#0090F5] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'motor_reset_timer',
        },
      ],
    },  
    {
      kind: 'category',
      name: 'Operators',
      id: '7',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#00b94d] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'logic_or',
        },
        {
          kind: 'block',
          type: 'logic_greater',
          inputs: {
            FirstValue: {  // Shadow input for the first field
              shadow: {
                type: "math_number",
                name:"firstVariable"
              }
            },
            secondValue: {  // Shadow input for the second field
              shadow: {
                type: "math_number",
                name:"secondVariable"
              }
            }
          }
        },
      ],
    },  
  ],
};

export const toolboxConfiguration13 = {
  contents: [
    {
      kind: 'category',
      name: 'Events',
      id: '1',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#f5c402] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'event_button_action',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Control',
      id: '2',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ffb515] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'forever',
        },
        {
          kind: 'block',
          type: 'if_then_else',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Sensors',
      id: '3',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#34ccf1] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'sensor_distance_in',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Movement',
      id: '4',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#ff4ccd] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind: 'block',
          type: 'set_movement_motors_to',
        },
        {
          kind: 'block',
          type: 'set_movement_speed_to',
        },
        { 
          kind: 'block',
          type: 'stop_moving',
        },
        {
          kind: 'block',
          type: 'moment_with_time_direction',
        },
        {
          kind: 'block',
          type: 'move_right_for_rotation',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Operators',
      id: '7',
      cssconfig: {
        container: 'bg-white p-1',
        row: 'w-20 py-2 cursor-pointer text-center text-base',
        //  rowcontentcontainer: "bg-white",
        icon: 'w-5 h-5 bg-[#00b94d] rounded-full',
        label: 'font-sm block',
        // contents: "",
        selected: '!bg-gray-200 rounded-xl border text-black',
        openicon: 'w-5 h-5 bg-black',
        // closedicon: "",
      },
      contents: [
        {
          kind:"block",
          type:"random_number_picker",
          inputs: {
            FROM: {  // Shadow input for the first field
              shadow: {
                type: "math_number",
                name:"FROM"
              }
            },
            TO: {  // Shadow input for the second field
              shadow: {
                type: "math_number",
                name:"TO"
              }
            }
          }
        },
        {
          kind: 'block',
          type: 'logic_less',
          inputs: {
            FirstValue: {  // Shadow input for the first field
              shadow: {
                type: "math_number",
                name:"firstVariable"
              }
            },
            secondValue: {  // Shadow input for the second field
              shadow: {
                type: "math_number",
                name:"secondVariable"
              }
            }
          }
        },
        {
          kind: 'block',
          type: 'logic_greater',
          inputs: {
            FirstValue: {  // Shadow input for the first field
              shadow: {
                type: "math_number",
                name:"firstVariable"
              }
            },
            secondValue: {  // Shadow input for the second field
              shadow: {
                type: "math_number",
                name:"secondVariable"
              }
            }
          }
        },
      ],
    },  
  ],
};

export const toolboxConfigurations = {
  'lesson-2': toolboxConfiguration2,
  'lesson-3': toolboxConfiguration3,
  'lesson-4': toolboxConfiguration4,
  'lesson-5': toolboxConfiguration5,
  'lesson-6': toolboxConfiguration6,
  'lesson-7': toolboxConfiguration7,
  'lesson-8': toolboxConfiguration8,
  'lesson-10': toolboxConfiguration10,
  'lesson-11': toolboxConfiguration11,
  'lesson-12': toolboxConfiguration12,
  'lesson-13': toolboxConfiguration13,
  // Add more configurations as needed
};
