import React, {useCallback, useMemo} from 'react';
import {useThree} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';
import Grid from './Grid';
import {SceneContentProps} from './types';
import InteractiveGLBModel from './InteractiveGLBModel';

// Separate SceneContent into its own file for better organization
export const SceneContent: React.FC<SceneContentProps> = ({
  canvasBlocks,
  selectedBlock,
  setSelectedBlock,
  updateBlockPosition,
  callUpdateBlockPosition,
  orbitControlsRef,
  isOrbitEnabled,
  setIsOrbitEnabled,
  onDeleteBlock,
  onDuplicateBlock,
  onRotateBlock,
}) => {
  const {camera} = useThree();

  const handleBlockSelect = useCallback(
    (blockId: string) => {
      setSelectedBlock(blockId);
    },
    [setSelectedBlock],
  );

  const handleBackgroundClick = useCallback(() => {
    setSelectedBlock(null);
  }, [setSelectedBlock]);

  const renderBlocks = useMemo(
    () =>
      canvasBlocks.map((model, index) => (
        <InteractiveGLBModel
          key={model.id}
          {...model}
          onPositionChange={newPosition =>
            updateBlockPosition(index, newPosition)
          }
          onRotationChange={newPosition => onRotateBlock(index, newPosition)}
          isSelected={selectedBlock === model.id}
          onSelect={() => handleBlockSelect(model.id)}
          setIsOrbitEnabled={setIsOrbitEnabled}
          onDelete={() => onDeleteBlock(model.id)}
          onDuplicate={() => onDuplicateBlock(index)}
          callBlockPosition={newPosition =>
            callUpdateBlockPosition(index, newPosition)
          }
          modelFiles={canvasBlocks}
          orbitControlsRef={orbitControlsRef}
          setSelectedBlock={handleBackgroundClick}
          selectedBlock={selectedBlock}
        />
      )),
    [
      canvasBlocks,
      selectedBlock,
      updateBlockPosition,
      onRotateBlock,
      handleBlockSelect,
      handleBackgroundClick,
      callUpdateBlockPosition,
      onDeleteBlock,
      onDuplicateBlock,
      orbitControlsRef,
      setIsOrbitEnabled,
    ],
  );

  return (
    <>
      <OrbitControls
        ref={orbitControlsRef}
        camera={camera}
        enabled={isOrbitEnabled}
      />
      <Grid />
      <ambientLight intensity={0.2} />
      <directionalLight position={[10, 10, 10]} intensity={1} />
      {renderBlocks}
    </>
  );
};
