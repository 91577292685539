import { NeqLessonConfig } from "../Typs";

export const LESSON_CONFIGURATIONS: Record<string, NeqLessonConfig> = {
    "lesson-2": [
        { type: 'when_program_start', name: 'When Program Start' },
        {
            type: 'turn_on_for_seconds',
            name: 'Turn On For Seconds',
            fields: {
                TURN_ON_BITMAP: [
                    [1, 1, 0, 1, 1],
                    [1, 1, 0, 1, 1],
                    [0, 0, 0, 0, 0],
                    [1, 0, 0, 0, 1],
                    [0, 1, 1, 1, 0]
                ],
                TURN_ON_SECOND: 2
            }
        },
        { type: 'write_text', name: 'Write Text', fields: { WRITE_TEXT: 'Hello' } }
    ],

    "lesson-3": [
        {
            type: 'when_color_is',
            name: 'When Color Is',
            fields: {
                WHEN_COLOR_DROPDOWN: "f",
                WHEN_COLOR_PICKER: '#0091f5'
            }
        },
        { type: 'set_power_button_light', name: 'Set Power Button Light', fields: { SET_POWER_COLOR_PICKER: '#0091f5' } },
        { type: 'write_text', name: 'Write Text', fields: { WRITE_TEXT: 'Blue' } },
        { type: 'play_sound_until_done', name: 'Play Sound', fields: { SOUND_NAME: 'Blue' } },
        {
            type: 'turn_on_for_seconds',
            name: 'Turn On For Seconds',
            fields: {
                TURN_ON_BITMAP: [
                    [1, 1, 1, 0, 0],
                    [1, 0, 0, 1, 0],
                    [1, 1, 1, 0, 0],
                    [1, 0, 0, 1, 0],
                    [1, 1, 1, 0, 0]
                ],
                TURN_ON_SECOND: [.25, 0.25,]
            }
        },
        { type: 'stop_action', name: 'Stop Action', fields: { STOP: 'All' } }
    ],

    "lesson-4": [
        { type: 'when_program_start', name: 'When Program Start' },
        { type: 'set_speed_to', name: 'Set Speed', fields: { PORT: 'C+D', ENTER_NUMBER: 25 } },
        { type: 'flippermotor', name: 'Select Port', fields: { PORT: 'C+D', CHOICE: 'Left', INCREMENT_NUMBER: 2, INCREMENTS: 'rotations' } },
        { type: 'flippermotor', name: 'Select Port', fields: { PORT: 'C', CHOICE: 'Left', INCREMENT_NUMBER: 375, INCREMENTS: 'degrees' } },
        { type: 'flippermotor', name: 'Select Port', fields: { PORT: 'C+D', CHOICE: 'Left', INCREMENT_NUMBER: 2, INCREMENTS: 'rotations' } },
        { type: 'flippermotor', name: 'Select Port', fields: { PORT: 'D', CHOICE: 'Left', INCREMENT_NUMBER: 2.1, INCREMENTS: 'rotations' } },
        { type: 'flippermotor', name: 'Select Port', fields: { PORT: 'C+D', CHOICE: 'Left', INCREMENT_NUMBER: 2, INCREMENTS: 'rotations' } },
        { type: 'flippermotor', name: 'Select Port', fields: { PORT: 'C', CHOICE: 'Left', INCREMENT_NUMBER: 1.05, INCREMENTS: 'rotations' } },
        { type: 'flippermotor', name: 'Select Port', fields: { PORT: 'C+D', CHOICE: 'Right', INCREMENT_NUMBER: 3, INCREMENTS: 'rotations' } }
    ],

    "lesson-5": [
        { type: 'when_program_start', name: 'When Program Start' },
        { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 45 } },
        { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'D+C' } },
        { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } },
        {
            type: 'forever',
            name: 'Forever',
            contains: [
                {
                    type: 'blockContainer', // Add a type to fulfill BlockPattern requirements
                    name: 'Block Container', // Optional name for clarity
                    sequence: [
                        {
                            type: 'if_then',
                            name: 'If Then',
                            condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#00A745' } },
                            then: [
                                { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 75 } },
                                { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } }
                            ]
                        },
                        {
                            type: 'if_then',
                            name: 'If Then',
                            condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#FFE360' } },
                            then: [
                                { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 25 } },
                                { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } }
                            ]
                        },
                        {
                            type: 'if_then',
                            name: 'If Then',
                            condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#ff0000' } },
                            then: [
                                { type: 'stop_moving', name: 'Stop Moving' }
                            ]
                        },
                        {
                            type: 'if_then',
                            name: 'If Then',
                            condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#78E8FF' } },
                            then: [
                                { type: 'move_right_for_rotation', name: 'Move right for rotation', fields: { ANGLE: "60", ENTER_NUMBER: 0.8, TIME_UNITS: "Rotations" } },
                                { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } }
                            ]
                        }
                    ]
                }
            ]
        }
    ],

    "lesson-6": [
        { type: 'when_program_start', name: 'When Program Start' },
        { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 50 } },
        { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'C+D' } },
        { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } },
        { type: 'light_up', name: 'Light Up', fields: { PORT: 'F', CHOICE: 'Forever' } },
        {
            type: 'forever',
            name: 'Forever',
            contains: [
                {
                    type: 'blockContainer', // Add a type to fulfill BlockPattern requirements
                    name: 'Block Container', // Optional name for clarity
                    sequence: [
                        {
                            type: 'if_then',
                            name: 'If Then',
                            condition: { type: 'is_sensors_2', name: "Is Sensors", fields: { PORT: "F", DISTANCE_COMPARISON: 'closer than', ENTER_NUMBER: 9, UNITS: "cm" } },
                            then: [
                                { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 25 } },
                                { type: 'move_forved_for_rotation', name: 'Move Forved For Rotation', fields: { CHOICE: 'Reverse', ENTER_NUMBER: 1, TIME_UNITS: 'rotations' } },
                                { type: 'move_right_for_rotation', name: 'Move Right For Rotation', fields: { ANGLE: "30", ENTER_NUMBER: 1, TIME_UNITS: 'rotations' } }
                            ]
                        },
                        {
                            type: 'if_then',
                            name: 'If Then',
                            condition: { type: 'is_sensors_2', name: "Is Sensors", fields: { PORT: "F", DISTANCE_COMPARISON: 'farther than', ENTER_NUMBER: 9, UNITS: "cm" } },
                            then: [
                                { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 50 } },
                                { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } },
                            ]
                        }
                    ]
                }
            ]
        }
    ],

    "lesson-7": [
        { type: 'when_program_start', name: 'When Program Start' },
        { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'D+C' } },
        { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 30 } },
        {
            type: 'forever',
            name: 'Forever',
            contains: [
                {
                    type: 'blockContainer', // Add a type to fulfill BlockPattern requirements
                    name: 'Block Container', // Optional name for clarity
                    sequence: [
                        {
                            type: 'if_then_else',
                            name: 'If Then Else',
                            condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#000000' } },
                            then: [
                                { type: 'start_moving_right', name: 'Start Moving Right', fields: { ANGLE: "50" } }
                            ],
                            else: [
                                { type: 'start_moving_right', name: 'Start Moving Right', fields: { ANGLE: "-50" } }
                            ]
                        },
                    ]
                }
            ]
        }
    ],

    "lesson-10": [
        { type: 'when_program_start', name: 'When Program Start' },
        { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'C+D' } },
        { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 50 } },
        { type: 'moment_with_time_direction', name: 'Moment With Time Direction', fields: { CHOICE: 'Forward', INCREMENT_NUMBER: 4, INCREMENTS: "inches" } },
        { type: 'wait_seconds', name: 'Wait Seconds', fields: { WAIT_TIME: 1 } },
        { type: 'moment_with_time_direction', name: 'Moment With Time Direction', fields: { CHOICE: 'Reverse', INCREMENT_NUMBER: 1, INCREMENTS: "Rotations" } },
        { type: 'flippermotor', name: 'Flipper Motor', fields: { PORT: 'C', CHOICE: 'Left', INCREMENT_NUMBER: 0.9, INCREMENTS: "Rotations" } },
        { type: 'flippermotor', name: 'Flipper Motor', fields: { PORT: 'D', CHOICE: 'Right', INCREMENT_NUMBER: 0.9, INCREMENTS: "Rotations" } },
        { type: 'moment_with_time_direction', name: 'Moment With Time Direction', fields: { CHOICE: 'Forward', INCREMENT_NUMBER: 7.5, INCREMENTS: "cm" } },
    ],
    "lesson-11": [
        { type: 'event_button_action', name: 'When Buttons Pressed', fields: { BUTTON_SIDE: "RIGHT", ACTION: 'PRESSED' } },
        {
            type: 'if_then_else',
            name: 'If Then Else',
            condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#000000' } },
            then: [
                { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'C+D' } },
                { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 5 } },
                { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } },
                { type: 'controls_wait_until', name: 'controls wait until', condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#ff0000' } }, },
                { type: 'stop_moving', name: 'stop moving', },
            ],
            else: [
                {
                    type: 'turn_on_for_seconds',
                    name: 'Turn On For Seconds',
                    fields: {
                        TURN_ON_BITMAP: [
                            [1, 1, 0, 1, 1],
                            [1, 1, 0, 1, 1],
                            [0, 0, 0, 0, 0],
                            [0, 1, 1, 1, 0],
                            [1, 0, 0, 0, 1]
                        ],
                        TURN_ON_SECOND: 2
                    }
                },
                { type: 'write_text', name: 'Write Text', fields: { WRITE_TEXT: 'try again' } }
            ]
        },
        { type: 'moment_with_time_direction', name: 'Moment With Time Direction', fields: { CHOICE: 'Reverse', INCREMENT_NUMBER: 1, INCREMENTS: "inches" } },
        { type: 'move_right_for_rotation', name: 'Move right for rotation', fields: { ANGLE: "90", ENTER_NUMBER: 205, TIME_UNITS: "degrees" } },
        { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'C+D' } },
        { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 5 } },
        { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } },
        { type: 'controls_wait_until', name: 'Start Moving Right', condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#FFE360' } }, },
        { type: 'stop_moving', name: 'stop moving', },
        { type: 'moment_with_time_direction', name: 'Moment With Time Direction', fields: { CHOICE: 'Reverse', INCREMENT_NUMBER: 1, INCREMENTS: "inches" } },
        { type: 'move_right_for_rotation', name: 'Move right for rotation', fields: { ANGLE: "-90", ENTER_NUMBER: 200, TIME_UNITS: "degrees" } },
        { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'C+D' } },
        { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 5 } },
        { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Forward' } },
        { type: 'controls_wait_until', name: 'Start Moving Right', condition: { type: 'is_color', name: "Is Color", fields: { PORT: "B", WHEN_COLOR_PICKER: '#000000' } }, },
        { type: 'stop_moving', name: 'stop moving', },
        { type: 'write_text', name: 'Write Text', fields: { WRITE_TEXT: '1st PLACE' } }
    ],

    "lesson-12": [
        { type: 'event_button_action', name: 'When Buttons Pressed', fields: { BUTTON_SIDE: "LEFT", ACTION: 'PRESSED' } },
        {
            type: 'turn_on',
            name: 'Turn On',
            fields: {
                TURN_ON: [
                    [1, 1, 0, 1, 1],
                    [1, 1, 0, 1, 1],
                    [0, 0, 0, 0, 0],
                    [0, 1, 1, 1, 0],
                    [1, 0, 0, 0, 1]
                ],
            }
        },
        { type: 'write_text', name: 'Write Text', fields: { WRITE_TEXT: "I'M STUCK!" } },
        {
            type: 'forever',
            name: 'Forever',
            contains: [
                {
                    type: 'blockContainer', // Add a type to fulfill BlockPattern requirements
                    name: 'Block Container', // Optional name for clarity
                    sequence: [
                        { type: 'motor_reset_timer', name: 'Motor Reset Timer' },
                        { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'C+D' } },
                        { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 10 } },
                        { type: 'start_moving', name: 'Start Moving', fields: { CHOICE: 'Reverse' } },
                        {
                            type: "controls_wait_until",
                            name: "Wait Until",
                            condition: {
                                type: "logic_or",
                                name: "Logic OR",
                                inputs: {
                                    "A": {  // First input to OR operator
                                        block: {
                                            type: "logic_greater",
                                            name: "Logic Greater Than",
                                            inputs: {
                                                FirstValue: {  // First value to compare
                                                    block: {
                                                        type: "timer_block",
                                                        name: "Variable",
                                                    },
                                                    shadow: {
                                                        type: "math_number",
                                                        fields: {
                                                            NUM: ""  // Example value
                                                        }
                                                    }
                                                },
                                                secondValue: {  // Second value to compare
                                                    shadow: {
                                                        type: "math_number",
                                                        fields: {
                                                            NUM: 5  // Example value
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    "B": {  // Second input to OR operator
                                        block: {
                                            type: "sensors_press_option",  // Your second condition block
                                            name: "Sensors Press Option",
                                            fields: {
                                                "PORT": "A",
                                                "PRESS_OPTIONS": "pressed"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        { type: 'stop_moving', name: 'stop moving', },
                        {
                            type: 'if_then_else',
                            name: 'If Then Else',
                            condition: {
                                type: 'logic_greater', name: "Logic Greater", inputs: {
                                    FirstValue: {
                                        block: {
                                            type: "timer_block",
                                            name: "Variable",
                                        },
                                        shadow: {
                                            type: "math_number",
                                            fields: {
                                                NUM: 0  // First value
                                            }
                                        }
                                    },
                                    secondValue: {
                                        shadow: {
                                            type: "math_number",
                                            fields: {
                                                NUM: 5  // Second value
                                            }
                                        }
                                    }
                                }
                            },
                            then: [
                                {
                                    type: 'controls_repeat_ext', name: 'Repeat', fields: { REPEAT_TIMES: 2 },
                                    then: [
                                        { type: 'write_text', name: "Write Text", fields: { WRITE_TEXT: "I'M FREE!", } }
                                    ],
                                },
                                {
                                    type: 'turn_on_for_seconds',
                                    name: 'Turn On For Seconds',
                                    fields: {
                                        TURN_ON_BITMAP: [
                                            [1, 1, 0, 1, 1],
                                            [1, 1, 0, 1, 1],
                                            [0, 0, 0, 0, 0],
                                            [1, 0, 0, 0, 1],
                                            [0, 1, 1, 1, 0],
                                        ],
                                        TURN_ON_SECOND: [2]
                                    }
                                },
                                { type: 'stop_action', name: 'Stop Action', fields: { STOP: 'all' } },
                            ],
                            else: [
                                { type: 'moment_with_time_direction', name: 'Moment With Time Direction', fields: { CHOICE: 'Forward', INCREMENT_NUMBER: 1, INCREMENTS: "cm" } },
                                { type: 'move_right_for_rotation', name: 'Move right for rotation', fields: { ANGLE: "90", ENTER_NUMBER: 90, TIME_UNITS: "degrees" } },
                            ]
                        },
                    ]
                }
            ]
        },
    ],

    "lesson-13": [
        { type: 'event_button_action', name: 'When Buttons Pressed', fields: { BUTTON_SIDE: "LEFT", ACTION: 'PRESSED' } },
        {
            type: 'forever',
            name: 'Forever',
            contains: [
                {
                    type: 'blockContainer', // Add a type to fulfill BlockPattern requirements
                    name: 'Block Container', // Optional name for clarity
                    sequence: [
                        {
                            type: 'if_then_else',
                            name: 'If Then Else',
                            condition: {
                                type: 'logic_greater', name: "Logic Greater", inputs: {
                                    FirstValue: {
                                        block: {
                                            type: "sensor_distance_in",  // Your second condition block
                                            name: "Sensor Distance In",
                                            fields: {
                                                "PORT": "F",
                                                "UNITS": "cm"
                                            }
                                        }
                                    },
                                    secondValue: {
                                        shadow: {
                                            type: "math_number",
                                            fields: {
                                                NUM: 10 // Second value
                                            }
                                        }
                                    }
                                }
                            },
                            then: [
                                { type: 'set_movement_speed_to', name: 'Set Movement Speed', fields: { ENTER_NUMBER: 10 } },
                                { type: 'set_movement_motors_to', name: 'Set Movement Motors', fields: { PORT: 'C+D' } },
                                { type: 'moment_with_time_direction', name: 'Moment With Time Direction', fields: { CHOICE: 'Forward', INCREMENT_NUMBER: 5, INCREMENTS: "cm" } },
                            ],
                            else: [
                                { type: 'stop_moving', name: 'stop moving', },
                                {
                                    type: 'if_then_else',
                                    name: 'If Then Else',   
                                    condition: {
                                        type: 'logic_less', name: "Logic Less", inputs: {
                                            FirstValue: {
                                                block: {
                                                    type: "random_number_picker",  // Your second condition block
                                                    name: "random number picker",
                                                    inputs: {
                                                        FROM: {  // Shadow input for the first field
                                                          shadow: {
                                                            type: "math_number",
                                                            name:"FROM",
                                                            fields: {
                                                                NUM: 1  // Second value
                                                            }
                                                          }
                                                        },
                                                        TO: {  // Shadow input for the second field
                                                          shadow: {
                                                            type: "math_number",
                                                            name:"TO",
                                                            fields: {
                                                                NUM: 8  // Second value
                                                            }
                                                          }
                                                        }
                                                      }
                                                }
                                            },
                                            secondValue: {
                                                shadow: {
                                                    type: "math_number",
                                                    fields: {
                                                        NUM: 4  // Second value
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    then: [
                                        { type: 'move_right_for_rotation', name: 'Move right for rotation', fields: { ANGLE: "65", ENTER_NUMBER: 0.7, TIME_UNITS: "rotations" } },

                                    ],
                                    else: [
                                        { type: 'move_right_for_rotation', name: 'Move right for rotation', fields: { ANGLE: "-45", ENTER_NUMBER: 0.9, TIME_UNITS: "rotations" } },

                                    ]
                                },
                            ]
                        },
                    ]
                }
            ]
        },
    ],
}   